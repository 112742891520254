import {React, useState, useContext, useEffect} from 'react';
import { useParams, Link } from 'react-router-dom';

import {gql,useMutation} from "@apollo/client";

import { CssVarsProvider } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';

const RESET_PASSWORD = gql`
mutation resetPassowrd($password: String!, $username: String!, $token: String!) {
  resetPassword(password: $password, token: $token, username: $username) {
    user {
      id
      email
      username
    }
  }
}
`

export function LoginReset(){
    const [form,setForm] = useState({username:'',confirmPassword:'',password:''})
    const [formStatus,setFormStatus] = useState('initial')
    const initialErrors = {matchError:false,errors:[]}
    const [errors,setErrors] = useState(initialErrors)
    const params = useParams()
    const [resetPassword, { loading, error, data, reset }] = useMutation(RESET_PASSWORD);
    const handleChange = (e) => {
        const updatedForm = {...form, [e.target.name]: e.target.value};
        setForm(updatedForm);
        if(updatedForm.password != updatedForm.confirmPassword && errors.matchError == false){
            setErrors({...errors, matchError:true})}
        if(updatedForm.password == updatedForm.confirmPassword && errors.matchError == true){
            setErrors({...errors, matchError:false})
        }
    };

    const handleSubmit = (e) => {   e.preventDefault();   setFormStatus('submitting');
        console.log(form);
        resetPassword({
            variables: {username: form.username, password: form.password, token: params.key},
            onCompleted: (data) => {
                setFormStatus('initial');
                console.log(data);
                setErrors(initialErrors);
            },
            onError: (error) => {setErrors({...errors, errors:[...errors.errors,error.message]}); setFormStatus('initial');reset();}
        })




    }
    return(
        <div>
            <span>{params.key}</span>
            <CssVarsProvider>
                <Sheet
                    sx={{
                        width: 300,
                        mx: 'auto', // margin left & right
                        my: 4, // margin top & botom
                        py: 3, // padding top & bottom
                        px: 2, // padding left & right
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 'sm',
                        boxShadow: 'md',
                    }}
                    variant="outlined"
                >
                    <div>
                        <Typography level="h4" component="h1">
                            <b>GROMS v5.2</b>
                        </Typography>
                        <Typography level="body2">Reset your password below.</Typography>
                    </div>

                    <Input
                        name="username"
                        type="text"
                        placeholder="Email Address"
                        label="email"
                        value = {form.username}
                        onChange = {handleChange}
                    />

                    <Input
                        name="password"
                        type="password"
                        placeholder="password"
                        label="Password"
                        value = {form.password}
                        onChange = {handleChange}
                    />
                    <Input
                        name="confirmPassword"
                        type="password"
                        placeholder="confirm password"
                        label="Confirm Password"
                        value = {form.confirmPassword}
                        onChange = {handleChange}
                    />
                    <LoginButton handleSubmit={handleSubmit} status={formStatus}/>


                </Sheet>
                {errors.matchError ? <Typography level="body2" color={"red"}>Passwords do not match</Typography> : null}
                {errors.errors.map((error) => { return <Typography level="body2" color="red">{error}</Typography>})}
                {data && data.resetPassword.user ? <div>
                    <Typography level="body2" color="green">Password Reset</Typography>
                    <p><Link to={'/login'}>Please Login Here</Link></p>
                </div> : null}
                {data && data.resetPassword.user==null ? <Typography level="body2" color="red">Password Reset Failed</Typography> : null}
            </CssVarsProvider>
        </div>
    )
}


function LoginButton({handleSubmit, status}){
    return(<Button type='submit' sx={{ mt: 1 /* margin top */ }} onClick={handleSubmit} disabled={status=='submitting' ? true : false}>{status=='initial' ? 'Reset' : <CircularProgress/>}</Button>)
}
