import {React, useMemo, useRef, useState, useCallback} from 'react'
import {gql, useMutation, useQuery} from "@apollo/client";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from "@mui/material/Button";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {FileUploader} from "../components/FileUploader";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {noDecimalThousands, twoDecimalThousands} from "../utils/Numbers";
import {PortfolioSelectWrapper} from "../components/shared/PortfolioSelect";
import Overlay from "../components/shared/Overlay";

const GET_PL = gql`query ALL_PL($active:Boolean) {
  allPortfolioLists(active: $active) {
    edges {
      cursor
      node {
        id
        alias
        active
        name
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`
const ADD_FLOW_FILE = gql`
    mutation AddFlowFile($input:AddFileMutationInput!) {
        addFile(input: $input) {
        file {
        id
        name
        description
        downloadUrl
    }
  }
}`
const PROCESS_FILE = gql`mutation ProcessFileMutation($input:ProcessFileMutationInput!) {processFile(input: $input) {
flows {
    id
    portfolio{alias}
    datetime_Date
    direction
    amount
    shares
    currency
    notes
    }
}}`
const NEW_FLOW_MUTATION = gql`
    mutation NewFlowMutation($input:NewFlowMutationInput!) {
    newFlows(input: $input) {flows {
        id
        portfolio{alias}
        datetime_Date
        direction
        amount
        shares
        currency
        notes            
    }}
}
`


export function NewFlow(){
    const [pageState, setPageState] = useState('init')
    const [showOverlay, setShowOverlay] = useState(false)
    const [pageIter, setPageIter] = useState(0)
    const [savedFlows, setSavedFlows] = useState([])
    const [stagedFlows, setStagedFlows] = useState([])
    const [addFlows, {data:addFlowsData, loading:addFlowsLoading, error:addFlowsError}] = useMutation(NEW_FLOW_MUTATION);

    const onSetPageState = (x)=>{setPageState(x)}
    const onReset = ()=>{
        setStagedFlows([]);
        setPageIter(pageIter+1);
        setPageState('init');
    };
    const onStage = (data)=>{
        setStagedFlows(data);
        setPageState('confirm');
    };
    const onSubmit = (data) => {
        const payload = data?.map((row)=>{return({
            portfolioId: row.portfolioId,
            datetime: row.datetime,
            postingDatetime: row.postingDatetime,
            settlementDatetime: row.settlementDatetime,
            currency: row.currency,
            amount: Math.abs(row.amountRaw),
            shares: row.shares,
            notes: row.notes,
            direction: row.amount>0?'IN':'OUT'
        })});
        setShowOverlay(true)
        addFlows({
            variables:{"input":{"data":payload}},
            onCompleted: (addFlowsData)=>{
                setShowOverlay(false)
                setSavedFlows(addFlowsData.newFlows.flows.map((flow)=>{return flow}));
                setPageState('results')
            },
            onError: (error)=>{
                console.log(error);
                setShowOverlay(false)
            }
        })
    };

    const handleUploadSuccess = (x)=>{
        console.log('upload success',x)
        setPageState('results')
        setSavedFlows(x);

    }


    return (
        <div key={pageIter}>
            <Overlay open={showOverlay} />
            {pageState==='init' ? <Initial onSetPageState={onSetPageState} onReset={onReset}/> : null}
            {pageState === 'form' ? <NewFlowFormWrapper key={pageIter} stagedFlows={stagedFlows} onSetPageState={onSetPageState} onReset={onReset} onSubmit={onStage}/> : null}
            {pageState === 'upload' ? <NewFlowUpload onSetPageState={onSetPageState} onReset={onReset} onSuccess={handleUploadSuccess}/> : null}
            {pageState === 'confirm' ? <Confirm key={pageIter} stagedFlows={stagedFlows} onSetPageState={onSetPageState} onReset={onReset} onSubmit={onSubmit}/> : null}
            {pageState === 'results' ? <Results onSetPageState={onSetPageState} onReset={onReset} results={savedFlows}/> : null}
        </div>
    )


}

const Initial = ({onSetPageState})=>{
    return(
        <div>
            <h1>New Flow</h1>
            <Button variant={"outlined"} onClick={()=>{onSetPageState('form')}}>Manual</Button>
            <Button variant={"outlined"} onClick={()=>{onSetPageState('upload')}}>Upload</Button>
        </div>
    )
}

const NewFlowFormWrapper = ({stagedFlows, onSetPageState, onReset, onSubmit})=>{
    const {loading,error,data, refetch} = useQuery(GET_PL, {variables:{active:true}})
    const plData = useMemo(()=>{
        if(loading || error){return []}
        return data?.allPortfolioLists?.edges.map((edge)=>{return edge.node})
    },[data,loading,error])
    const rowData = useMemo(()=>{return stagedFlows},[stagedFlows])
    if(loading){return <div>Loading</div>}
    if(error){return <div>Error</div>}
    return(
        <NewFlowForm stagedFlows={rowData} plData={plData} onSetPageState={onSetPageState} onReset={onReset} onSubmit={onSubmit}/>
    )
}
const NewFlowForm = ({stagedFlows, plData, onReset, onSubmit})=>{
    const rowData = useMemo(()=>{return stagedFlows},[stagedFlows])
    const getRowId = useCallback(params=>params.data.id,[])
    const validateRow = ()=>{return true}
    const handleSubmit = ()=>{
        var output = []
        gridRef.current.api.forEachNode(n=>{if(validateRow(n.data)){output.push(n.data)}})
        onSubmit(output)
    }
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: true,
        };
    }, []);

    const addRow = ()=>{
        let maxRow = 0
        gridRef?.current?.api.forEachNode((node)=>{if(node.data.id>maxRow){maxRow=node.data.id}})
        let newRow = {'id':maxRow+1, 'amountRaw':0, 'shares':0}
        gridRef.current.api.applyTransaction({add:[newRow]})
    }
    const removeRenderer = props => {
        const onClick = ()=>{gridRef.current.api.applyTransaction({remove:[{id:props.data.id}]})}
        return <Button onClick={onClick}>Remove</Button>
    }

    const portfolioRenderer = useCallback((params)=> {return plData.find((x)=>{return x.id===params.value})?.alias || params.value},[plData])

    const PortfolioSelectCellEditor = (props)=>{
        const [value,setValue] = useState(props?.data?.portfolio || null)
        const handleChange = (e)=>{
            const newValue = e.id
            const oldValue = value
            if(newValue===oldValue){return}
            else{setValue(newValue);props.node.setDataValue('portfolioId', newValue);}
        }

        return(
            <PortfolioSelectWrapper onChange={handleChange} />
        )
    }

    const [columnDef, setColumnDef] = useState([
        {field:'id', hide:true},
        {field:'datetime', filter: 'agDateColumnFilter'},
        {field:'postingDatetime', filter: 'agDateColumnFilter'},
        {field:'settlementDatetime', filter: 'agDateColumnFilter'},
        {field:'currency', valueParser: (params)=>{return params.newValue.toUpperCase()}},
        {field:'direction', valueGetter: (params)=>{return params.data.amountRaw>0?'IN':'OUT'},editable: false},
        {field:'portfolioId', headerName: 'Portfolio', cellRenderer:portfolioRenderer, cellEditor: PortfolioSelectCellEditor, cellEditorPopup:true},
        {field:'amount', valueGetter: (params)=>{return Math.abs(params.data.amountRaw)}, valueFormatter: twoDecimalThousands, hide:true},
        {field:'amountRaw', headerName:'Amount', twoDecimalThousands, hide:false, valueParser: params=>Number(params.newValue), valueFormatter: twoDecimalThousands},
        {field:'shares', valueFormatter: twoDecimalThousands, valueParser: params=>Number(params.newValue)},
        {field:'notes'},
        {field: 'delete', cellRenderer: removeRenderer, editable:false}
    ])

    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>Enter New Flows</h1>
            <Button variant={"outlined"} onClick={addRow}>Add Row</Button>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={true}
                rowSelection='multiple'
                checkboxSelection={true}
                ref={gridRef}
                getRowId = {getRowId}
            />
            <Button variant={"contained"} onClick={handleSubmit}>Stage</Button>
            <Button variant={"outlined"} onClick={onReset}>Reset</Button>
        </div>
    )
}

const NewFlowUpload = ({onSetPageState, onReset, onSuccess})=>{
    const [fileType, setFileType] = useState('gromsFlows')
    const [pageState, setPageState] = useState('initial')
    const [pageIter, setPageIter] = useState(0)
    const [addFlowFile, {data, loading, error}] = useMutation(ADD_FLOW_FILE)
    const [processFlowFile, {data:processData, loading:processLoading, error:processError}] = useMutation(PROCESS_FILE)

    const showLoadingOverlay = useMemo(()=>{
        if(pageState==='saving to groms'||pageState==='processing file'){return true};return false;
    },[pageState])
    const handleS3Error = ()=>{setPageState('s3 error')}
    const handleS3Success =  (x,fileType)=>{setPageState('s3 success');saveFile(x,fileType)}

    const saveFile = (x,fileType)=>{
        setPageState('saving to groms')
        addFlowFile({
            variables:{
                input:{description: 'new flow file',file: x.uploadDetails.fields.key,name: x.file.path}
                },
                onCompleted: (data)=>{
                    setPageState('successfully saved to groms');
                    processFile(data.addFile.file.id, fileType)
                },
                onError: (e)=>{
                    setPageState('error saving to groms')
                }
            });
    }

    const processFile = (fileId,fileType) => {
        setPageState('processing file')
        const ft = fileType
        processFlowFile({
            variables:{input:{fileId: fileId,fileType: fileType}},
            onCompleted: (processData)=>{
                setPageState('successfully processed file');
                const savedFlows = processData.processFile.flows
                onSuccess(savedFlows)
            },
            onError: (e)=>{
                setPageState('error processing file')
            }
        })
    }

    const handleRadioChange = (e)=>{setFileType(e.target.value);setPageIter(pageIter+1)}

    return(
        <div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showLoadingOverlay}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <h1>New Flow Upload</h1>
            <Box>
                <Container maxWidth="sm">
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="gromsFlows"
                        name="radio-buttons-group"
                        onChange={handleRadioChange}
                        value={fileType}
                    >
                        <FormControlLabel value="gromsFlows" control={<Radio />} label="Groms" />
                        <FormControlLabel value="alpsFlows" control={<Radio />} label="Alps Flow" />
                        <FormControlLabel value="ucitsFlows" control={<Radio />} label="UCITS Flow" />
                    </RadioGroup>
                </Container>
            </Box>
            <FileUploader key={'fu_'+pageIter} onError={handleS3Error} onSuccess={(x)=>{handleS3Success(x,fileType)}}/>
            <Button variant={"outlined"} onClick={()=>{onSetPageState('init')}}>Back</Button>
            <Button variant={"outlined"} onClick={()=>{onReset()}}>Reset</Button>
            <h5>{pageState} -- {showLoadingOverlay ? 'loading' : 'not loading'}</h5>
        </div>
    )
}

const Confirm = ({stagedFlows, onSetPageState, onReset, onSubmit})=>{
    const rowData = useMemo(()=>{return stagedFlows},[stagedFlows])
    const gridRef = useRef(null);
    const handleSubmit = ()=>{onSubmit(stagedFlows)}
    const getRowId = useCallback(params=>params.data.id,[])
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);
    const [columnDef, setColumnDef] = useState([
        {field:'id', hide:false},
        {field:'datetime', filter: 'agDateColumnFilter'},
        {field:'postingDatetime', filter: 'agDateColumnFilter'},
        {field:'settlementDatetime', filter: 'agDateColumnFilter'},
        {field:'currency', valueParser: (params)=>{return params.newValue.toUpperCase()}},
        {field:'direction', valueGetter: (params)=>{return params.data.amountRaw>0?'IN':'OUT'}},
        {field:'portfolioId', headerName: 'Portfolio'},
        {field:'amount', valueGetter: (params)=>{return Math.abs(params.data.amountRaw)}, valueFormatter: twoDecimalThousands},
        {field:'shares', valueFormatter: twoDecimalThousands, valueParser: params=>Number(params.newValue)},
        {field:'notes'},
    ])

    return(
        <div>
            <h1>New Flow Confirm</h1>
            <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDef}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    enableRangeSelection={false}
                    rowSelection='none'
                    checkboxSelection={false}
                    ref={gridRef}
                    getRowId = {getRowId}
                ></AgGridReact>
            </div>
            <Button variant={"outlined"} onClick={()=>{onSetPageState('form')}}>Back</Button>
            <Button variant={"contained"} disabled={rowData?.length===0} onClick={handleSubmit}>Submit</Button>
            <Button variant={"outlined"} onClick={()=>{onReset()}}>Reset</Button>
        </div>
    )
}

const Results = ({onSetPageState, onReset,results})=>{
    const rowData = useMemo(()=>{return results},[results])
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);
    const [columnDef, setColumnDef] = useState([
        {field:'id', hide:false},
        {field:'portfolio.alias', headerName: 'Portfolio'},
        {field:'datetime_Date', headerName: 'Date', filter: 'agDateColumnFilter'},
        {field:'direction', headerName: 'Direction'},
        {field:'amount', headerName: 'Amount', filter: 'agNumberColumnFilter', valueFormatter: noDecimalThousands},
        {field:'shares', headerName: 'Shares', filter: 'agNumberColumnFilter', valueFormatter: noDecimalThousands},
        {field:'currency', headerName: 'Currency'},
        {field:'notes', headerName: 'Notes'}

    ])
    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>New Flow Results</h1>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='none'
                checkboxSelection={false}
                ref={gridRef}
            ></AgGridReact>
            <Button variant={"outlined"} onClick={()=>{onReset()}}>Reset</Button>
        </div>
    )
}

