import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';

export function DatePicker({value,setValue, label}) {
    const [tempValue,setTempValue] = React.useState(value);
    const handleChange = (newValue)=>{setTempValue(newValue)};
    const handleBlur = ()=>{if(tempValue !== value){setValue(tempValue.format('YYYY-MM-DD'))}}

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                    label={label}
                    value={value}
                    onChange={handleChange}
                    onBlur = {handleBlur}
                />
        </LocalizationProvider>
    );
}