import {gql, useQuery} from "@apollo/client";
import React, {useState, useMemo, useRef} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {twoDecimalThousands, noDecimalThousands} from "../utils/Numbers";
import {DatePicker} from "../components/shared/DatePicker";
import dayjs from "dayjs";

const GET_ORDERS = gql`query All_O($datetime_Date_Gte: String!, $datetime_Date_Lte: String!) {
  allOrders(
    datetime_Date_Gte: $datetime_Date_Gte
    datetime_Date_Lte: $datetime_Date_Lte
  ) {
    edges {
      node {
        id
        security{
            identifier{identifier}
            currency
            accountType
            sector        
        }
        shares
        filled
        datetime_Date
        side
        px
        initialFx        
        
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`

export  function Orders(){
    const initialParams = {datetime_Date_Gte:"2023-12-15", datetime_Date_Lte:"2024-12-31"}
    const [queryParams, setQueryParams] = useState(initialParams)
    const [tempParams, setTempParams] = useState(initialParams)
    const {loading, error, data, refetch} = useQuery(GET_ORDERS, {variables:queryParams});
    const handleChange = (e)=>{
        const newParams = {...queryParams, [e.target.name]:e.target.value}
        setQueryParams(newParams)
    }


    const [columnDef, setColumnDef] = useState([
        {field:'node.id', hide:true},
        {field:'node.security.identifier.identifier.ticker', headerName: 'Ticker'},
        {field:'node.side', headerName: 'Side'},
        {field:'node.datetime_Date', headerName: 'Date', filter: 'agDateColumnFilter'},
        {field:'node.shares', headerName: 'Shares', valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'node.filled', headerName: 'Filled', valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'node.px', headerName: 'Price', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'node.initialFx', headerName: 'FX', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'node.security.currency', headerName: 'Currency'},
        {field:'node.security.accountType', headerName: 'Account Type'},
        {field:'node.security.sector', headerName: 'Sector'}
    ])

    const gridRef = useRef(null)

    const rowData = useMemo(()=>{
        if(loading){
            gridRef?.current?.api.showLoadingOverlay();
            return null
        }
        gridRef?.current?.api.hideOverlay();
        return data.allOrders?.edges
    },[data, loading])

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);
    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>Orders</h1>
            <DatePicker value={dayjs(queryParams.datetime_Date_Gte)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Gte', value:newValue}})}} label={'Start Date'}/>
            <DatePicker value={dayjs(queryParams.datetime_Date_Lte)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Lte', value:newValue}})}} label={'End Date'}/>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='none'
                checkboxSelection={false}
                ref={gridRef}
            ></AgGridReact>
            <span>Total Entries: {data ? data.allOrders.edges.length : null}</span>
        </div>
    )
}