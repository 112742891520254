import React, {useState, useMemo, useCallback, useRef, forwardRef} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from '@mui/material/Button';
import {gql, useMutation, useQuery} from "@apollo/client";
import {UploadTradeFile} from '../components/UploadTradeFile'
import {noDecimalThousands, twoDecimalThousands, percentOneDecimal} from "../utils/Numbers";
import {PortfolioSelectWrapper} from "../components/shared/PortfolioSelect";
import {SecuritySelectWrapper} from "../components/shared/SecurityMasterSelect";
import {BrokerSelectWrapper} from "../components/shared/BrokerSelect";
import Overlay from '../components/shared/Overlay'
import dayjs from "dayjs";

const GET_PL = gql`query ALL_PL($active:Boolean) {
  allPortfolioLists(active: $active) {
    edges {
      cursor
      node {
        id
        alias
        active
        name
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`

const GET_SM = gql`query ALL_SM{
  allSecurityMasters{
    edges {
      cursor
      node {
        id
        identifier {
          identifier
        }
        longName
        currency
        sector
        securityType
        financialStatement
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`

const GET_BROKERS = gql`query ALL_BROKERS($active:Boolean!) {
  allBrokers(active: $active) {
    edges {
      node {
        id
        name
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}`

const GET_POSITIONS = gql`
query GET_POS($portfolio_Active: Boolean!) {
  allPositions(
    portfolio_Active: $portfolio_Active    
  ) {
    edges {
      node {
        id
        price {
          id
          px
          fx
          datetime          
        }
        security {
            id
            priceMultiplier
            accountType
          }      
        portfolio {
          id
        }
        datetime_Date
        pos
      }
    }
  }
}
`

const NEW_TRADE_MUTATION = gql`
    mutation NewTradeMutation($input:NewTradeMutationInput!) {
    newTrades(input: $input) {routeAllocations {id}}
}
`

export function NewTrades(){
    const {loading:plLoading, error:plError, data:plData, refetch:plRefetch} = useQuery(GET_PL, {variables:{active:true}})
    const {loading:smLoading, error:smError, data:smData, refetch:smRefetch} = useQuery(GET_SM);
    const {loading:brLoading, error:brError, data:brData, refetch:brRefetch} = useQuery(GET_BROKERS, {variables:{active:true}});
    const {loading:posLoading, error:posError, data:posData, refetch:posRefetch} = useQuery(GET_POSITIONS, {variables:{portfolio_Active:true}})
    const [showOverlay, setShowOverlay] = useState(false)
    const [addTrades, {data:addTradesData, loading:addTradesLoading, error:addTradesError}] = useMutation(NEW_TRADE_MUTATION);
    const [pageState, setPageState] = useState('init')
    const [tradeData, setTradeData] = useState([])
    const [pageIter, setPageIter] = useState(0)
    const [savedRas, setSavedRas] = useState([])



    const handleStage = (data)=>{
        setPageState('confirm');
        setTradeData(data);
    }
    const handleSubmit = (data)=>{
        const payload = data.map((item)=>{return(
            {
                include:item.include,
                groupId:item.groupId,
                userId:item.userId,
                portfolioId:item.portfolio,
                securityId:item.security,
                datetime:item.datetime,
                side:item.trade < 0 ? 'SELLLONG' : 'BUYLONG',
                initialPx:item.px,
                initialInterest:0,
                initialFx:item.fx,
                notes:item.notes,
                shares:Math.abs(item.trade),
                brokerId:item.broker,
                settlementDatetime:item.settlementDatetime,
                fx:item.fx,
                postingDatetime:item.postingDatetime,
            }
        )})
        setShowOverlay(true)
        addTrades({
            variables:{"input":{"data":payload}},
            onCompleted: (addTradesData)=>{
                setShowOverlay(false)
                setSavedRas(addTradesData.newTrades.routeAllocations.map((ra)=>{return ra.id}));
                setPageState('results')
            },
            onError: (error)=>{
                console.log(error);
                setShowOverlay(false)
            }
        })
    }
    const handleRestart = ()=>{
        setPageState('init');
        setTradeData([]);
        plRefetch();
        smRefetch();
        brRefetch();
        posRefetch();
        setPageIter(pageIter+1)
    }

    const handleUploadSuccess = (uploadedRas)=>{
        setSavedRas(uploadedRas);
        setPageState('results');
    }

    if(plLoading || smLoading || brLoading || posLoading){return <div>Loading</div>}
    if(plError || smError || brError || posError){return <div>Error</div>}
    if(plData && smData && brData && posData){
        return(
            <div>
                <Overlay open={showOverlay}/>
                <h1>New Trades</h1>
                {pageState === 'init' ? <div><NewTradeInitial onChoose={(e)=>{setPageState(e)}}/></div> : <div/>}
                <div key={'trade_logic'+pageIter} style={{display: pageState==='enterTrades'?'block':'none'}}><NewTradesGrid tradeData={tradeData} pageIter={pageIter} plData={plData} smData={smData} brData={brData} posData={posData} onSubmit={handleStage} onBack={()=>{setPageState('init');setPageIter(pageIter+1)}}/></div>
                <div key={'upload'+pageIter} style={{display: pageState==='uploadFile'?'block':'none'}}><UploadTradeFile onSuccess={handleUploadSuccess} onBack={()=>{setPageState('upload');setPageIter(pageIter+1)}}/></div>
                <div style={{display: pageState==='confirm'?'block':'none'}}><ConfirmLogic tradeData={tradeData} smData={smData} plData={plData} brData={brData} onSubmit={handleSubmit} onBack={()=>{setPageState('enterTrades')}}/></div>
                <div style={{display: pageState==='results'?'block':'none'}}><ResultsLogic onRestart={handleRestart} onBack={()=>{setPageState('confirm')}} savedRas={savedRas}/></div>
            </div>
        )
    }
}

function NewTradeInitial({onChoose}){
    return(
        <div>
            <Button variant={"outlined"} onClick={()=>{onChoose('enterTrades')}}>Manual</Button>
            <Button variant={"outlined"} onClick={()=>{onChoose('uploadFile')}}>Upload</Button>
        </div>
    )
}


const NewTradesGrid = ({tradeData, plData,smData,brData,posData,onSubmit,onBack})=>{
    return(
        <div>
            <NewTradesLogic tradeData={tradeData} plData={plData} smData={smData} brData={brData} posData={posData} onSubmit={onSubmit} onBack={onBack}/>
        </div>
    )
}

export  function NewTradesLogic({tradeData, plData, smData, brData, posData, onSubmit, onBack}){
    const gridRef = useRef(null)
    const rowData = useMemo(()=>{return tradeData},[tradeData])
    const addRow = ()=>{
        let maxRow = 0
        gridRef?.current?.api.forEachNode((node)=>{if(node.data.id>maxRow){maxRow=node.data.id}})
        let newRow = {'posStart':0, 'trade':0, 'aum':1, 'id':maxRow+1, 'include':true}
        gridRef.current.api.applyTransaction({add:[newRow]})
    }
    const removeRenderer = props => {
        const onClick = ()=>{gridRef.current.api.applyTransaction({remove:[{id:props.data.id}]})}
        return <Button onClick={onClick}>Remove</Button>
    }
    const validateRow = (row)=>{return true}
    const handleSubmit = () => {
        var output = []
        gridRef.current.api.forEachNode(n=>{if(validateRow(n.data)){output.push(n.data)}})
        onSubmit(output)
    };

    // Memoized Data
    const memoedAum = useMemo(()=>{
        // get every unique portfolio in positions
        const portfolios = [...new Set(posData?.allPositions?.edges?.map((pos)=>{return pos?.node?.portfolio?.id}))]
        console.log('portfolios',portfolios)
        // for each portfolio, get the sum of the positions and multiply by the price
        const aum = portfolios.map((portfolio)=>{
            const positions = posData?.allPositions?.edges?.filter((pos)=>{return pos.node.portfolio.id===portfolio})
            let aum = 0
            positions?.forEach((position)=>{
                if(position?.node?.price===null){return}
                aum += position.node.pos*position.node.price.px*position.node.security.priceMultiplier/position.node.price.fx
            })
            return {portfolio:portfolio, aum:aum}
        })
        console.log(aum)
        return aum

    },[posData])


    const memoedCash = useMemo(()=>{
        // get every unique portfolio in positions
        const portfolios = [...new Set(posData.allPositions.edges.map((pos)=>{return pos.node.portfolio.id}))]
        // for each portfolio, get the sum of the positions and multiply by the price
        const cash = portfolios.map((portfolio)=>{
            const positions = posData.allPositions.edges.filter((pos)=>{return (pos.node.portfolio.id===portfolio && pos.node.security.accountType=="CASH EQUIVALENT")})
            const cash = positions.reduce((a,b)=>{return a+b.node.pos*b.node.price.px*b.node.security.priceMultiplier/b.node.price.fx},0)
            return {portfolio:portfolio, cash:cash}
        })
        return cash
    },[posData])


// Value Getters
    const sideGetter = (params)=> {
        if (params.data.posStart >=0 && params.data.trade >=0){return 'BUYLONG'}
        else if (params.data.posStart >0 && params.data.trade <0){return 'SELLLONG'}
        else if (params.data.posStart <=0 && params.data.trade <0){return 'SELLSHORT'}
        else if (params.data.posStart <0 && params.data.trade >=0){return 'COVERSHORT'}
        else {return 'None'}
    }
    const mvStartGetter = (params)=>{return params.data.posStart*params.data.mul*params.data.px/params.data.fx}
    const weightStartGetter = (params)=>{return (params.data.posStart*params.data.mul*params.data.px/params.data.fx)/params.data.aum}
    const shareGetter = (params)=>{return Math.abs(params.data.trade)}
    const posEndGetter = (params)=>{return params.data.posStart+params.data.trade}
    const mvEndGetter = (params)=>{return (params.data.posStart+params.data.trade)*params.data.mul*params.data.px/params.data.fx}
    const weightEndGetter = (params)=>{return ((params.data.posStart+params.data.trade)*params.data.mul*params.data.px/params.data.fx)/params.data.aum}
    const considerationGetter = (params)=>{return -params.data.trade*params.data.px*params.data.mul/params.data.fx}

    // Cell Renderer
    const portfolioRenderer = (params)=> {
        const match = plData?.allPortfolioLists?.edges?.filter((pl)=>{return pl?.node?.id===params?.value})
        if (match?.length===1){return match[0].node?.alias}
        return params?.value
    }

    const securityRenderer = (params)=> {
        const match = smData?.allSecurityMasters?.edges?.filter((pl)=>{return pl.node.id===params.value})
        if (match?.length===1){return match[0].node?.identifier?.identifier?.ticker}
        return params.value
    }

    const brokerRenderer = (params)=> {
        const match = brData?.allBrokers?.edges?.filter((pl)=>{return pl?.node?.id===params?.value})
        if (match?.length===1){return match[0].node?.name}
        return params?.value
    }

    // Change handlers
    const handlePortfolioChange = (params)=>{
        function matchAum(params){
            const x = memoedAum.filter((aum)=>{return aum.portfolio===params.newValue})
            if(x.length===1){return x[0].aum}
            return 0
            }

        function matchCash(params){
            const x = memoedCash.filter((cash)=>{return (cash.portfolio===params.newValue)})
            if(x.length===1){return x[0].cash}
            return 0
        }

        function matchPos(params){
            if(!params.data.security){return null}
            if(params.data.security){
                const x = posData.allPositions.edges.filter((pos)=>{return (pos.node.portfolio.id===params.newValue && pos.node.security.id===params.data.security)})
                if(x.length===1){return x[0].node}
                return null
            }
        }

        const aumMatch = matchAum(params)
        const cashMatch = matchCash(params)
        const posMatch = matchPos(params)

        if(aumMatch) {params.node.setDataValue('aum', aumMatch)}
        else{params.node.setDataValue('aum', 0)}
        if(cashMatch) {params.node.setDataValue('cash', cashMatch)}
        else{params.node.setDataValue('cash', 0)}
        if(posMatch) {
            params.node.setDataValue('posStart', posMatch.pos)
            params.node.setDataValue('mul', posMatch.security.priceMultiplier)
            if(posMatch?.price?.px){params.node.setDataValue('px', posMatch.price.px)}else{params.node.setDataValue('px', 0)}
            if(posMatch?.price?.fx){params.node.setDataValue('fx', posMatch.price.fx)}else{params.node.setDataValue('fx', 1)}

        }
        else{
            params.node.setDataValue('posStart', 0)
            params.node.setDataValue('px', 0)
            params.node.setDataValue('fx', 1)
            params.node.setDataValue('mul', 0)
        }
    }

    const handleSecurityChange = (params)=>{
        function matchPos(params){
            if(!params.data.portfolio){return null}
            if(params.data.portfolio){
                const x = posData.allPositions.edges.filter((pos)=>{return (pos.node.portfolio.id===params.data.portfolio && pos.node.security.id===params.newValue)})
                if(x.length===1){return x[0].node}
                return null
            }
        }
        const posMatch = matchPos(params)
        if(posMatch) {
            params.node.setDataValue('posStart', posMatch.pos)
            params.node.setDataValue('mul', posMatch.security.priceMultiplier)
            if(posMatch.price.px){params.node.setDataValue('px', posMatch.price.px)}else{params.node.setDataValue('px', 0)}
            if(posMatch.price.fx){params.node.setDataValue('fx', posMatch.price.fx)}else{params.node.setDataValue('fx', 1)}
        }else{
            params.node.setDataValue('posStart', 0)
            params.node.setDataValue('px', 0)
            params.node.setDataValue('fx', 1)
            params.node.setDataValue('mul', 0)
        }
    }

    // Cell Editors
    const PortfolioSelectCellEditor = (props)=>{
        const [value,setValue] = useState(props?.data?.portfolio || null)
        const handleChange = (e)=>{
            const newValue = e.id
            const oldValue = value
            if(newValue===oldValue){return}
            else{setValue(newValue);props.node.setDataValue('portfolio', newValue);}
        }

        return(
            <PortfolioSelectWrapper onChange={handleChange} />
        )
    }
    const SecuritySelectCellEditor = (props)=>{
        const [value,setValue] = useState(props?.data?.security || null)
        const handleChange = (e)=>{
            const newValue = e.id
            const oldValue = value
            if(newValue===oldValue){return}
            else{setValue(newValue);props.node.setDataValue('security', newValue);}
        }
        return(
            <div style={{width:'100%', height:'100px'}}><SecuritySelectWrapper onChange={handleChange} /></div>
        )
    }
    const BrokerSelectCellEditor = (props)=>{
        const [value,setValue] = useState(props?.data?.broker || null)
        const handleChange = (e)=>{
            const newValue = e.id
            const oldValue = value
            if(newValue===oldValue){return}
            else{setValue(newValue);props.node.setDataValue('broker', newValue);}
        }
        return(
            <div style={{width:'100%', height:'100px'}}><BrokerSelectWrapper onChange={handleChange} /></div>
        )
    }



    // Value Setters
    const weightEndSetter = (params)=> {
        const endWeight = params.newValue
        const mvStart = params.data.posStart * params.data.mul * params.data.px / params.data.fx
        const tradeDollars = (endWeight * params.data.aum) - mvStart
        const tradeShares = tradeDollars / (params.data.px*params.data.mul/params.data.fx)
        const roundedTradeShares = Math.round(tradeShares)
        params.node.setDataValue('trade', roundedTradeShares)
        return true
    }
    const posEndSetter = (params)=> {
        const endPos = params.newValue
        const tradeShares = endPos - params.data.posStart
        const roundedShares = Math.round(tradeShares)
        params.node.setDataValue('trade', roundedShares)
        return true
    }
    const mvEndSetter = (params)=> {
        const mvEnd = params.newValue
        const tradeDollars = mvEnd - (params.data.posStart * params.data.mul * params.data.px / params.data.fx)
        const tradeShares = tradeDollars / (params.data.px*params.data.mul/params.data.fx)
        const roundedTradeShares = Math.round(tradeShares)
        params.node.setDataValue('trade', roundedTradeShares)
        return true
    }

    // Column definitions
    const [columnDef, setColumnDef] = useState([
        {field:'id', hide:true},
        {field:'Select', headerCheckboxSelection:true, checkboxSelection: true,headerCheckboxSelectionFilteredOnly: true},
        {
            headerName: 'Portfolio',
            children: [
                {
                    field: 'portfolio',
                    cellRenderer: portfolioRenderer,
                    onCellValueChanged: handlePortfolioChange,
                    cellEditor: PortfolioSelectCellEditor,
                    cellEditorPopup: true,
                },
                {
                    headerName:'AUM',
                    field: 'aum',
                    valueParser: params=>Number(params.newValue),
                    editable: true ,
                    valueFormatter: noDecimalThousands,
                    filter: 'agNumberColumnFilter',
                },
                {
                    headerName:'Cash',
                    field: 'cash',
                    valueParser: params=>Number(params.newValue),
                    editable: true,
                    valueFormatter: noDecimalThousands,
                    filter: 'agNumberColumnFilter',
                },
            ]
        },
        {
            headerName:'Security',
            children:[{
                headerName: 'Security',
                field: 'security',
                cellRenderer: securityRenderer,
                onCellValueChanged:handleSecurityChange,
                cellEditor:SecuritySelectCellEditor,
                cellEditorPopup:true,
            }
            ]
        },
        {
            headerName: 'Pricing',
            children:[
                {
                    headerName:'Multiplier',
                    field: 'mul',
                    valueParser: params=>Number(params.newValue),
                    valueFormatter:twoDecimalThousands,
                    filter: 'agNumberColumnFilter'
                },
                {
                    headerName:'Price',
                    field: 'px',
                    valueParser: params=>Number(params.newValue),
                    valueFormatter:twoDecimalThousands,
                    filter: 'agNumberColumnFilter'},
                {
                    headerName:'FX',
                    field: 'fx',
                    valueParser: params=>Number(params.newValue),
                    valueFormatter:twoDecimalThousands,
                    filter: 'agNumberColumnFilter'
                },
            ]
        },
        {
            headerName:'Start',
            children:[
                {headerName:'Pos Start', field: 'posStart',valueParser: params=>Number(params.newValue), valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter' },
                {headerName:'MV Start', field: 'mvStart', valueGetter: mvStartGetter, editable:false, valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter' },
                {headerName:'Wgt Start', field: 'weightStart', valueGetter: weightStartGetter, editable: false, valueFormatter: percentOneDecimal, filter: 'agNumberColumnFilter' },
            ]
        },
        {
            headerName:'Trade',
            children:[
                {headerName:'Trade Shares', field: 'trade', valueParser: params=>Number(params.newValue), valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
                {field: 'shares', valueGetter: shareGetter, editable: false, hide:true},
            ],
        },
        {
            headerName:'End',
            children:[
                {headerName:'Pos End', field: 'posEnd', valueGetter: posEndGetter, editable: true, valueSetter:posEndSetter,valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
                {headerName:'MV End', field: 'mvEnd', valueGetter: mvEndGetter, editable: true, valueFormatter: noDecimalThousands, valueSetter:mvEndSetter, filter: 'agNumberColumnFilter'},
                {headerName:'Wgt End', field: 'weightEnd', valueGetter: weightEndGetter, editable: true, valueSetter: weightEndSetter, valueFormatter: percentOneDecimal, filter: 'agNumberColumnFilter'},
            ]
        },
        {
            headerName: 'Broker',
            children:[{
                headerName:'Broker',
                field: 'broker',
                cellRenderer: brokerRenderer,
                cellEditor:BrokerSelectCellEditor,
                cellEditorPopup:true
            }
            ]
        },
        {
            headerName:'Misc',
            children:[
                {field: 'consideration', valueGetter: considerationGetter,editable: false, valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
                {field: 'groupId', filter: 'agNumberColumnFilter', valueParser: params=>Number(params.newValue)},
                {field: 'userId', filter: 'agNumberColumnFilter', valueParser: params=>Number(params.newValue)},
                {field: 'side', valueGetter: sideGetter, editable: false},
                {field: 'notes' },
                {field: 'datetime', filter: 'agDateTimeColumnFilter'},
                {field: 'settlementDatetime', filter: 'agDateColumnFilter' },
                {field: 'postingDatetime', filter: 'agDateColumnFilter' },
                {field: 'include', headerName:'Include', filter: 'agSetColumnFilter'},
            ]
        },
        {field: 'delete', cellRenderer: removeRenderer, editable:false}
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
            editable:true,
        };
    }, []);



        return(
            <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
                <Button variant={"outlined"} onClick={onBack}>Reset</Button>
                <Button variant={"outlined"} onClick={addRow}>Add Route Allocation</Button>
                <AgGridReact
                    rowData={rowData}
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    reactiveCustomComponents={true}
                    columnDefs={columnDef}
                    animateRows={true}
                    enableRangeSelection={true}
                    rowSelection='multiple'
                    checkboxSelection={false}
                    getRowId = {params=>params.data.id}
                ></AgGridReact>
                <Button variant={"contained"} onClick={()=>{handleSubmit()}}>Stage</Button>
            </div>

        )

}


export function ConfirmLogic({tradeData, smData, plData, brData, onSubmit, onBack}){
    const gridRef = useRef()
    const rowData = useMemo(()=>{return tradeData},[tradeData])
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
            editable:false,
        };
    }, []);
    const securityGetter = params => smData?.allSecurityMasters?.edges.find((x)=>{return x.node.id===params?.data?.security})?.node?.identifier?.identifier?.ticker
    const portfolioGetter = params=> plData?.allPortfolioLists?.edges.find((x)=>{return x.node.id===params?.data?.portfolio})?.node?.alias
    const brokerGetter = params=> brData?.allBrokers?.edges?.find((x)=>{return x.node.id===params?.data?.broker})?.node?.name
    const sideGetter = (params) => {if(params?.data?.trade>0){return 'BUY'}else{return 'SELL'}}

    const columnDef = useMemo(()=>{
        return [
            {field:'id', hide:false},
            {field:'security', headerName:'Security', valueGetter:securityGetter},
            {field:'portfolio', headerName:'Portfolio', valueGetter:portfolioGetter},
            {field:'broker', headerName: 'Broker', valueGetter:brokerGetter},
            {field:'datetime', headerName:'Datetime'},
            {field:'settlementDatetime', headerName:'Settlement'},
            {field:'side', headerName:'Side', valueGetter:sideGetter},
            {field:'trade', headerName:'Trade', valueFormatter:noDecimalThousands, filter:'agNumberColumnFilter'},
            {field:'px', headerName:'Price', valueFormatter:twoDecimalThousands, filter:'agNumberColumnFilter'},
            {field:'fx', headerName:'FX', valueFormatter:twoDecimalThousands, filter:'agNumberColumnFilter'},

        ]
    },[])
    return(
        <div>
            <h1>Confirm Trades</h1>
            <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
                <AgGridReact
                    rowData={rowData}
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDef}
                    animateRows={true}
                    enableRangeSelection={false}
                    rowSelection='none'
                    checkboxSelection={false}
                    getRowId = {params=>params.data.id}
                ></AgGridReact>
            </div>
            <Button variant={'contained'} diasbled={rowData?.length===0} onClick={()=>{onSubmit(tradeData)}}>Submit</Button>
            <Button variant={'outlined'} onClick={()=>{onBack()}}>Back</Button>

        </div>
    )
}

function ResultsLogic({data, onRestart, onBack, savedRas}){
    return(
        <div>
            <h1>Results</h1>
            {savedRas.map((ra)=>{return <div key={ra}>{ra}</div>})}
            <Button onClick={()=>{onRestart()}}>Restart</Button>
            <Button onClick={()=>{onBack()}}>Back</Button>
        </div>
    )
}