import {React, useState, useContext} from 'react';
import { useLocation, useNavigate, Link} from 'react-router-dom';
import {GromsContext} from "../context/Context";
import { CssVarsProvider } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import {login} from "../utils/Auth";
import {gql,useMutation} from "@apollo/client";

// Define mutation
const VERIFY_LOGIN = gql`mutation verifyLogin($username: String!, $password: String!) {
  tokenAuth(input: {username: $username, password: $password}) {
    clientMutationId
    payload
    token
  }
}`


export function Login({prevLocation}){
    const auth = useContext(GromsContext)
    const location = useLocation();
    const [verifyLogin, { loading, error, data, reset }] = useMutation(VERIFY_LOGIN);
    const navigate = useNavigate();
    const initialForm = {email:'',password:''}
    const [form,setForm] = useState(initialForm)
    const [formStatus,setFormStatus] = useState('initial')
    const [errors,setErrors] = useState([])
    const handleChange = (e) => {setForm({...form, [e.target.name]: e.target.value});}
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormStatus('submitting');
        verifyLogin({
            variables: {username: form.email, password: form.password},
            onCompleted: (data) => {
                setFormStatus('success');
                auth.signin(
                    data.tokenAuth.payload.username,
                    data.tokenAuth.token,
                    true,
                    ()=>{navigate(location.state?.from || '/groms/main')}
                );
            },
            onError: (error) => {setErrors([...errors,error.message]); setFormStatus('initial');setForm(initialForm)}
        })
        console.log(form);
    }
    return(
        <div>
            <CssVarsProvider>
                <Sheet
                    sx={{
                        width: 300,
                        mx: 'auto', // margin left & right
                        my: 4, // margin top & botom
                        py: 3, // padding top & bottom
                        px: 2, // padding left & right
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 'sm',
                        boxShadow: 'md',
                    }}
                    variant="outlined"
                >
                    <div>
                        <Typography level="h4" component="h1">
                            <b>GROMS v5.2</b>
                        </Typography>
                        <Typography level="body2">Sign in to continue.</Typography>
                    </div>

                    <Input
                        // html input attribute
                        name="email"
                        type="email"
                        placeholder="johndoe@gorozen.com"
                        // pass down to FormLabel as children
                        label="Email"
                        value = {form.email}
                        onChange = {handleChange}
                    />
                    <Input
                        name="password"
                        type="password"
                        placeholder="password"
                        label="Password"
                        value = {form.password}
                        onChange = {handleChange}
                    />
                    <LoginButton handleSubmit={handleSubmit} status={formStatus}/>
                </Sheet>
                {errors.map((error) => {
                    return <Typography level="body2" color="red">{error}</Typography>
                })}
                <Link to={'/login/reset'}>Forgot Password</Link>
            </CssVarsProvider>
        </div>
    )
}


function LoginButton({handleSubmit, status}){
    return(<Button type='submit' sx={{ mt: 1 /* margin top */ }} onClick={handleSubmit} disabled={status=='submitting' ? true : false}>{status=='initial' ? 'Login' : <CircularProgress/>}</Button>)
}