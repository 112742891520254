import {gql, useQuery} from "@apollo/client";
import React, {useState, useMemo, useRef} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {DatePicker} from "../components/shared/DatePicker";
import dayjs from "dayjs";
import {twoDecimalThousands, noDecimalThousands} from "../utils/Numbers";


const GET_FILLS = gql`query GET_F($datetime_Date_Gte:String!, $datetime_Date_Lte:String!){
  allFills(datetime_Date_Gte: $datetime_Date_Gte, datetime_Date_Lte: $datetime_Date_Lte) {
    edges {
      node {
        id
        shares
        px
        fx
        datetime_Date
        route{
            id
            broker{name}
            order{
                initialFx
                security{
                    identifier{identifier}
                    currency
                    accountType
                    sector
                }
                side
            }
            }
      }      
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
`

export  function Fills(){
    const initialParams = {datetime_Date_Gte:dayjs().format('YYYY-MM-DD'), datetime_Date_Lte:dayjs().format('YYYY-MM-DD')}
    const [queryParams, setQueryParams] = useState(initialParams)
    const {loading, error, data, refetch} = useQuery(GET_FILLS, {variables:queryParams});
    const handleChange = (e)=>{
        const newParams = {...queryParams, [e.target.name]:e.target.value}
        setQueryParams(newParams)
    }


    const [columnDef, setColumnDef] = useState([
        {field:'node.id', hide:true},
        {field:'node.route.order.security.identifier.identifier.ticker', headerName: 'Ticker'},
        {field:'node.route.order.side', headerName: 'Side'},
        {field:'node.datetime_Date', headerName: 'Date', filter: 'agDateColumnFilter'},
        {field:'node.shares', headerName: 'Shares', valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'node.px', headerName: 'Price', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'node.route.order.initialFx', headerName: 'FX', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'node.route.broker.name', headerName: 'Broker'},
        {field:'node.route.order.security.currency', headerName: 'Currency'},
        {field:'node.route.order.security.accountType', headerName: 'Account'},
        {field:'node.route.order.security.sector', headerName: 'Sector'},
        {field:'node.route.id', headerName: 'Route ID'}

    ])

    const gridRef = useRef(null)

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);
    const rowData = useMemo(()=>{
        if(loading){gridRef?.current?.api.showLoadingOverlay();return null}
        gridRef?.current?.api.hideOverlay();
        return data?.allFills?.edges
    },[data, loading])

    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>Fills</h1>
            <DatePicker value={dayjs(queryParams.datetime_Date_Gte)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Gte', value:newValue}})}} label={'Start Date'}/>
            <DatePicker value={dayjs(queryParams.datetime_Date_Lte)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Lte', value:newValue}})}} label={'End Date'}/>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='none'
                checkboxSelection={false}
                ref={gridRef}
            ></AgGridReact>
            <span>Total Entries: {rowData?.length || 0}</span>
        </div>
    )
}