import {gql, useQuery} from "@apollo/client";
import React, {useState, useMemo, useRef} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {DatePicker} from "../components/shared/DatePicker";
import {twoDecimalThousands} from "../utils/Numbers";
import dayjs from "dayjs";


const GET_GLS = gql`query GET_GLS($datetime_Date_Gte: String!, $datetime_Date_Lte: String!) {
  allGeneralLedgers(
    datetime_Date_Gte: $datetime_Date_Gte
    datetime_Date_Lte: $datetime_Date_Lte
  ) {
    edges {
      node {
        id
        portfolio{alias}
        security{
            identifier{identifier}
            accountType
            currency
            sector        
        }
        datetime_Date
        amount
        shares        
        event{
            id
            name
            notes
        }        
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
`

export  function Gls(){
    const initialParams = {datetime_Date_Gte:dayjs().format('YYYY-MM-DD'), datetime_Date_Lte:dayjs().format('YYYY-MM-DD')}
    const [queryParams, setQueryParams] = useState(initialParams)
    const {loading, error, data, refetch} = useQuery(GET_GLS, {variables:queryParams, notifyOnNetworkStatusChange:true});
    const gridRef = useRef(null)
    const handleChange = (e)=>{
        const newParams = {...queryParams, [e.target.name]:e.target.value}
        setQueryParams(newParams)
    }
    const rowData = useMemo(()=>{
        if(loading){
            gridRef?.current?.api.showLoadingOverlay();
            return null
        }
        gridRef?.current?.api.hideOverlay();
        return data.allGeneralLedgers?.edges
    },[data, loading])



    const [columnDef, setColumnDef] = useState([
        {field:'node.event.id', headerName:'Event ID', filterable:true, rowGroup:true},
        {field:'node.id'},
        {field: 'node.security.identifier.identifier.ticker', headerName: 'Ticker'},
        {field:'node.portfolio.alias', headerName: 'Portfolio'},
        {field:'node.datetime_Date', headerName: 'Date', filter: 'agDateColumnFilter'},
        {field:'node.amount', headerName: 'Amount', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'node.shares', headerName: 'Shares', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'node.security.currency', headerName: 'Currency'},
        {field:'node.security.accountType', headerName: 'Account Type'},
        {field:'node.security.sector', headerName: 'Sector'},
        {field:'node.event.name', headerName: 'Event'},
        {field:'node.event.notes', headerName: 'Notes'}

    ])



    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);

    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>General Ledger Entries</h1>
            <DatePicker value={dayjs(queryParams.datetime_Date_Gte)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Gte', value:newValue}})}} label={'Start Date'}/>
            <DatePicker value={dayjs(queryParams.datetime_Date_Lte)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Lte', value:newValue}})}} label={'End Date'}/>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='none'
                checkboxSelection={false}
                ref={gridRef}
                groupDefaultExpanded = {-1}
            ></AgGridReact>
            <span>Total Entries: {rowData?.length}</span>
        </div>
    )
}