import {React, useState, useMemo} from "react";
import {useMutation, useQuery, useLazyQuery, gql} from "@apollo/client";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import {FileUploader} from '../components/FileUploader'
import {PortfolioSelect} from '../components/PortfolioSelect'
import {Initial} from '../components/reconcile/Initial'
import {Parameters} from "../components/reconcile/Parameters";
import {CustodianDuplicates} from "../components/reconcile/Custodian";
import {Match} from "../components/reconcile/Match";
import {Reconcile} from "../components/reconcile/Reconcile";
import {Results} from "../components/reconcile/Results";
import {Summary} from "../components/reconcile/Summary";


const ADD_RECON_FILE = gql`
    mutation AddReconFile($input:AddFileMutationInput!) {
        addFile(input: $input) {
        file {
        id
        name
        description
        downloadUrl
    }
  }
}`



export function NewReconciliation(){
    const [pageState, setPageState] = useState("initial")
    const [pageIter, setPageIter] = useState(0)
    const [params,setParams] = useState(null)
    const [custodianData, setCustodianData] = useState(null)
    const [reconcileReport, setReconcileReport] = useState(null)
    const [results, setResults] = useState(null)


    const startNewRecon = ()=>{
        setParams(null);setCustodianData(null);setReconcileReport(null);
        setPageState('parameters')
    }

    const handleParamSubmit = (params)=>{
        setParams(params);
        setPageState('custodian');
    }

    const handleCustodianSubmit = (data)=>{
        setCustodianData(data);
        setPageState('match');
    }

    const handleMatchSubmit = (data)=>{
        setPageState('reconcile');
    }

    const handleGromsDupeSubmit = (data)=>{
        setPageState('custodianOnly');
    }

    const handleCustodianOnlySubmit = (data)=>{
        setPageState('reconcile');
    }

    const handleReconcileSubmit = (data)=>{
        setReconcileReport(data);
        setPageState('results');
    }

    const handleResultsSubmit = (data)=>{
        setPageState('summary');
        setResults(data);
    }

    const handleReset = ()=>{
        setParams(null);setCustodianData(null);setReconcileReport(null);
        setPageIter(pageIter+1);
        setPageState('initial')
    }

    return (
        <div key={pageIter}>
            <h1>New Reconciliation</h1>
            <ReconSteps pageState={pageState}/>

            <div style={{display: pageState==='initial'?'block':'none'}}>
                <Initial onSubmit={startNewRecon} onReset={handleReset} onBack={()=> {setPageState('initial')}}/>
            </div>

            <div style={{display: pageState==='parameters'?'block':'none'}}>
                <Parameters onBack={()=>{setPageState('initial')}} onReset={handleReset} onSubmit={handleParamSubmit}/>
            </div>

            {pageState === 'custodian' ?
                <div><CustodianDuplicates
                    onBack={()=>{setPageState('parameters')}}
                    onReset={handleReset}
                    onSubmit={handleCustodianSubmit}
                    params = {params}
                /></div> : null}

            {pageState==='match' ?
                <div><Match
                    onBack={()=>{setPageState('custodian')}}
                    onReset={handleReset}
                    onSubmit={handleMatchSubmit}
                    params = {params}
                    custodianData = {custodianData}
                />
                </div>: null}

            {pageState==='reconcile' ?
                <div>
                    <Reconcile
                        onSubmit={handleReconcileSubmit}
                        onBack={()=>{setPageState('match')}}
                        onReset={handleReset}
                        params={params}
                        custodianData={custodianData}
                        reconcileReport={[]}
                    />
                </div>
                : null}



            <div style={{display: pageState==='results'?'block':'none'}}>
                <Results
                    onReset={handleReset}
                    onBack={()=>{setPageState('reconcile')}}
                    onSubmit={handleResultsSubmit}
                    data={{params:params, custodianData:custodianData, reconcileReport:reconcileReport}}
                />
            </div>
            <div style={{display: pageState==='summary'?'block':'none'}}>
                <Summary onReset={handleReset}/>
            </div>
        </div>
    )
}


const ReconSteps = ({pageState})=>{
    const steps = [
        {label: 'Initial', value: 'initial'},
        {label: 'Parameters', value: 'parameters'},
        {label: 'Custodian Duplicates', value: 'custodian'},
        {label: 'Match', value: 'match'},
        {label: 'Reconcile', value: 'reconcile'},
        {label: 'Results', value: 'results'},
        {label: 'Summary', value: 'summary'},
    ]

    const getActiveStep = (pageState)=> {
        // get the index of pagestate in steps
        let i = 0;
        for (i = 0; i < steps.length; i++) {
            if (steps[i].value === pageState) {
                return i;
            }
        }
    }


    return(
        <div>
            <Stepper activeStep={getActiveStep(pageState)}>
                {steps.map((label, index) => {return(<Step key={label.label}><StepLabel>{label.label}</StepLabel></Step>)})}
            </Stepper>
        </div>
    )
}















