import {React, useState} from "react";
import {gql, useMutation} from "@apollo/client";
import {FileUploader} from "../FileUploader";
import Button from '@mui/material/Button';
import {PortfolioSelect} from "../NewPortfolioSelect";
import {PortfolioSelectWrapper} from "../shared/PortfolioSelect";
import {ReconcileSelectWrapper} from "../shared/ReconcileSchemaSelect";
import dayjs from "dayjs";
import {DatePicker} from "../shared/DatePicker";
import Grid from '@mui/material/Unstable_Grid2';


const ADD_RECON_FILE = gql`
    mutation AddReconFile($input:AddFileMutationInput!) {
        addFile(input: $input) {
        file {
        id
        name
        description
        downloadUrl
    }
  }
}`

export function Parameters({onSubmit, onBack, onReset}){
    const [fileObj, setFileObj] = useState("RmlsZU9iak5vZGU6MTMwMA==")
    const [reconciliationDate, setReconciliationDate] = useState(dayjs().format('YYYY-MM-DD'))
    const [portfolios, setPortfolios] = useState([])
    const [reconcileSchema, setReconcileSchema] = useState()
    const [pageState, setPageState] = useState('initial')
    const [addReconFile, {data, loading, error}] = useMutation(ADD_RECON_FILE)

    const handleS3Error = ()=>{setPageState('s3 error')}
    const handleS3Success = (x)=>{setPageState('s3 success');saveFile(x)}
    const saveFile = (x)=>{
        addReconFile({
            variables:{
                input:{
                    description: 'groms uploaded reconciliation file',
                    file: x.uploadDetails.fields.key,
                    name: x.file.path
                }
            },
            onCompleted: (data)=>{
                setFileObj(data.addFile.file.id)
                setPageState('successfully saved to groms');
            },
            onError: (e)=>{
                setPageState('error saving to groms')
            }
        });

    };
    const handleChange = (e)=>{
        if(e.target.name==='reconciliationDate'){
            setReconciliationDate(e.target.value)
        }
        else if(e.target.name==='portfolios'){
            setPortfolios(e.target.value)
        }
        else if(e.target.name==='reconcileSchema'){
            setReconcileSchema(e.target.value)
        }

    }

    const handlePortfolioChange = (portfolios)=>{
        setPortfolios(portfolios.map((portfolio,index)=>{return portfolio.id}))
    }

    const handleSubmit = ()=>{
        const output = {fileObj:fileObj, reconciliationDate:reconciliationDate, portfolios:portfolios, reconcileSchema:reconcileSchema}
        onSubmit(output)
    };


    return(
        <div>
            <h2>Set Initial Parameters</h2>
            <Grid container spacing={2}>
                <Grid xs={2}></Grid>
                <Grid xs={8}>
                    <FileUploader onSuccess={handleS3Success} onError={handleS3Error}/>
                    <div style={{paddingTop:'10px'}}><DatePicker value={dayjs(reconciliationDate)} setValue = {(newValue)=>{handleChange({target:{name:'reconciliationDate', value:newValue}})}} label={'Recon Date'}/></div>
                    <PortfolioSelectWrapper title={'Select Portfolio...'} isMulti={true} onChange={handlePortfolioChange} value={portfolios}/>
                    <ReconcileSelectWrapper value={reconcileSchema} onChange={(e)=> {handleChange({target:{name:'reconcileSchema', value:e}})}}/>
                    <div style={{paddingTop:'10px'}}>
                        <Button variant={"outlined"} onClick={onBack}>Back</Button>
                        <Button variant={"contained"} onClick={handleSubmit}>Next</Button>
                        <Button variant={"outlined"} onClick={onReset}>Reset</Button>

                    </div>

                    <p>{pageState}</p>

                </Grid>
                <Grid xs={2}></Grid>
            </Grid>


        </div>
    )
}