import {gql, useQuery} from "@apollo/client";
import React, {useState, useMemo, useRef, useEffect} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {getPrimaryKeyFromRelay} from "../utils/Utils";

const GET_PL = gql`query ALL_PL($active:Boolean) {
  allPortfolioLists(active: $active) {
    edges {
      cursor
      node {
        id
        alias
        active
        name
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`

export  function PortfolioLists(){
    const {loading,error,data, refetch} = useQuery(GET_PL, {variables:{active:true}})
    const [columnDef, setColumnDef] = useState([
        {field: 'node.id'},
        {field:'portfolioId', headerName: 'Portfolio ID', valueGetter: (params) => {return getPrimaryKeyFromRelay(params.data.node.id)}, hide:true},
        {field:'node.name'},
        {field:'node.alias'},
        {field:'node.active'},
    ])
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);

    const rowData = useMemo(()=>{
        if(loading){gridRef?.current?.api.showLoadingOverlay();return null}
        gridRef?.current?.api.hideOverlay();
        return data.allPortfolioLists?.edges
    },[data, loading])

    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>Portfolio Lists</h1>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='none'
                checkboxSelection={false}
                ref={gridRef}
            ></AgGridReact>
            <span>Total Entries: {rowData?.length || 0}</span>
        </div>
    )
}