import {React, useState, useMemo} from 'react';
import {gql, useQuery} from "@apollo/client";
import Select from 'react-select';






const GET_PL = gql`query ALL_PL($active:Boolean) {
  allPortfolioLists(active: $active) {
    edges {      
      node {
        id
        alias        
      }
    }    
  }
}
`


export function PortfolioSelect({multiple, value, onChange, disabled, clearable, searchable, name}) {
    const {loading,error,data, refetch} = useQuery(GET_PL, {variables:{active:true}})

    const portfolioOptions = useMemo(()=>{
        if(loading || error){return null}
        return data?.allPortfolioLists?.edges.map(({node})=>({value:node.id,label:node.alias}))
    },[data,loading, error])

    const selectValue = useMemo(()=>{
        if(!value || !data){return null}
        const options = data?.allPortfolioLists?.edges.map(({node})=>({value:node.id,label:node.alias}))
        return options.filter((option)=>value.includes(option.value))
    },[value,data])

    const handleChange = (e)=>{
        if(multiple){onChange(e.map((x)=>x.value))}
        if(!multiple)(onChange(e.value))

    }

    return (
        <div>
            <Select
                className="basic-multi-select"
                classNamePrefix="select"
                isMulti={multiple}
                isDisabled={disabled}
                isLoading={loading}
                isClearable={clearable}
                isRtl={false}
                isSearchable={searchable}
                name={name}
                options={portfolioOptions}
                onChange={handleChange}
                value={selectValue}

            />
        </div>
    );
}