import {React} from "react";
import Button from "@mui/material/Button";

export function Summary({onSubmit, onBack, onReset}){
    return(
        <div>
            <h2>Summary</h2>
            <h4>Success</h4>
            <Button variant={'outlined'} onClick={onReset}>Reset</Button>
        </div>
    )
}