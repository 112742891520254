import React, {useContext, useEffect, useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GromsProvider, GromsContext} from "./context/Context";



//const BASE_URL = 'http://127.0.0.1:8000/app/graphql'
const BASE_URL = 'https://pm9fn3miku.us-east-1.awsapprunner.com/app/graphql'
const httpLink = createHttpLink({ uri: BASE_URL });




const makeClientWithoutToken = ()=>{
    const client = new ApolloClient({
        uri: BASE_URL,
        cache: new InMemoryCache(),
    });
    return client
}

const makeClientWithToken = (token)=>{
    const authMiddleware = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: 'JWT '+token
            }}});
    const client = new ApolloClient({
        link: authMiddleware.concat(httpLink),
        cache: new InMemoryCache()
    });
    return client
}

const Main = ()=>{
    const auth = useContext(GromsContext)
    const client = useMemo(()=>{
        if(auth?.user?.isAuthenticated===true && auth?.user?.token!=null){
            const token = auth?.user?.token
            return makeClientWithToken(token)
        }
        return makeClientWithoutToken()
    },[auth])
    return(
        <ApolloProvider client={client}><App/></ApolloProvider>
    )
}

const MainWrapper = ()=>{
    return(
        <React.StrictMode>
            <GromsProvider>
                <Main/>
            </GromsProvider>
        </React.StrictMode>

    )
}








const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<MainWrapper/>);

reportWebVitals();
