import {React, useMemo, useRef, useState} from 'react'
import {gql, useMutation, useQuery} from '@apollo/client'
import {AgGridReact} from "ag-grid-react";
import {DatePicker} from "../components/shared/DatePicker";
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import dayjs from "dayjs";
import {twoDecimalThousands, noDecimalThousands} from "../utils/Numbers";

const GENERATE_FILE_RAS = gql`
query GenerateFileRas($route_Datetime_Date_Gte: String!, $route_Datetime_Date_Lte: String!) {
  allRouteAllocations(
    route_Datetime_Date_Gte: $route_Datetime_Date_Gte
    route_Datetime_Date_Lte: $route_Datetime_Date_Lte
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        filled
        shares
        px
        fx
        com
        orderAllocation{portfolio {alias}}
        
        notes
        route {
        id
        datetime_Date
        order {
            security {identifier {identifier} currency}
            side
          }
        }
      }
    }
  }
}
`

const GENERATE_FILES = gql`
    mutation GenerateFilesMutation($input: GenerateFilesInput!) {
  generateFiles(input: $input) {
    files {
      id
      name
      description
      downloadUrl
    }
  }
}
`




export function Generate(){
    const [pageState, setPageState] = useState('initial')
    const [pageIter, setPageIter] = useState(0)
    const [generateFiles, {data, loading, error}] = useMutation(GENERATE_FILES)
    const [results, setResults] = useState([])
    const [loadingOverlay, setLoadingOverlay] = useState(false)
    const handleTradeSubmit = (d)=>{
        setLoadingOverlay(true);
        generateFiles({
            variables:{"input":{
                    "routeAllocationId": d,
                    "fileType": "tradeFiles"
            }},
            onCompleted:(data)=>{
                setResults(data?.generateFiles?.files);setPageState('results')
                setLoadingOverlay(false)
            }
        }
        )

    }

    const handleEmsiSubmit = (d)=>{
        setLoadingOverlay(true)
        generateFiles({
                variables:{"input":{
                        "routeId": d,
                        "fileType": "emsi"
                    }},
                onCompleted:(data)=>{
                    setResults(data?.generateFiles?.files);
                    setPageState('results');
                    setLoadingOverlay(false)
                }
            }
        )

    };


    const handleBbuSubmit = (d)=>{
        setLoadingOverlay(true)
        generateFiles({
                variables:{
                    "input":{
                        "portfolioListId": d.portfolioListIds,
                        "datetime_Date_Gte": d.datetime_Date_Gte,
                        "datetime_Date_Lte": d.datetime_Date_Lte,
                        "fileType": "bbu",
                    }},
                onCompleted:(data)=>{
                    setResults(data?.generateFiles?.files);
                    setPageState('results');
                    setLoadingOverlay(false)
                }
            }
        )

    };

    const handleBack = ()=>{setPageState('initial')}
    const handleReset = ()=>{setPageState('initial');setPageIter(pageIter+1);}



    return (
        <div key={pageIter}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingOverlay}><CircularProgress color="inherit" /></Backdrop>
            <h1>Generate</h1>
            <div style={{display: pageState==='initial'?'block':'none'}} ><Initial onPickType={(x)=>{setPageState(x)}}/></div>
            <div style={{display: pageState==='trade'?'block':'none'}} ><TradeFiles onSubmit={handleTradeSubmit} onBack={handleBack} onReset={handleReset}/></div>
            <div style={{display: pageState==='emsi'?'block':'none'}} ><Emsi onSubmit={handleEmsiSubmit} onBack={handleBack} onReset={handleReset}/></div>
            <div style={{display: pageState==='bbu'?'block':'none'}} ><Bbu onSubmit={handleBbuSubmit} onBack={handleBack} onReset={handleReset}/></div>
            <div style={{display: pageState==='results'?'block':'none'}} ><Results results={results} onBack={handleBack} onReset={handleReset}/></div>
        </div>
    )
}





const Initial = ({onPickType})=>{
    return(
        <div>
            <Button variant="outlined" onClick={()=>{onPickType('trade')}}>Trade Files</Button>
            <Button variant="outlined"  onClick={()=>{onPickType('emsi')}}>EMSI Files</Button>
            <Button variant="outlined"  onClick={()=>{onPickType('bbu')}}>BBU Files</Button>
        </div>
    )
}







const TradeFiles = ({onSubmit, onBack, onReset})=>{
    const initialParams = {route_Datetime_Date_Lte:dayjs().format('YYYY-MM-DD'), route_Datetime_Date_Gte:dayjs().format('YYYY-MM-DD')}
    const [queryParams, setQueryParams] = useState(initialParams)
    const {data, loading, error} = useQuery(GENERATE_FILE_RAS, {variables:queryParams, fetchPolicy:'network-only'})

    const handleChange = (e)=>{
        const newParams = {...queryParams, [e.target.name]:e.target.value}
        setQueryParams(newParams)
    }

    const handleSubmit = ()=>{
        const selectedNodes = gridRef?.current?.api?.getSelectedNodes()
        const output = selectedNodes.map(node=>node?.data?.node?.id)
        onSubmit(output)
    }

    const [columnDef, setColumnDef] = useState([
        {headerName:'ID', field: 'node.id',headerCheckboxSelection: true,checkboxSelection: true,headerCheckboxSelectionFilteredOnly: true},
        {headerName:'Ticker', field:'node.route.order.security.identifier.identifier.ticker'},
        {headerName:'Date', field:'node.route.datetime_Date', filter: 'agDateColumnFilter'},
        {headerName:'Portfolio', field:'node.orderAllocation.portfolio.alias'},
        {headerName:'Side', field:'node.route.order.side'},
        {headerName:'Shares', field:'node.filled', filter: 'agNumberColumnFilter', valueFormatter: noDecimalThousands},
        {headerName:'Price', field:'node.px', filter: 'agNumberColumnFilter', valueFormatter: twoDecimalThousands},
        {headerName:'FX', field:'node.fx', filter: 'agNumberColumnFilter', valueFormatter: twoDecimalThousands},
        {headerName:'Currency', field:'node.route.order.security.currency'},
        {headerName:'Commission', field:'node.com', filter: 'agNumberColumnFilter', valueFormatter: twoDecimalThousands},
    ])

    const gridRef = useRef(null)

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);

    const rowData = useMemo(()=>{
        if(loading){gridRef?.current?.api.showLoadingOverlay();return null}
        gridRef?.current?.api.hideOverlay();
        return data?.allRouteAllocations?.edges
    },[data, loading])



    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1> Trade Files</h1>
            <h2>Choose Relevant Route Allocations</h2>
            <DatePicker value={dayjs(queryParams.route_Datetime_Date_Gte)} setValue = {(newValue)=>{handleChange({target:{name:'route_Datetime_Date_Gte', value:newValue}})}} label={'Start Date'}/>
            <DatePicker value={dayjs(queryParams.route_Datetime_Date_Lte)} setValue = {(newValue)=>{handleChange({target:{name:'route_Datetime_Date_Lte', value:newValue}})}} label={'End Date'}/>
            <AgGridReact
                rowData={rowData}
                enableRangeSelection={false}
                rowSelection='multiple'
                checkboxSelection={true}
                ref={gridRef}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                ref={gridRef}
            ></AgGridReact>
            <Button variant="outlined" onClick={onBack}>Back</Button>
            <Button variant="contained" onClick={()=>{handleSubmit()}}>Submit</Button>
            <Button variant="outlined" onClick={onReset}>Reset</Button>
        </div>
    )

}

const Emsi = ({onSubmit, onBack, onReset})=>{
    const initialParams = {route_Datetime_Date_Lte:dayjs().format('YYYY-MM-DD'), route_Datetime_Date_Gte:dayjs().format('YYYY-MM-DD')}
    const [queryParams, setQueryParams] = useState(initialParams)
    const {data, loading, error} = useQuery(GENERATE_FILE_RAS, {variables:queryParams, fetchPolicy:'network-only'})

    const handleChange = (e)=>{
        const newParams = {...queryParams, [e.target.name]:e.target.value}
        setQueryParams(newParams)
    }

    const handleSubmit = ()=>{
        const selectedNodes = gridRef?.current?.api?.getSelectedNodes()
        const output = selectedNodes.map(node=>node?.data?.node?.route?.id)
        // only submit unique elements of output
        onSubmit([...new Set(output)])
    }

    const [columnDef, setColumnDef] = useState([
        {headerName:'ID', field: 'node.id',headerCheckboxSelection: true,checkboxSelection: true,headerCheckboxSelectionFilteredOnly: true},
        {headerName:'Ticker', field:'node.route.order.security.identifier.identifier.ticker'},
        {headerName:'Date', field:'node.route.datetime_Date', filter: 'agDateColumnFilter'},
        {headerName:'Portfolio', field:'node.orderAllocation.portfolio.alias'},
        {headerName:'Side', field:'node.route.order.side'},
        {headerName:'Shares', field:'node.shares', filter: 'agNumberColumnFilter', valueFormatter: noDecimalThousands},
        {headerName:'Price', field:'node.px', filter: 'agNumberColumnFilter', valueFormatter: twoDecimalThousands},
        {headerName:'FX', field:'node.fx', filter: 'agNumberColumnFilter', valueFormatter: twoDecimalThousands},
        {headerName:'Currency', field:'node.route.order.security.currency'},
        {headerName:'Commission', field:'node.com', filter: 'agNumberColumnFilter', valueFormatter: twoDecimalThousands},
    ])

    const gridRef = useRef(null)

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);

    const rowData = useMemo(()=>{
        if(loading){gridRef?.current?.api.showLoadingOverlay();return null}
        gridRef?.current?.api.hideOverlay();
        return data?.allRouteAllocations?.edges
    },[data, loading])



    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>EMSI Files</h1>
            <h2>Choose Relevant Route Allocations</h2>
            <DatePicker value={dayjs(queryParams.route_Datetime_Date_Gte)} setValue = {(newValue)=>{handleChange({target:{name:'route_Datetime_Date_Gte', value:newValue}})}} label={'Start Date'}/>
            <DatePicker value={dayjs(queryParams.route_Datetime_Date_Lte)} setValue = {(newValue)=>{handleChange({target:{name:'route_Datetime_Date_Lte', value:newValue}})}} label={'End Date'}/>
            <AgGridReact
                rowData={rowData}
                enableRangeSelection={false}
                rowSelection='multiple'
                checkboxSelection={true}
                ref={gridRef}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                ref={gridRef}
            ></AgGridReact>
            <Button variant={"outlined"} onClick={onBack}>Back</Button>
            <Button variant={"contained"} onClick={()=>{handleSubmit()}}>Submit</Button>
            <Button variant={"outlined"} onClick={onReset}>Reset</Button>
        </div>
    )

}

const Bbu = ({onSubmit, onBack, onReset})=>{
    const GET_PL = gql`query ALL_PL($active:Boolean) {
  allPortfolioLists(active: $active) {
    edges {
      cursor
      node {
        id
        alias
        active
        name
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`

    const initialParams = {datetime_Date_Lte:dayjs().format('YYYY-MM-DD'), datetime_Date_Gte:dayjs().format('YYYY-MM-DD')}
    const [queryParams, setQueryParams] = useState(initialParams)


    const handleChange = (e)=>{
        const newParams = {...queryParams, [e.target.name]:e.target.value}
        setQueryParams(newParams)
    }

    const {data, loading, error} = useQuery(GET_PL, {variables:{active:true}});
    const handleSubmit = ()=>{
        const selectedNodes = gridRef?.current?.api?.getSelectedNodes()
        const output = {
            datetime_Date_Lte: queryParams.datetime_Date_Lte,
            datetime_Date_Gte: queryParams.datetime_Date_Gte,
            portfolioListIds: selectedNodes?.map(node=>node.data.node.id)
        }
        onSubmit(output)
    }

    const [columnDef, setColumnDef] = useState([
        {headerName:'ID', field: 'node.id',headerCheckboxSelection: true,checkboxSelection: true,headerCheckboxSelectionFilteredOnly: true, hide:true},
        {headerName:'Name', field:'node.name',headerCheckboxSelection: true,checkboxSelection: true,headerCheckboxSelectionFilteredOnly: true},
    ])

    const gridRef = useRef(null)

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);

    const rowData = useMemo(()=>{
        if(loading){gridRef?.current?.api.showLoadingOverlay();return null}
        gridRef?.current?.api.hideOverlay();
        return data?.allPortfolioLists?.edges
    },[data,loading])


    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>BBU Files</h1>
            <h2>Choose Relevant Date Range and Portfolios</h2>
            <DatePicker value={dayjs(queryParams.datetime_Date_Gte)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Gte', value:newValue}})}} label={'Start Date'}/>
            <DatePicker value={dayjs(queryParams.datetime_Date_Lte)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Lte', value:newValue}})}} label={'End Date'}/>

            <AgGridReact
                rowData={rowData}
                enableRangeSelection={false}
                rowSelection='multiple'
                checkboxSelection={true}
                ref={gridRef}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                ref={gridRef}
            />

            <Button variant={"outlined"} onClick={onBack}>Back</Button>
            <Button variant={"contained"} onClick={()=>{handleSubmit()}}>Submit</Button>
            <Button variant={"outlined"} onClick={onReset}>Reset</Button>

        </div>
    )

}


const Results = ({results, onBack, onReset})=>{
    const downloadRenderer = (params) => {return <a href={params?.data?.downloadUrl} target="_blank" rel="noopener noreferrer">Download</a>}
    const [columnDef, setColumnDef] = useState([
        {headerName:'ID', field: 'id',headerCheckboxSelection: true,checkboxSelection: true,headerCheckboxSelectionFilteredOnly: true},
        {headerName:'Name', field:'name'},
        {headerName:'Description', field:'description'},
        {headerName:'Download', cellRenderer:(params)=>downloadRenderer(params)},
    ])

    const gridRef = useRef(null)

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);



    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>Results</h1>
            <AgGridReact
                rowData={results}
                enableRangeSelection={false}
                ref={gridRef}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                ref={gridRef}
            ></AgGridReact>
            <Button variant={"outlined"} onClick={onBack}>Back</Button>
            <Button variant={"outlined"} onClick={onReset}>Reset</Button>
        </div>


    )
}