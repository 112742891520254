import {React, useState, useMemo, useCallback} from 'react';
import {gql, useQuery} from "@apollo/client";
import Select from 'react-select';




const GET_BROKERS = gql`query ALL_BROKERS($active:Boolean) {
  allBrokers(active: $active) {
    edges {      
      node {
        id        
        name
      }
    }    
  }
}
`


export function BrokerSelectWrapper({onChange}){
    const {loading,error,data, refetch} = useQuery(GET_BROKERS, {variables:{active:true}})
    const rowData = useMemo(()=>{if(loading || error){return []};return data?.allBrokers?.edges?.map((edge)=>{return edge?.node})},[data,loading]);
    return(<BrokerSelect rowData={rowData} onChange={onChange}/>)
}

export function BrokerSelect({rowData, onChange}){
    const formatOptionLabel = (option)=>{return <div>{option.name}</div>}
    const handleChange = (e)=>{onChange(e)}
    return (
        <div>
            <Select
                options={rowData}
                getOptionValue={(option)=>{return option.id}}
                onChange={(e)=>{handleChange(e)}}
                isMulti={false}
                isSearchable={false}
                formatOptionLabel={formatOptionLabel}
            />
        </div>
    );
}