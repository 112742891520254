import {React, useMemo, useRef, useState, useEffect, useCallback} from "react";
import {useQuery, gql} from "@apollo/client";
import {AgGridReact} from "ag-grid-react";
import { v4 as uuidv4 } from 'uuid';
import Button from "@mui/material/Button";
import {twoDecimalThousands} from "../../utils/Numbers";
import Overlay from "../shared/Overlay";

const GET_RELEVANT_DATA = gql`
query AllReconcileQuery(
  $portfolioId_In: [ID], 
  $datetime_Date_Gte: String, 
  $datetime_Date_Lte: String, 
  $active: Boolean,
  $custodianData:[GenericScalar],
  $reconcileSchemaId:ID,  
  $reconciliationDate:String
) {
  allPortfolioLists(id_In: $portfolioId_In) {
    edges {
      node {
        id
        alias
      }
    }
  }
  allGeneralLedgers(
    datetime_Date_Gte: $datetime_Date_Gte
    datetime_Date_Lte: $datetime_Date_Lte
    portfolioId_In: $portfolioId_In
  ) {
    edges {
      node {
        datetime_Date
        event {
          id
          name
          notes
          type
        }
        portfolio {
          id
          alias
        }
        amount
        shares
        security {
            id
            identifier {identifier}
        }
      }
    }
  }
  allSecurityMasters(active: $active) {
    edges {
      node {
        id
        active
        accountType
        financialStatement
        securityType
        sector
        identifier {
          identifier
        }
      }
    }
  }
  
  allReconcile(
    portfolioId_In: $portfolioId_In
    reconcileSchemaId: $reconcileSchemaId
    reconciliationDate: $reconciliationDate
    custodianData: $custodianData
  ) {
    edges {
           node {
        id
        lineNumber        
        matched
        portfolioList {
          id
          alias
        }
        posExt
        posInt
        reconcileEntry {
          id
          security{identifier{identifier}}
          entry
        }
        securityMaster {
          id
          identifier{identifier}
        }
        status
      }
    }
  }
  
}
`





const makeStartDate = (date)=>{
    const d = new Date(date);
    d.setDate(d.getDate()-90);
    return d.toISOString().split('T')[0]
}


export function Reconcile({onSubmit, onBack, onReset, params, custodianData}){
    const [excludedData,setExcludedData] = useState([])
    const [newGls, setNewGls] = useState([])
    const [reconcileSelected, setReconcileSelected] = useState(null)

    const [reconcileTable, setReconcileTable] = useState([])

    const changeExcluded = (data)=>{setExcludedData(data)}
    const handleReconcileSelection = (data)=>{setReconcileSelected(data)}
    const handleReconcileTableChange = (data)=>{setReconcileTable(data)}
    const handleNewGlsTableChange = (data)=>{setNewGls(data)}
    const handleBackOut = (data)=>{
        const output = data.filter((row)=>{return row.netDifference!=0}).map((row)=>{
            return(
                {
                    id: uuidv4(),
                    portfolioId: row.portfolioList.id,
                    securityId: row.securityMaster.id,
                    eventName:'Plug',
                    eventType:'Plug',
                    amount:0,
                    shares:row.netDifference,
                    include:true,
                    notes:'Backed out from reconciliation',
                    datetime: params.reconciliationDate,
                    postingDatetime: params.reconciliationDate
                }
            )
        });
        // concatenate output with newGls
        setNewGls(newGls.concat(output))
    }

    const handleSubmit = useCallback(()=>{onSubmit({excludedData:excludedData, newGls:newGls, reconcileTable:reconcileTable})},
        [excludedData,newGls,reconcileTable]
    )

    const {data, loading, error} = useQuery(GET_RELEVANT_DATA, {
        variables:{
            portfolioId_In: params.portfolios,
            datetime_Date_Gte: makeStartDate(params.reconciliationDate),
            datetime_Date_Lte: params.reconciliationDate,
            active: Boolean,
            custodianData:custodianData,
            reconcileSchemaId:params.reconcileSchema,
            reconciliationDate:params.reconciliationDate,

        },
        fetchPolicy: "network-only",
    })

    const groupedExcludedData = useMemo(()=>{
        const groupedData = excludedData.reduce((acc, item) => {
            const key = `${item.security.id}-${item.portfolio.id}`;
            const existingItem = acc.find(x => x.key === key);
            if (existingItem) {existingItem.shares += item.shares;} else {
                acc.push({
                    key: key,
                    security: item.security.id,
                    portfolio: item.portfolio.id,
                    shares: -item.shares
                });
            }

            return acc;
        }, []);
        groupedData.forEach(item => delete item.key);
        return groupedData.filter((row)=>{return row.shares!=0})
    },[excludedData])

    const groupedNewGls = useMemo(()=>{
        const groupedData = newGls.reduce((acc, item) => {
            const key = `${item.securityId}-${item.portfolioId}`;
            const existingItem = acc.find(x => x.key === key);
            if (existingItem) {existingItem.shares += item.shares;} else {
                acc.push({
                    key: key,
                    security: item.securityId,
                    portfolio: item.portfolioId,
                    shares: item.shares
                });
            }

            return acc;
        }, []);
        groupedData.forEach(item => delete item.key);
        return groupedData.filter((row)=>{return row.shares!=0})
    },[newGls])

    const portfolioLists = useMemo(()=>{
        if(loading){return[]}
        return data.allPortfolioLists.edges.map((item)=>{return item.node})
    },[data,loading])

    const generalLedgers = useMemo(()=>{
        if(loading){return[]}
        return data.allGeneralLedgers.edges.map((item)=>{return item.node})
    },[data,loading])

    const securityMasters = useMemo(()=>{
        if(loading){return[]}
        return data.allSecurityMasters.edges.map((item)=>{return item.node})
    },[data,loading])

    const reconciliationData = useMemo(()=>{
        if(loading){return[]}
        const output = data.allReconcile.edges.map((item)=>{return item.node});
        setReconcileTable(output);
        return output},[data,loading])

    return(
        <div>
            <Overlay open={loading} />
            <ReconcileTable
                data={reconciliationData}
                handleSelection={handleReconcileSelection}
                excludedData={groupedExcludedData}
                newGls = {groupedNewGls}
                params={params}
                handleReconcileTableChange={handleReconcileTableChange}
                sm={securityMasters}
                pl={portfolioLists}
                onBackOut={handleBackOut}
            />

            <RecentGlsTable
                data={generalLedgers}
                onSetExcluded={changeExcluded}
                reconcileSelected={reconcileSelected}
            />

            <NewGlsTable
                data={newGls}
                onDataChange={handleNewGlsTableChange}
                sm={securityMasters}
                pl={portfolioLists}
            />


            <div style={{marginTop:'100px'}}>
                <Button variant={'outlined'} onClick={onBack}>Back</Button>
                <Button variant={'contained'} onClick={handleSubmit}>Next</Button>
                <Button variant={'outlined'} onClick={onReset}>Reset</Button>
            </div>
        </div>
    )
}

const ReconcileTable = ({data, handleSelection, excludedData, newGls, params, handleReconcileTableChange, sm, pl, onBackOut})=>{

    const gridRef = useRef(null)
    const rowData = useMemo(()=>{return data.map((item)=>({...item, override:0}))},[data]);

    const grossDifferenceGetter = (params)=>{
        const external = params.data.posExt == null ? 0 : params.data.posExt
        const internal = params.data.posInt == null ? 0 : params.data.posInt
        return external - internal
    }
    const netGetter = (params)=>{
        const external = params.data.posExt == null ? 0 : params.data.posExt
        const internal = params.data.posInt == null ? 0 : params.data.posInt
        const override = params.data.override == null ? 0 : params.data.override
        const excluded = params.getValue('exclude') == null ? 0 : params.getValue('exclude')
        const newGls = params.getValue('newGls') == null ? 0 : params.getValue('newGls');
        return Math.round((external - internal - override - excluded - newGls)*100)/100
    }

    const excludedGetter = (params)=>{
        const securityId = params?.data?.securityMaster?.id
        const portfolioId = params?.data?.portfolioList?.id
        const row = excludedData.find((row)=>{return row.security === securityId && row.portfolio === portfolioId})
        if(row!=null){return row.shares}
        return 0
    }

    const newGlsGetter = (params)=>{
        const securityId = params?.data?.securityMaster?.id
        const portfolioId = params?.data?.portfolioList?.id
        const row = newGls.find((row)=>{return row.security === securityId && row.portfolio === portfolioId})
        if(row!=null){return row.shares}
        return 0
    }

    const backOut = ()=>{
        let updatedGrid = []
        gridRef?.current?.api?.getSelectedNodes().forEach((row)=>{
            const security = row.data.securityMaster.id
            const portfolio = row.data.portfolioList.id
            const excluded = 0
            const gls = 0
            const posExternal = row.data.posExt == null ? 0 : row.data.posExt
            const posInternal = row.data.posInt == null ? 0 : row.data.posInt
            const override = row.data.override == null ? 0 : row.data.override
            const netDifference = posExternal - posInternal - override - excluded - gls
            const newRow = {...row.data, excluded: excluded, newGls: gls, netDifference: netDifference}
            updatedGrid.push(newRow)
        })

        onBackOut(updatedGrid)
    }

    const columnDef = useMemo(()=>{
        return(
            [
                {field: 'securityMaster.identifier.identifier.ticker',headerName:'Ticker',headerCheckboxSelection: true,checkboxSelection: true,headerCheckboxSelectionFilteredOnly: true},
                {field:'portfolioList.alias', headerName:'Portfolio'},
                {field:'status', headerName:'Merge Status'},
                {field:'posExt', headerName:'External Pos', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                {field:'posInt', headerName:'Internal Pos', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                {field:'grossDifference', headerName:'Gross Difference (E-I)', valueGetter: grossDifferenceGetter, valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                {field: 'override', headerName: 'Override', editable:true, filter: 'agNumberColumnFilter', valueFormatter: twoDecimalThousands},
                {field: 'exclude', headerName:'Excluded',  valueGetter: excludedGetter, valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                {field: 'newGls', headerName: 'New Gls', valueGetter: newGlsGetter, valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                {field: 'netDifference', headerName: 'Net Difference (E-I*)', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter', valueGetter:netGetter},
            ]
        )
    },[excludedData, newGls])

    useEffect(()=>{
        excludedData.forEach((row)=>{
            let gridData = []
            gridRef.current.api.getModel().rowsToDisplay.forEach((rowData)=>{gridData.push(rowData.data)});
            const missing = gridData.find((item)=>{return item.securityMaster.id === row.security && item.portfolioList.id === row.portfolio}) === undefined ? true : false
            if(missing){
                const portfolio = pl?.find((item)=>{return item.id === row.portfolio})
                const security = sm?.find((item)=>{return item.id===row.security})
                const newLine = {
                    securityMaster: security,
                    portfolioList: portfolio,
                    posExt: 0,
                    posInt: 0,
                    override: 0,
                    status:'new'
                }
                gridRef.current.api.applyTransaction({ add: [newLine] });
            }
        })
    },[excludedData])

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        };
    }, []);

    const onSelectionChanged = ()=>{
        const selected = gridRef.current.api.getSelectedNodes().map(node => node.data)
        if(selected.length>0){handleSelection(selected[0])}
        else{handleSelection(null)}
    }
    const onCellValueChanged = ()=>{handleReconcileTableChange(gridRef.current.api.getModel().rowsToDisplay.map((row)=>{return row.data}));}

    return(
        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
            <h3>Reconciliation Table</h3>
            <Button variant={'outlined'} onClick={backOut}>Back Out</Button>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='single'
                checkboxSelection={true}
                ref={gridRef}
                onSelectionChanged={onSelectionChanged}
                onCellValueChanged={onCellValueChanged}
            />
        </div>
    )
}

const RecentGlsTable = ({data, reconcileSelected, onSetExcluded})=>{
    const rowData = useMemo(()=>data, [data])
    const gridRef = useRef(null)

    const [columnDef, setColumnDef] = useState([
        {field: 'event.id',headerCheckboxSelection: true,checkboxSelection: true,headerCheckboxSelectionFilteredOnly: true, rowGroup:true, groupDefaultExpanded:true},
        {field:'event.name', headerName:'Event'},
        {field:'portfolio.alias', headerName:'Portfolio'},
        {field:'portfolio.id', headerName:'portfolioId',hide:true},
        {field:'datetime_Date', headerName:'Date'},
        {field: 'security.identifier.identifier.ticker', headerName:'Ticker'},
        {field: 'security.identifier.identifier.id', headerName:'securityId', hide:true},
        {field:'shares', headerName:'Shares', valueFormatter: twoDecimalThousands},


    ])

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        };
    }, []);

    const onSelectionChanged = ()=>{
        onSetExcluded(gridRef.current.api.getSelectedNodes().map(node => node.data))
    }

    if(reconcileSelected != null){
        if(gridRef?.current?.api){
            const portfolioId = reconcileSelected?.portfolioList?.id
            const securityId = reconcileSelected?.securityMaster?.id
            const events = rowData.filter((gl)=>{return gl.portfolio.id === portfolioId && gl.security.id === securityId}).map((gl)=>{return gl.event.id})
            gridRef.current.api.setFilterModel({"event.id": {"values": events,"filterType": "set"},})
        }
    }

    if(reconcileSelected==null){if(gridRef?.current?.api){gridRef.current.api.setFilterModel(null)}}

    return(
        <div style={{marginTop:'50px'}}>
        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
            <h3>Recent General Ledger Entries -- {reconcileSelected===null ? 'All' : 'Filtered'}</h3>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={false}
                enableRangeSelection={false}
                rowSelection='multiple'
                checkboxSelection={true}
                suppressRowClickSelection={true}
                ref={gridRef}
                groupDefaultExpanded={1}
                groupSelectsChildren={true}
                onSelectionChanged={onSelectionChanged}
            />
        </div>

            </div>
    )
}

const NewGlsTable = ({data, sm,pl, onDataChange})=>{

    const gridRef = useRef(null)
    const dataRef = useRef(data)
    const smRef = useRef(sm)
    const plRef = useRef(pl)

    useEffect(()=>{dataRef.current=data},[data])
    useEffect(()=>{smRef.current=sm},[sm])
    useEffect(()=>{plRef.current=pl},[pl])
    const removeRenderer = props => {
        const removeRow = (id)=>{onDataChange(dataRef.current.filter((row)=>{return row.id != id}))}
        return <Button onClick={()=>{removeRow(props.data.id)}}>Remove</Button>
    }

    const securityRenderer = (params)=>{
        const security = smRef?.current?.find((row)=>{return row.id === params.value})
        return security?.identifier?.identifier?.ticker
    }

    const portfolioRenderer=(params)=>{
        const portfolio = plRef?.current?.find((row)=>{return row.id===params.value})
        return portfolio?.alias
    }

    const [columnDef,setColumnDef] = useState([
        {field: 'id',headerCheckboxSelection: false,checkboxSelection: false,headerCheckboxSelectionFilteredOnly: false},
        {field: 'portfolioId', headerName:'Portfolio', editable:false, cellRenderer: portfolioRenderer},
        {field: 'securityId', headerName:'Security', cellRenderer: securityRenderer, editable:false},
        {field: 'eventName', headerName: 'Event Name', editable:true},
        {field: 'eventType', headerName:'Event Type', editable:true},
        {field:'amount', headerName:'Amount', editable:true, valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'shares', headerName:'Shares', editable:true, valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'include', headerName:'Include', editable:true},
        {field:'notes', headerName: 'Notes', editable: true},
        {field:'datetime', headerName:'Date', editable:true},
        {field:'postingDatetime', headerName:'Posting Date', editable: true},
        {field: 'delete', cellRenderer: removeRenderer, editable:false}
    ])

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        };
    }, []);

    const addEntry = ()=>{
        const id = uuidv4()
        const newData = [...data]
        newData.push({id:id})
        onDataChange(newData)
    }
    const onCellEditRequest = (event)=>{
        const oldData = event.data;
        const field = event.colDef.field;
        const newValue = event.newValue;
        const oldItem = data.find((row) => row.id === oldData.id);
        let newItem = { ...oldItem };
        newItem[field] = newValue;
        const output = data.map((oldItem) =>oldItem.id == newItem.id ? newItem : oldItem);
        onDataChange(output);
        gridRef.current.api.setGridOption('rowData', output);
    }

    const getRowId = (data)=>data.data.id

    return(
        <div style={{marginTop:'50px'}}>
        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
            <h3>New General Ledger Entries</h3>
            <Button variant={'outlined'} onClick={()=> {addEntry()}}>New Entry</Button>
            <AgGridReact
                rowData={data}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={true}
                rowSelection='multiple'
                checkboxSelection={true}
                readOnlyEdit ={true}
                onCellEditRequest = {onCellEditRequest}
                ref={gridRef}
                getRowId = {getRowId}
            />
        </div>
    </div>
    )
}