import {React, useState, useMemo, useCallback} from 'react';
import {gql, useQuery} from "@apollo/client";
import Select from 'react-select';




const GET_PL = gql`query ALL_PL($active:Boolean) {
  allPortfolioLists(active: $active) {
    edges {
      cursor
      node {
        id
        alias
        active
        name
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`


export function PortfolioSelectWrapper({onChange, isMulti=false, title='Select...'}){
    const {loading,error,data, refetch} = useQuery(GET_PL, {variables:{active:true}})
    const rowData = useMemo(()=>{if(loading || error){return []};return data?.allPortfolioLists?.edges?.map((edge)=>{return edge?.node})},[data,loading]);
    return(<PortfolioSelect rowData={rowData} onChange={onChange} isMulti={isMulti} title={title}/>)
}

export function PortfolioSelect({rowData, onChange, isMulti, title}){
    const formatOptionLabel = (option)=>{return <div>{option.alias}</div>}
    const handleChange = (e)=>{onChange(e)}
    return (
        <div>
            <Select
                options={rowData}
                getOptionValue={(option)=>{return option.id}}
                onChange={(e)=>{handleChange(e)}}
                isMulti={isMulti}
                isSearchable={false}
                formatOptionLabel={formatOptionLabel}
                placeholder={title}

            />
        </div>
    );
}