import Button from "@mui/material/Button";
import {FileUploader} from "../components/FileUploader";
import {React, useState, useMemo, useRef} from "react";
import {gql, useMutation} from "@apollo/client";
import {twoDecimalThousands, percentTwoDecimal, percentOneDecimal, noDecimalThousands} from "../utils/Numbers";
import {AgGridReact} from "ag-grid-react";
import Overlay from "../components/shared/Overlay";


const ADD_FILL_FILE = gql`
    mutation AddFillFile($input:AddFileMutationInput!) {
        addFile(input: $input) {
        file {
        id
        name
        description
        downloadUrl
    }
  }
}`
const PROCESS_FILE = gql`mutation ProcessFileMutation($input:ProcessFileMutationInput!) {
processFile(input: $input) {
    fills {id}
}
}`
const NEW_FILL_MUTATION = gql`
    mutation NewFillMutation($input:NewFillMutationInput!) {
    newFills(input: $input) {fills {id}}
}
`

export function NewFills(){
    const [pageIter, setPageIter] = useState(0)
    const [pageState, setPageState] = useState('init')
    const [savedFills, setSavedFills] = useState([])
    const [fillData, setFillData] = useState([])
    const [addFills, {data:addFillsData, loading:addFillsLoading, error:addFillsError}] = useMutation(NEW_FILL_MUTATION);
    const [showOverlay, setShowOverlay] = useState(false);
    const handleSuccess = (data)=>{
        console.log('successfully saved...', data)
        setSavedFills(data);
        setPageState('results');
    }
    const handleError = (e)=>{console.log('Error', e)}

    const handleStage = (data)=>{
        setPageState('confirm');
        setFillData(data)
    }

    const handleSubmit = (data)=>{
        const payload = data.map((item)=>{return(
            {
                datetime:item.datetime,
                routeId:item.routeId,
                shares:item.shares,
                px:item.px,
                fx:item.fx,
                com:item.com,
                fee:item.fee,
                interest:item.interest,
                notes:item.notes
            }
        )})
        setShowOverlay(true);
        addFills({
            variables:{"input":{"data":payload}},
            onCompleted: (addFillsData)=>{
                setShowOverlay(false)
                setSavedFills(addFillsData.newFills.fills.map((fill)=>{return fill.id}));
                setPageState('results')
            },
            onError: (error)=>{
                console.log(error);
                setShowOverlay(false)
            }
        })
    }
    const handleReset = ()=>{
        setFillData([]);
        setPageState('init');
        setPageIter(pageIter+1);
    }

    return(
        <div key={pageIter}>
            <Overlay open={showOverlay}/>
            <h1>New Fills</h1>
            <div style={{display: pageState==='init'?'block':'none'}}><Init setPageState={setPageState}/></div>
            <div style={{display: pageState==='fillForm'?'block':'none'}}><FillForm fillData={fillData} setPageState={setPageState} onSubmit={handleStage} onReset={handleReset} onBack={()=>{setPageState('init')}}/></div>
            {pageState === 'confirm' ? <Confirm setPageState={setPageState} fillData={fillData} onSubmit={handleSubmit} onBack={()=>{setPageState('fillForm')}} onReset={handleReset}/> : null}
            <div style={{display: pageState==='upload'?'block':'none'}}><Upload onSuccess={handleSuccess} onError={handleError} onBack={()=>{setPageState('init')}}/></div>
            <div style={{display: pageState==='results'?'block':'none'}}><Results fills={savedFills} onReset={handleReset}/></div>
        </div>
    )}


const Init = ({setPageState})=>{
    return(
        <div>
            <Button variant={'outlined'} onClick={()=>{setPageState('fillForm')}}>Manual</Button>
            <Button variant={'outlined'} onClick={()=>{setPageState('upload')}}>Upload</Button>
        </div>
    )
}


function Upload({onSuccess,onError,onBack}){
    const [pageState, setPageState] = useState('initial')
    const [addFillFile, {data, loading, error}] = useMutation(ADD_FILL_FILE)
    const [processFillFile, {data:processData, loading:processLoading, error:processError}] = useMutation(PROCESS_FILE)
    const [showOverlay,setShowOverlay] = useState(false)
    const handleS3Error = ()=>{setPageState('s3 error')}
    const handleS3Success = (x)=>{setPageState('s3 success');saveFile(x)}
    const saveFile = (x)=>{
        addFillFile({
            variables:{
                input:{
                    description: 'groms uploaded emsx fill file',
                    file: x.uploadDetails.fields.key,
                    name: x.file.path
                }
            },
            onCompleted: (data)=>{
                setPageState('successfully saved to groms');
                processFile(data.addFile.file.id)
            },
            onError: (e)=>{
                console.log('error in saveFile', e)
                setPageState('error saving to groms')
            }
        });

    };
    const processFile = (fileId)=>{
        setShowOverlay(true)
        processFillFile({
            variables:{
                input:{
                    fileId: fileId,
                    fileType: 'emsxFills'
                }
            },
            onCompleted: (processData)=>{
                const savedFills = processData.processFile.fills.map((x)=>x.id);
                setShowOverlay(false)
                onSuccess(savedFills);
                setPageState('successfully processed file');
            },
            onError: (e)=>{
                console.log('error in processFile', e)
                setShowOverlay(false)
                setPageState('error processing file')
            }
        })

    }



    return(
        <div>
            <Overlay open={showOverlay}/>
            <h2>Please Drop a Valid EMSX Fill File.</h2>
            <FileUploader onError={handleS3Error} onSuccess={handleS3Success}/>
            <Button variant={'outlined'} onClick={()=>{onBack()}}>Back</Button>
            {error?.map((e,i)=>{return(<h5 key={i} style={{color:'red'}}>{e}</h5>)})}
            {processError?.map((e,i)=>{return(<h5 key={i} style={{color:'red'}}>{e}</h5>)})}
        </div>
    )
}


function FillForm({fillData, onSubmit,onBack, onReset}){
    const gridRef = useRef(null)
    const rowData = useMemo(()=>{return fillData},[fillData])
    const removeRenderer = props => {
        const onClick = ()=>{gridRef.current.api.applyTransaction({remove:[{id:props.data.id}]})}
        return <Button onClick={onClick}>Remove</Button>
    }
    const addRow = ()=>{
        let maxRow = 0
        gridRef?.current?.api.forEachNode((node)=>{if(node.data.id>maxRow){maxRow=node.data.id}})
        const newRow = {id:maxRow+1}
        gridRef?.current?.api?.applyTransaction({add:[newRow]})
    }


    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
            editable:true,
        };
    }, []);

    const columnDef = useMemo(()=>{
        return([
            {field:'id', headerName:'ID'},
            {field:'datetime', headerName:'Datetime', type:'dateTime'},
            {field:'routeId', headerName:'Route ID'},
            {field:'shares', headerName:'Shares', filter:'agNumberColumnFilter', valueParser: params=>Number(params.newValue), valueFormatter: noDecimalThousands},
            {field:'px', headerName:'Price', filter:'agNumberColumnFilter', valueParser: params=>Number(params.newValue), valueFormatter:twoDecimalThousands},
            {field:'fx', headerName:'FX', filter:'agNumberColumnFilter', valueParser: params=>Number(params.newValue), valueFormatter: twoDecimalThousands},
            {field:'com', headerName:'Com', filter:'agNumberColumnFilter', valueParser: params=>Number(params.newValue), valueFormatter: twoDecimalThousands},
            {field:'fee', headerName:'Fee', filter:'agNumberColumnFilter', valueParser: params=>Number(params.newValue), valueFormatter: twoDecimalThousands},
            {field:'interest', headerName:'Interest', filter:'agNumberColumnFilter', valueParser: params=>Number(params.newValue), valueFormatter: twoDecimalThousands},
            {field:'notes', headerName:'Notes'},
            {field: 'delete', headerName:'Delete', cellRenderer: removeRenderer, editable:false}
        ])
    },[])

    const handleSubmit = ()=>{
        let output = []
        gridRef?.current?.api?.forEachNode((node)=>{output.push(node.data)})
        onSubmit(output);
    }

    return(
        <div>
            <h2>New Fill Form</h2>
            <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>

                <Button variant={"outlined"} onClick={addRow}>Add Fill</Button>
                <AgGridReact
                    rowData={rowData}
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    reactiveCustomComponents={true}
                    columnDefs={columnDef}
                    animateRows={true}
                    enableRangeSelection={false}
                    rowSelection='none'
                    checkboxSelection={false}
                    getRowId = {params=>  params.data.id}
                />
                <Button variant={"outlined"} onClick={onBack}>Back</Button>
                <Button variant={"contained"} onClick={handleSubmit}>Stage</Button>
                <Button variant={"outlined"} onClick={onReset}>Reset</Button>
            </div>

        </div>
    )
}



function Confirm({fillData, onSubmit, onBack, onReset}){
    const rowData = useMemo(()=>{return fillData},[fillData])
    const gridRef = useRef()
    const columnDef = useMemo(()=>{
        return([
            {field:'id', headerName:'ID'},
            {field:'datetime', headerName:'Datetime', type:'dateTime'},
            {field:'routeId', headerName:'Route ID'},
            {field:'shares', headerName:'Shares', filter:'agNumberColumnFilter'},
            {field:'px', headerName:'Price', filter:'agNumberColumnFilter', valueParser: params=>Number(params.newValue), valueFormatter:twoDecimalThousands},
            {field:'fx', headerName:'FX', filter:'agNumberColumnFilter', valueParser: params=>Number(params.newValue), valueFormatter: twoDecimalThousands},
            {field:'com', headerName:'Com', filter:'agNumberColumnFilter', valueParser: params=>Number(params.newValue), valueFormatter: twoDecimalThousands},
            {field:'fee', headerName:'Fee', filter:'agNumberColumnFilter', valueParser: params=>Number(params.newValue), valueFormatter: twoDecimalThousands},
            {field:'interest', headerName:'Interest', filter:'agNumberColumnFilter', valueParser: params=>Number(params.newValue), valueFormatter: twoDecimalThousands},
            {field:'notes', headerName:'Notes'},
        ])
    },[])

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
            editable:false,
        };
    }, []);
    const handleSubmit = ()=>{
        let output = []
        gridRef?.current?.api?.forEachNode((node)=>{output.push(node.data)})
        onSubmit(output);
    }
    return(
        <div>
            <h2>Confirm</h2>
            <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
                <AgGridReact
                    rowData={rowData}
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    reactiveCustomComponents={true}
                    columnDefs={columnDef}
                    animateRows={true}
                    enableRangeSelection={false}
                    rowSelection='none'
                    checkboxSelection={false}
                    getRowId = {params=>  params.data.id}
                />
                <Button variant={'outlined'} onClick={onBack}>Back</Button>
                <Button variant={"contained"} onClick={handleSubmit}>Submit</Button>
                <Button variant={'outlined'} onClick={onReset}>Reset</Button>
            </div>
        </div>
    )
}





function Results({fills, onReset}){
    return(
        <div>
            <h2>Results</h2>
            {fills.map((x)=><p>{x}</p>)}
            <Button variant={'outlined'} onClick={()=>{onReset()}}>Reset</Button>
        </div>
    )
}