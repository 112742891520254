import React, { createContext, useState, useContext } from 'react';
import {useLocation, Navigate, useNavigate} from 'react-router-dom';
import {gql, useMutation} from "@apollo/client";

export let GromsContext = createContext();

export function GromsProvider({children}) {
    const [user, setUser] = useState({user:null, token:null, isAuthenticated:false});
    const signin = (user, token, isAuthenticated, callback) => {
        setUser({user:user, token:token ,isAuthenticated:isAuthenticated});
        localStorage.setItem('gromsToken',token);
        callback();
    };
    const signout = () => {setUser({user:null, token:null,isAuthenticated:false});localStorage.removeItem('gromsToken')};

    let value = {user, signin, signout};
    return <GromsContext.Provider value={value}>{children}</GromsContext.Provider>;
}



const VERIFY_TOKEN = gql`mutation verifyToken($token: String!) {
  verifyToken(input: {token: $token}) {
    payload
  }
}`

export function RequireAuth({ children }){
    const [verifyToken, { loading, error, data, reset }] = useMutation(VERIFY_TOKEN);
    const navigate = useNavigate();
    const location = useLocation();
    let auth = useContext(GromsContext)
    if(auth.user.isAuthenticated){return children}
    if(!auth.userisAuthenticated) {
        let savedToken = localStorage.getItem('gromsToken')
        if(savedToken){
            verifyToken({
                variables: {token: savedToken},
                onCompleted: (data) => {

                    auth.signin(
                        data.verifyToken.payload.username,
                        savedToken,
                        true,
                        () => {
                            navigate(location)
                        }
                    );
                },
                onError: (error) => {

                    auth.signout();
                    navigate('/login')
                }
            })
            }
        if(!savedToken){
            return <Navigate to='/login' state={{from: location}}/>
        }
    }
}