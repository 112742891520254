var numbro = require("numbro");

export function formatNumber(value, format) {
  if(value === null || value === undefined || value===0) {return '--';}
  return numbro(value).format(format);
}

export function twoDecimalThousands(params) {
  if(params.value === null || params.value === undefined || params.value===0) {return '--';}
  return formatNumber(params.value, "0,0.00");
}

export function sixDecimalThousands(params) {
  if(params.value === null || params.value === undefined || params.value===0) {return '--';}
  return formatNumber(params.value, "0,0.000000");
}

export function noDecimalThousands(params) {
  if(params.value === null || params.value === undefined || params.value===0) {return '--';}
  return numbro(params.value).format({thousandSeparated: true, mantissa: 0})
}

export function percentOneDecimal(params) {
  if(params.value === null || params.value === undefined || params.value===0) {return '--';}
  return formatNumber(params.value, "0.0%");
}

export function percentTwoDecimal(params) {
  if(params.value === null || params.value === undefined || params.value===0) {return '--';}
  return formatNumber(params.value, "0.00%");
}

export function average(params){
  if(params.value===null || params.value===undefined || params.value===0) {return '--';}
    return formatNumber(params.value, {average:true});
}
