import {gql, useQuery} from "@apollo/client";
import React, {useState, useMemo, useRef, useCallback} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {getPrimaryKeyFromRelay} from "../utils/Utils";

const GET_SM = gql`query ALL_SM{
  allSecurityMasters{
    edges {
      cursor
      node {
        id
        identifier {
          identifier
        }
        longName
        currency
        sector
        securityType
        financialStatement
        active
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`

export  function SecurityMasters(){
    const {loading, error, data, refetch} = useQuery(GET_SM);

    const [columnDef, setColumnDef] = useState([
        {field:'node.id'},
        {field:'securityId', headerName: 'Security ID', valueGetter: params=>getPrimaryKeyFromRelay(params.data.node.id), hide:true},
        {field:'node.identifier.identifier.ticker', headerName: 'Ticker'},
        {field:'node.identifier.identifier.sedol', headerName: 'Sedol'},
        {field:'node.identifier.identifier.isin', headerName: 'ISIN'},
        {field:'node.longName', headerName: 'Name'},
        {field:'node.currency', headerName: 'Currency'},
        {field:'node.sector', headerName: 'Sector'},
        {field:'node.securityType', headerName: 'Security Type'},
        {field:'node.financialStatement', headerName: 'Financial Statement'},
        {field:'node.active', headerName: 'Active'},

    ])
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);

    const rowData = useMemo(()=>{
        if(loading){gridRef?.current?.api.showLoadingOverlay();return null}
        gridRef?.current?.api.hideOverlay();
        return data.allSecurityMasters?.edges
    },[data, loading])

    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>Security Master</h1>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='none'
                checkboxSelection={false}
                ref={gridRef}
            ></AgGridReact>
            <span>Total Entries: {rowData?.length || 0}</span>
        </div>
    )
}