import {React, useMemo, useRef, useState} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import Button from "@mui/material/Button";
import {AgGridReact} from "ag-grid-react";
import Overlay from "../components/shared/Overlay";

import {twoDecimalThousands, noDecimalThousands, percentOneDecimal} from "../utils/Numbers";

const GET_UNPROCESSED_RAS = gql`
query UnprocessedRas($event_Generalledgers_Isnull: Boolean!, $route_Datetime_Date_Gte: String!, $filled_Gt: Decimal!) {
  allRouteAllocations(
    event_Generalledgers_Isnull: $event_Generalledgers_Isnull
    route_Datetime_Date_Gte: $route_Datetime_Date_Gte
    filled_Gt: $filled_Gt
  ) {
    edges {
      node {
        id
        px
        shares
        filled
        route {
        broker{name}
          datetime_Date
          order {
            side
            security {
              identifier {
                identifier
              }
            }
          }
        }
        orderAllocation {
          portfolio {
            id
            name
          }
        }
      }
    }
  }
}
`

const GET_UNPROCESSED_FLOWS = gql`
query UnprocessedFlows(
    $datetime_Date_Gte:String!, 
    $amount_Gt:Decimal!, 
    $event_GeneralLedgers_Isnull:Boolean!
    ){
  allFlows(
    datetime_Date_Gte: $datetime_Date_Gte
    amount_Gt: $amount_Gt
    event_Generalledgers_Isnull: $event_GeneralLedgers_Isnull
  ) {
    edges {
      node {
        id
        amount
        currency
        datetime_Date
        direction
        portfolio {
          name
        }
      }
    }
  }
}
`

const PROCESS_GLS = gql`
mutation ProcessGls($input:ProcessGlsInput!) {
processGls(input: $input) {
    generalLedgers {id}
  }
}
`


export function NewGls(){
    const [pageState, setPageState] = useState('initial');
    const [pageIter, setPageIter] = useState(0);
    const [results, setResults] = useState([]);
    const [processGls, {loading, error, data}] = useMutation(PROCESS_GLS)
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false)

    const handlePick = (x)=>{setPageState(x)}
    const handleBack = ()=>{setPageState('initial')}
    const handleReset = ()=>{setResults([]); setPageState('initial'); setPageIter(pageIter+1)}

    const handleSubmit = (x)=>{
        setShowLoadingOverlay(true)
        processGls({
            variables:{input:x},
            onCompleted:(data)=>{
                setResults(data.processGls.generalLedgers)
                setPageState('results')
                setShowLoadingOverlay(false)
            },
            onError:(error)=>{
                console.log(error)
                setShowLoadingOverlay(false)}
        }
        )
    }



    return(
        <div key={pageIter}>
            <Overlay open={showLoadingOverlay}/>
            <h1>Process General Ledgers</h1>
            <div style={{display: pageState==='initial'?'block':'none'}}><Initial onPick={handlePick}/></div>
            <div style={{display: pageState==='pickRas'?'block':'none'}}><PickRas onBack={handleBack} onReset={handleReset} onSubmit={handleSubmit}/> </div>
            <div style={{display: pageState==='pickFlows'?'block':'none'}}><PickFlows onBack={handleBack} onReset={handleReset} onSubmit={handleSubmit}/></div>
            <div style={{display: pageState==='results'?'block':'none'}}><Results onBack={handleBack} onReset={handleReset} data={results}/></div>
        </div>
    )
}


const Initial = ({onPick})=>{
    return(
        <div>
            <Button variant={"outlined"} onClick={()=>{onPick('pickRas')}}>Route Allocations</Button>
            <Button variant={"outlined"} onClick={()=>{onPick('pickFlows')}}>Flows</Button>
        </div>
    )
}

const PickRas = ({onBack, onSubmit, onReset})=>{
    const {loading, error, data} = useQuery(GET_UNPROCESSED_RAS, {variables:{event_Generalledgers_Isnull:true, route_Datetime_Date_Gte:'2022-01-01', filled_Gt:0}, fetchPolicy: 'network-only'})
    const [columnDef, setColumnDef] = useState([
        {field: 'node.id',headerCheckboxSelection: true,checkboxSelection: true,headerCheckboxSelectionFilteredOnly: true},
        {field:'node.route.order.security.identifier.identifier.ticker', headerName:'Ticker'},
        {field:'node.orderAllocation.portfolio.name', headerName:'Portfolio'},
        {field:'node.route.datetime_Date', headerName:'Date', filter: 'agDateColumnFilter'},
        {field:'node.shares', headerName:'Shares', valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'node.filled', headerName:'Filled', valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
        {field: 'node.route.order.side', headerName:'Side'},
        {field: 'node.px', headerName: 'Px', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
        {field: 'node.route.broker.name', headerName: 'Broker'},
    ])
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        };
    }, []);

    const handleBack = ()=>{onBack()}
    const handleSubmit = ()=>{onSubmit({routeAllocationId: gridRef.current.api.getSelectedNodes().map(node => node.data.node.id)})}
    const handleReset = ()=>{onReset()}

    const rowData = useMemo(()=>{
        if(loading){
            gridRef?.current?.api.showLoadingOverlay();
            return null
        }
        gridRef?.current?.api.hideOverlay();
        return data.allRouteAllocations?.edges
    },[data,loading])


    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h2>Pick Ras</h2>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={true}
                rowSelection='multiple'
                checkboxSelection={true}
                ref={gridRef}
            />
            <Button variant={"outlined"} onClick={handleBack}>Back</Button>
            <Button variant={"contained"} onClick={handleSubmit}>Submit</Button>
            <Button variant={"outlined"} onClick={handleReset}>Reset</Button>

        </div>
    )
}

const PickFlows = ({onBack, onSubmit, onReset})=>{
    const {loading, error, data} = useQuery(GET_UNPROCESSED_FLOWS, {variables:{datetime_Date_Gte: "2022-01-01",amount_Gt: 0,event_GeneralLedgers_Isnull: true}, fetchPolicy: 'network-only'})
    const [columnDef, setColumnDef] = useState([
        {field: 'node.id',headerCheckboxSelection: true,checkboxSelection: true,headerCheckboxSelectionFilteredOnly: true},
        {field:'node.portfolio.name', headerName:'Portfolio'},
        {field:'node.datetime_Date', headerName:'Date', filter: 'agDateColumnFilter'},
        {field:'node.amount', headerName:'Amount', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'node.currency', headerName:'Currency'},
        {field:'node.direction', headerName:'Direction'},

    ])
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        };
    }, []);

    const handleBack = ()=>{onBack()}
    const handleSubmit = ()=>{onSubmit({flowId: gridRef.current.api.getSelectedNodes().map(node => node.data.node.id)})}
    const handleReset = ()=>{onReset()}

    const rowData = useMemo(()=>{
        if(loading){
            gridRef?.current?.api.showLoadingOverlay();
            return null
        }
        gridRef?.current?.api.hideOverlay();
        return data?.allFlows?.edges
    },[data,loading])


    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h2>Pick Flows</h2>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={true}
                rowSelection='multiple'
                checkboxSelection={true}
                ref={gridRef}
            />
            <Button variant={"outlined"} onClick={handleBack}>Back</Button>
            <Button variant={"contained"} onClick={handleSubmit}>Submit</Button>
            <Button variant={"outlined"} onClick={handleReset}>Reset</Button>
        </div>
    )
}

const Results = ({onBack, onReset, data})=>{
    const [columnDef, setColumnDef] = useState([
        {field:'id', headerName:'ID'},
    ])
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        };
    }, []);
    const handleBack = ()=>{onBack()}
    const handleReset = ()=>{onReset()}
    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h2>Results</h2>
            <AgGridReact
                rowData={data}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={true}
                rowSelection='multiple'
                checkboxSelection={true}
                ref={gridRef}
            />
            <Button onClick={handleBack}>Back</Button>
            <Button onClick={handleReset}>Reset</Button>
    </div>
    )
}