import {gql, useQuery} from "@apollo/client";
import React, {useState, useMemo, useRef} from 'react';
import { AgGridReact } from 'ag-grid-react';
import dayjs, { Dayjs } from 'dayjs';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {twoDecimalThousands} from "../utils/Numbers";
import {DatePicker} from "../components/shared/DatePicker";


const GET_PRICES = gql`query ALL_PX($datetime_Date_Gte: String!, $datetime_Date_Lte: String!) {
  allPrices(
    datetime_Date_Gte: $datetime_Date_Gte
    datetime_Date_Lte: $datetime_Date_Lte
  ) {
    edges {
      node {
        id        
        security {
            identifier{identifier}
            currency
            accountType
            sector
        }
        datetime_Date
        px
        fx
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
`

export  function Prices(){
    const initialParams = {datetime_Date_Gte: dayjs().format('YYYY-MM-DD'), datetime_Date_Lte:dayjs().format('YYYY-MM-DD')}
    const [queryParams, setQueryParams] = useState(initialParams)

    const {loading, error, data, refetch} = useQuery(GET_PRICES, {variables:queryParams, notifyOnNetworkStatusChange:true});
    const handleChange = (e)=>{
        const newParams = {...queryParams, [e.target.name]:e.target.value}
        setQueryParams(newParams)
    }


    const [columnDef, setColumnDef] = useState([
        {field:'node.id', headerName:'ID', hide:true},
        {field:'node.security.identifier.identifier.ticker', headerName: 'Ticker'},
        {field:'node.datetime_Date', headerName: 'Date', filter: 'agDateColumnFilter'},
        {field:'node.px', headerName: 'Price', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'node.fx', headerName: 'FX', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
        {field:'node.security.currency', headerName: 'Currency'},
        {field:'node.security.accountType', headerName: 'Account Type'},
        {field:'node.security.sector', headerName: 'Sector'}
    ])

    const gridRef = useRef(null)

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);
    const rowData = useMemo(()=>{
        if(loading){
            gridRef?.current?.api.showLoadingOverlay();
            return null
        }
        gridRef?.current?.api.hideOverlay();
        return data.allPrices?.edges
    },[data, loading])


    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>Prices</h1>
            <DatePicker value={dayjs(queryParams.datetime_Date_Gte)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Gte', value:newValue}})}} label={'Start Date'}/>
            <DatePicker value={dayjs(queryParams.datetime_Date_Lte)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Lte', value:newValue}})}} label={'End Date'}/>
            <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
                <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='none'
                checkboxSelection={false}
                ref={gridRef}/>
                <span>Total Entries: {data ? data.allPrices.edges.length : null}</span>
            </div>
        </div>
    )
}