import {React, useState} from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';



import InboxIcon from '@mui/icons-material/MoveToInbox';
import MenuIcon from '@mui/icons-material/Menu';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BalanceIcon from '@mui/icons-material/Balance';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CreateIcon from '@mui/icons-material/Create';
import PersonIcon from '@mui/icons-material/Person';



import {ExpandLess, ExpandMore, StarBorder} from "@mui/icons-material";
import {Collapse} from "@mui/material";

const drawerWidth = 240;

export function ResponsiveAppBar({auth}){
    const [open,setOpen] = useState(false)
    const closeDrawer = ()=>{setOpen(false)}
    return(
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        <MenuIcon onClick={()=>{setOpen(!open)}}/>GROMS v5.2
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                //variant="permanent"
                open={open}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
                style={{zoom:'75%'}}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        <SideBarDropDown text='Trading' icon={<BalanceIcon/>}>
                            <SideBarSubItem text={'New Trades'} icon={<AddIcon/>} path='trades/new' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Book Trades'} icon={<MenuBookIcon/>} path='trades/book' closeDrawer={closeDrawer}/>
                        </SideBarDropDown>

                        <SideBarItem text={'Portfolio'} icon={<AccountTreeIcon/>} path='portfolio' closeDrawer={closeDrawer}/>

                        <SideBarItem text={'Prepare Files'} icon={<FileCopyIcon/>} path='generate' closeDrawer={closeDrawer}/>



                        <SideBarDropDown text='Create New' icon={<CreateIcon/>}>
                            <SideBarSubItem text={'Reconcile'} icon={<div/>} path='reconciliations/new' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Compliance'} icon={<div/>} path='compliances/new' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Flow'} icon={<div/>} path='flows/new' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Fill'} icon={<div/>} path='fills/new' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Price'} icon={<div/>} path='prices/new' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'File'} icon={<div/>} path='files/new' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'General Ledger'} icon={<div/>} path='gls/new' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Performance'} icon={<div/>} path='performances/new' closeDrawer={closeDrawer}/>
                        </SideBarDropDown>

                        <SideBarDropDown text='Tables' icon={<ListIcon/>}>
                            <SideBarSubItem text={'Prices'} icon={<div/>} path='prices' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Files'} icon={<div/>} path='files' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'General Ledgers'} icon={<div/>} path='gls' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Orders'} icon={<div/>} path='orders'closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Order Allocations'} icon={<div/>} path='orderallocations' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Routes'} icon={<div/>} path='routes' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Route Allocations'} icon={<div/>} path='routeallocations' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Fills'} icon={<div/>} path='fills' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Compliance'} icon={<div/>} path='compliances' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Security Masters'} icon={<div/>} path='securitymasters' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Security Identifiers'} icon={<div/>} path='securityidentifiers' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Portfolio Lists'} icon={<div/>} path='portfoliolists' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Reconciliations'} icon={<div/>} path='reconciliations' closeDrawer={closeDrawer}/>
                            <SideBarSubItem text={'Performances'} icon={<div/>} path='performances' closeDrawer={closeDrawer}/>
                        </SideBarDropDown>
                        <ListItemButton onClick={auth.signout}>
                            <ListItemIcon><PersonIcon/></ListItemIcon>
                            <ListItemText>Sign Out</ListItemText>
                        </ListItemButton>

                    </List>
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{zoom:'100%'}}>
                <Toolbar />
                <Outlet/>
            </Box>
        </Box>
    )
}

const SideBarItem = ({text,icon,path,closeDrawer})=>{
    const navigate = useNavigate();
    const handleClick = ()=>{closeDrawer(); navigate(path)}
    return(
        <div>
                <ListItemButton onClick={handleClick}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={text}/>
                </ListItemButton>
        </div>
    )
}

const SideBarSubItem = ({text,icon,path, closeDrawer})=>{
    const navigate = useNavigate();
    const handleClick = ()=>{closeDrawer(); navigate(path)}
    return(
        <div>
            <ListItemButton onClick={handleClick} sx={{ pl: 4 }}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={text}/>
            </ListItemButton>
        </div>
    )
}

const SideBarDropDown = ({text,icon, children})=>{
    const navigate = useNavigate();
    const [expand,setExpand] = useState(false)
    const handleClick = ()=>{navigate('test')}
    return(
        <div>
            <ListItemButton onClick={()=>{setExpand(!expand)}}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={text} />
                {expand ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={expand} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children}
                </List>
            </Collapse>
        </div>
    )
}