import {React, useState, useContext, useEffect} from 'react';
import { useLocation, useNavigate, Link} from 'react-router-dom';
import {GromsContext} from "../context/Context";
import { CssVarsProvider } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import {gql, useLazyQuery} from "@apollo/client";


const REQUEST_RESET_MUTATION = gql`
query requestPasswordReset ($email:String!){
  passwordResetRequest(email: $email) {
    email
    message
  }
}`

export function LoginResetRequest(){
    const initialErrors = []
    const [form,setForm] = useState({email:''})
    const [errors, setErrors] = useState(initialErrors)
    const [formStatus,setFormStatus] = useState('initial')
    const [requestReset, { loading, error, data }] = useLazyQuery(REQUEST_RESET_MUTATION);
    const handleChange = (e) => {   setForm({...form, [e.target.name]: e.target.value});  }
    const handleSubmit = (e) => {   e.preventDefault();   setFormStatus('submitting');
        requestReset({
            variables: {email: form.email},
            onCompleted: (data) => {
                setFormStatus('initial');
                console.log(data);
                setErrors(initialErrors);
            },
            onError: (error) => {setErrors([...errors,error.message]); setFormStatus('initial');}
        })

    }

    return(
        <div>
            <CssVarsProvider>
                <Sheet
                    sx={{
                        width: 300,
                        mx: 'auto', // margin left & right
                        my: 4, // margin top & botom
                        py: 3, // padding top & bottom
                        px: 2, // padding left & right
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 'sm',
                        boxShadow: 'md',
                    }}
                    variant="outlined"
                >
                    <div>
                        <Typography level="h4" component="h1">
                            <b>Reset Password</b>
                        </Typography>
                        <Typography level="body2">Enter your email address and instructions will be mailed to you.</Typography>
                    </div>

                    <Input
                        // html input attribute
                        name="email"
                        type="email"
                        placeholder="johndoe@gorozen.com"
                        // pass down to FormLabel as children
                        label="Email"
                        value = {form.email}
                        onChange = {handleChange}
                    />

                    <ResetButton handleSubmit={handleSubmit} status={formStatus}/>


                </Sheet>

            </CssVarsProvider>
            {errors.map((error) => { return <Typography level="body2" color="red">{error}</Typography>})}
            {data ? <Typography level="body2" color="green">Check your email for instructions.</Typography> : null}
        </div>
    )
}


function ResetButton({handleSubmit, status}){
    return(<Button type='submit' sx={{ mt: 1 /* margin top */ }} onClick={handleSubmit} disabled={status=='submitting' ? true : false}>{status=='initial' ? 'Reset' : <CircularProgress/>}</Button>)
}
