import {gql, useQuery} from "@apollo/client";
import React, {useState, useMemo, useRef} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {DatePicker} from "../components/shared/DatePicker";
import dayjs from "dayjs";
import {twoDecimalThousands, noDecimalThousands} from "../utils/Numbers";


const GET_IDENTIFIERS = gql`query GetIdentifiers {
  allSecurityIdentifiers {
    edges {
      node {
        dateAdded_Date
        id
        identifier
        isActive        
        notes
        security {
          id
          accountType
          sector
          currency
          longName
        }
      }
    }
  }
}
`

export  function SecurityIdentifier(){
    const {loading, error, data, refetch} = useQuery(GET_IDENTIFIERS);

    const gridRef = useRef(null)

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);
    const rowData = useMemo(()=>{
        if(loading){gridRef?.current?.api.showLoadingOverlay();return null}
        gridRef?.current?.api.hideOverlay();
        return data.allSecurityIdentifiers?.edges
    },[data, loading])

    const columnDef = useMemo(()=>{
        var columns = [
            {field:'node.id', hide:true},
            {field:'node.dateAdded_Date', headerName: 'Date Added', filter: 'agDateColumnFilter'},
            {field:'node.isActive', headerName: 'Active'},
            {field:'node.notes', headerName: 'Notes'},
            {field:'node.security.accountType', headerName: 'Account Type'},
            {field:'node.security.sector', headerName: 'Sector'},
            {field:'node.security.currency', headerName: 'Currency'},
            {field:'node.security.longName', headerName: 'Long Name'}
        ]
        if(loading){return columns}
        var securityFields = new Set()
        data.allSecurityIdentifiers.edges.forEach((edge)=>{
            Object.keys(edge.node.identifier).forEach((key)=>{
                securityFields.add(key)
            })
        })
        // for each security field add an entry to columns with the field name
        securityFields.forEach((field)=>{
            columns.push({field:`node.identifier.${field}`, headerName: field})
        })

        return columns
    },[data, loading])




    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>Security Identifiers</h1>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='none'
                checkboxSelection={false}
                ref={gridRef}
            ></AgGridReact>
            <span>Total Entries: {rowData?.length || 0}</span>
        </div>
    )
}