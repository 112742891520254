import {React, useState} from "react";
import Button from '@mui/material/Button';
import Overlay from '../shared/Overlay'
import {gql, useMutation} from "@apollo/client";
import dayjs from "dayjs";


const NEW_RECONCILIATION_MUTATION = gql`
    mutation NewReconciliationMutation($input:NewReconciliationMutationInput!) {
    newReconciliation(input: $input) {
        reconciliations{id}
        generalLedgers{id}
    }
}
`

export function Results({onSubmit, onBack, onReset, data}){
    const [addReconciliation, {data:addReconciliationData, loading:addReconciliationLoading, error:addReconciliationError}] = useMutation(NEW_RECONCILIATION_MUTATION);
    const [showOverlay,setShowOverlay] = useState(false)
    const handleSubmit = ()=>{
        const output = {
            portfolios:data.params.portfolios,
            runDate: dayjs().format('YYYY-MM-DD'),
            reconciliationDate:data.params.reconciliationDate,
            payload:{
                custodianData:data.custodianData,
                reconcileSchema: data.params.reconcileSchema,
                fileObj: data.params.fileObj,
                excludedData:data.reconcileReport.excludedData,
                newGls:data.reconcileReport.newGls,
                reconcileTable:data.reconcileReport.reconcileTable
            }
        }
        setShowOverlay(true)
        addReconciliation({
            variables:{"input":{"data":[output]}},
            onCompleted:(data)=>{
                setShowOverlay(false)
                onSubmit(data)

            },
            onError:(error)=>{
                setShowOverlay(false)
                console.log(error)
            }
            });
    }

    return(
        <div>
            <Overlay open={showOverlay}/>
            <h2>Results</h2>
            <Button variant={'outlined'} onClick={onBack}>Back</Button>
            <Button variant={'contained'} onClick={handleSubmit}>Save to Database</Button>
            <Button variant={'outlined'} onClick={onReset}>Reset</Button>
            <button onClick={()=>{console.log(data)}}>Data</button>
        </div>
    )
}