import {React, useState, useMemo, useCallback, useEffect} from 'react';
import {gql, useQuery} from "@apollo/client";
import Select, { components, SingleValueProps, MultiValueProps } from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Fuse from 'fuse.js';




const GET_SM = gql`query GetSecurity($active:Boolean){
  allSecurityMasters(active: $active){
    edges {
      node {
        id
        longName
        identifier {
          identifier
        }
      }
    }
  }
}
`


export function SecuritySelectWrapper({onChange}){
    const {loading,error,data, refetch} = useQuery(GET_SM, {variables:{active:true}})
    const rowData = useMemo(()=>{
        if(loading || error){return []}
        return data?.allSecurityMasters?.edges?.map((edge)=>{return edge?.node})
    },[data,loading])
    const handleChange=(e)=>{onChange(e)}
    return(<SecuritySelect rowData={rowData} onChange={handleChange}/>)
}

export function SecuritySelect({rowData, onChange}){
    const [searchPattern, setSearchPattern] = useState(null)
    const identifiers = useMemo(()=>{
        if(rowData?.length===0 || rowData == null){return []}
        let options = []
        rowData?.forEach((node)=>{
            const identifier = node?.identifier?.identifier
            // get a list of all the keys in identifier
            const keys = Object.keys(identifier)
            const keyPaths = keys.map((key)=>{return 'identifier.identifier.'+key})
            keyPaths.forEach((key)=>{options.push(key)})
        })
        // get all unique values of options
        let output = [...new Set(options)]
        output.push('longName')
        return output
    },[rowData])
    const fuse = useMemo(()=>{return new Fuse(rowData, {keys:identifiers, threshold:0.3, includeMatches:true})},[rowData,identifiers])
    const results = useMemo(()=>{
        if(searchPattern?.length>=3){
            const searchResults = fuse.search(searchPattern)
            if(searchResults.length>0){
                let output = []
                searchResults.forEach((item)=>{output.push({...item.item,matches:item.matches})})
                return output

            }
        }
        return rowData
    },[rowData, searchPattern, fuse])
    const formatOptionLabel = (option)=>{
        const ticker = <span>{option.identifier.identifier.ticker}</span>
        if(option.hasOwnProperty('matches')){
            if(option.matches.length>0){
                const matchValue = option?.matches[0].value
                const matchIndex = option?.matches[0].indices[0]
                const matchStartIndex = matchIndex[0]
                const matchEndIndex = matchIndex[1]
                const matchValueLength = matchValue.length
                const preMatch = matchValue.substring(0,matchStartIndex)
                const matchString = matchValue.substring(matchStartIndex,matchEndIndex)
                const postMatch = matchValue.substring(matchEndIndex,matchValueLength)
                return <div>{ticker} | {preMatch}<strong>{matchString}</strong>{postMatch}</div>
            }
        }
        return <div>{ticker}</div>
    }
    const handleChange = (e)=>{onChange(e)}
    const handleInputChange=(e)=>{setSearchPattern(e)}
    const customFilter = useCallback((candidate,input)=>{return true},[])
    const MultiValueContainer = (props)=>{
        return(
            <div>
                <components.MultiValueContainer {...props}>{props.data.identifier.identifier.ticker}{props.children[1]}</components.MultiValueContainer>
            </div>
        )
    }
    return (
        <div>
            <Select
                options={results}
                getOptionValue={(option)=>{return option.id}}
                onChange={(e)=>{handleChange(e)}}
                isMulti={false}
                isSearchable={true}
                onInputChange={handleInputChange}
                filterOption = {customFilter}
                formatOptionLabel={formatOptionLabel}
                components={{MultiValueContainer}}
            />
        </div>
    );
}