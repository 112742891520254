import {React, useState, useMemo, useRef} from "react";
import {gql, useQuery, useMutation} from "@apollo/client";
import Button from '@mui/material/Button';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import {PortfolioSelectWrapper} from "../shared/PortfolioSelect";
import {SecuritySelect} from "../shared/SecurityMasterSelect";
import {noDecimalThousands} from "../../utils/Numbers";
import Overlay from "../shared/Overlay";

const GET_SM = gql`query GetSecurity($active:Boolean){
  allSecurityMasters(active: $active){
    edges {
      node {
        id
        longName
        identifier {
          identifier
        }
      }
    }
  }
}
`

const GET_RECON = gql`
query GetRecon(
    $portfolioId_In: [ID], 
    $reconcileSchemaId: ID, 
    $reconciliationDate: String, 
    $custodianData: [GenericScalar]
    ) {
  allReconcile(
    portfolioId_In: $portfolioId_In
    reconcileSchemaId: $reconcileSchemaId
    reconciliationDate: $reconciliationDate
    custodianData: $custodianData
  ) {
    edges {
           node {
        id
        lineNumber        
        matched
        portfolioList {
          id
          alias
        }
        posExt
        posInt
        reconcileEntry {
          id
          security{identifier{identifier}}
          entry
        }
        securityMaster {
          id
          identifier{identifier}
        }
        status
      }
    }
  }
}`

const NEW_RECONCILE_ENTRY_MUTATION = gql`
    mutation NewReconcileEntryMutation($input: NewReconcileEntryMutationInput!) {
        newReconcileEntry(input: $input) {clientMutationId}
}
`


export function Match({custodianData, onSubmit, onBack, onReset, params}){
    const [pageState, setPageState] = useState('unmatched')
    const [pendingMatch, setPendingMatch] = useState([])
    const [rowData, setRowData] = useState(null)
    const [custodianSelected, setCustodianSelected] = useState(null)
    const [internalSelected, setInternalSelected] = useState(null)
    const {loading, error, data, refetch} = useQuery(GET_RECON, {
        variables:{
            reconcileSchemaId: params.reconcileSchema,
            portfolioId_In: params.portfolios,
            reconciliationDate:params.reconciliationDate,
            custodianData: custodianData
        },
        onCompleted: (data)=>{setRowData(data)},
        fetchPolicy: 'no-cache'
    })
    const [addReconcileEntries, {data:addEntriesData, loading:addEntriesLoading, error:addEntriesError}] = useMutation(NEW_RECONCILE_ENTRY_MUTATION);
    const [showOverlay, setShowOverlay] = useState(false)

    const handleCustodianRowChanged = (row)=>{setCustodianSelected(row);}
    const handleInternalRowChanged = (row)=>{setInternalSelected(row);}
    const onMatch = (custodianSelected, internalSelected)=>{
        var newData = rowData.allReconcile?.edges
        newData.forEach((entry)=>{
            if(entry.node.id===custodianSelected.id || entry.node.id===internalSelected.id){
                entry.node.status='PendingMatch'
            }
        })
        setRowData({allReconcile:{edges:newData}})
        var newPendingMatch = pendingMatch
        newPendingMatch.push({custodian:custodianSelected.id, internal:internalSelected.id})
        setPendingMatch(newPendingMatch)

    }
    const onUnmatch = (entry)=>{
        if(entry.status==='Matched'){
            // get the id from the entry
            const entryId = entry.id
            var maxId = 0
            rowData.allReconcile?.edges.forEach((entry)=>{if(entry.node.id>maxId){maxId=entry.node.id}})
            // remove the match entry from the rowData
            var newRowData = rowData?.allReconcile?.edges.filter((entry)=>{return entry.node.id!==entryId})
            // split the entry into two new ones: CustOnly and IntOnly setting their id to new max rows
            // Custody: newId, lineNumber, matched, posExt,  status
            // Internal: newId, matched, portfolioList,  posInt, securityMaster, status
            newRowData.push({node:{id:maxId+1, lineNumber:entry.lineNumber, matched:false, posExt:entry.posExt, status:'CustOnly'}})
            newRowData.push({node:{id:maxId+2, matched:false, portfolioList:entry.portfolioList, posInt:entry.posInt, securityMaster:entry.securityMaster, status:'IntOnly'}})
            // setRowData with the new data
            setRowData({allReconcile:{edges:newRowData}})
        }
        if(entry.status==='PendingMatch'){
            // get both entries from the pending match list
            var pendingMatchEntry = null
            var newRowData = rowData?.allReconcile?.edges
            pendingMatch.forEach((match)=>{if(match?.custodian===entry.id|| match?.internal===entry.id){pendingMatchEntry = match}})
            if(pendingMatchEntry!=null){
                newRowData.forEach((entry)=>{
                    if(entry.node.id===pendingMatchEntry?.custodian){entry.node.status='CustOnly'}
                    if(entry.node.id===pendingMatchEntry?.internal){entry.node.status='IntOnly'}
                })
            }

            const newPendingMatch = pendingMatch.filter((match)=>{return match!==pendingMatchEntry})

            setRowData({allReconcile:{edges:newRowData}})
            setPendingMatch(newPendingMatch)
        }

    }
    const addInternalOnly = ({portfolio,security})=>{
        let maxId = 0
        let maxLineNumber = 0
        rowData?.allReconcile?.edges?.forEach((entry)=>{
            if(entry.node.id>maxId){maxId=entry.node.id}
            if(entry.node.lineNumber>maxLineNumber){maxLineNumber=entry.node.lineNumber}
        })
        const node = {
            "id": maxId+1,
            "lineNumber": maxLineNumber+1,
            "matched": false,
            "portfolioList": portfolio,
            "posExt": null,
            "posInt": 0,
            "reconcileEntry": null,
            "securityMaster": security,
            "status": "IntOnly",
            "isRemovable":true,
            "__typename": "ReconcileNode"
        }
        let newRowData = rowData.allReconcile?.edges
        newRowData.push({node:node})
        setRowData({allReconcile:{edges:newRowData}})
    }
    const removeInternalOnly = (entry)=>{
        let newRowData = rowData.allReconcile?.edges?.filter((item)=>{return item.node.id!==entry})
        setRowData({allReconcile:{edges:newRowData}})
    }
    const handleSubmit = ()=>{
        setShowOverlay(true)
        const newMatches = pendingMatch.map((match)=>{
            const portfolioId = rowData.allReconcile?.edges.map(edge=>edge?.node).filter((entry)=>{return entry.id===match.internal})[0]?.portfolioList?.id
            const securityId = rowData.allReconcile?.edges.map(edge=>edge?.node).filter((entry)=>{return entry.id===match.internal})[0]?.securityMaster?.id
            const lineNumber = rowData.allReconcile?.edges.map(edge=>edge?.node).filter((entry)=>{return entry.id===match.custodian})[0]?.lineNumber
            const entry = custodianData.filter((entry)=>{return entry.line_number===lineNumber})[0]
            return(
                {
                    portfolioId: portfolioId,
                    securityId: securityId,
                    entry: entry,
                    schemaId: params.reconcileSchema,
                    dateAdded: params.reconciliationDate
                }
            )
        });
        if(newMatches.length>0){
            addReconcileEntries({
                variables:{"input":{"data":newMatches}},
                onCompleted: (addEntriesData)=>{
                    setShowOverlay(false)
                    onSubmit();
                },
                onError: (error)=>{
                    console.log(error);
                    setShowOverlay(false)
                }
            })
        }
        else{
            setShowOverlay(false)
            onSubmit();
        }
    }

    return(
    <div>
        <Overlay open={showOverlay}/>
        <h1>Match Entries</h1>
        {pageState === 'unmatched' ?
            <Button variant={"outlined"} onClick={()=>{setPageState('matched')}}>Show Matched</Button> : <Button variant={"outlined"} onClick={()=>{setPageState('unmatched')}}>Show Unmatched</Button>}
        <div style={{display: pageState==='unmatched'?'block':'none'}}>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <CustodianOnly
                        data={rowData}
                        custodianData={custodianData}
                        handleSelectionChanged={handleCustodianRowChanged}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CenterSection
                        data={rowData}
                        custodianData={custodianData}
                        custodianSelected={custodianSelected}
                        internalSelected={internalSelected}
                        handleSubmit={onMatch}
                    />
                </Grid>
                <Grid item xs={5}>
                    <InternalOnly
                        data={rowData}
                        handleSelectionChanged={handleInternalRowChanged}
                        removeRow={removeInternalOnly}
                    />
                    <CreateNew reconData={rowData} onSubmit={addInternalOnly}/>
                </Grid>
            </Grid>
        </div>

        <div style={{display: pageState==='matched'?'block':'none'}}>
            <Matched data={rowData} custodianData={custodianData} handleUnmatch={onUnmatch}/>
        </div>

        <div style={{paddingTop:'100px'}}><Button variant={"contained"} onClick={handleSubmit}>{pendingMatch.length>0 ? 'Save and ': null}Run Reconciliation</Button></div>






    </div>
)}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 400,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const EntryToolTip = ({params, custodianData})=>{
    const data = null
    const entryData = useMemo(()=>{return custodianData?.filter((data)=>{return data.line_number===params})[0]},[data])
    return(
        <div>
            <HtmlTooltip
                title={

                    <div>
                        <Typography color="inherit">Entry Details</Typography>
                        <pre>{JSON.stringify(entryData, null, 2)}</pre>
                    </div>

                }
            >
                <Button>Details</Button>
            </HtmlTooltip>
        </div>
    )
}

function CustodianOnly({data, custodianData, handleSelectionChanged}){
    const gridRef = useRef(null)
    const rowData = useMemo(()=>{
        return data?.allReconcile?.edges.map((edge)=>{return edge.node}).filter((node)=>{return node.status==='CustOnly'})
    },[data])
    const onSelectionChanged = (event)=>{handleSelectionChanged(gridRef?.current?.api.getSelectedRows()[0])}
    const columnDef = useMemo(()=>{
        return(
            [
                {headerName: "Line Number", field: "lineNumber"},
                {headerName: "External Position", field: "posExt", valueFormatter: noDecimalThousands},
                {headerName: "Has Reconcile Entry", field: "reconcileEntry", cellRenderer: (params)=>{return params.value?'Yes':'No'}},
                {headerName: "Ticker", field: "reconcileEntry", cellRenderer: (params)=>{return params.value?.security?.identifier?.identifier?.ticker}},
                {headerName: "Entry", field:"lineNumber", cellRenderer: (params)=> {return(<EntryToolTip params={params.value} custodianData={custodianData}/>)}},
                {headerName: "Status", field: "status"}

            ]
        )
    },[data])
    const gridOptions = {
        defaultColDef: {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        }
    }
    return(
        <div className="ag-theme-alpine" style={{height: 900, width: '100%'}}>
            <h2>Custody Only</h2>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                gridOptions = {gridOptions}
                animateRows={true}
                rowSelection={'single'}
                onSelectionChanged={onSelectionChanged}
                checkboxSelection={false}
                ref={gridRef}
                getRowId={(params)=>{return params.data.id}}

            ></AgGridReact>


        </div>
    )}

function InternalOnly({data, handleSelectionChanged, removeRow}){
    const RemoveButton = ({params, onSubmit})=>{
        if(params?.data?.isRemovable!=true){return null}
        return(<Button onClick={()=>{onSubmit(params.data.id)}}>Remove</Button>)
    }
    const gridRef = useRef(null)
    const rowData = useMemo(()=>{
        return data?.allReconcile?.edges.map((edge)=>{return edge.node}).filter((node)=>{return node.status==='IntOnly'})
    },[data])
    const onSelectionChanged = (event)=>{handleSelectionChanged(gridRef?.current?.api.getSelectedRows()[0])}
    const columnDef = useMemo(()=>{
        return(
            [
                {headerName: "Portfolio", field:'portfolioList.alias'},
                {headerName: "Internal Position", field: "posInt", valueFormatter: noDecimalThousands},
                {headerName: "Has Reconcile Entry", field: "reconcileEntry", cellRenderer: (params)=>{return params.value?'Yes':'No'}},
                {headerName: "Ticker", field: "securityMaster.identifier.identifier.ticker"},
                {headerName: "Status", field: "status"},
                {headerName:'Remove', field:'isRemovable', cellRenderer: (params)=>{return <RemoveButton params={params} onSubmit={removeRow}/>}}

            ]
        )
    },[data])
    const gridOptions = {
        defaultColDef: {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        }
    }
    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h2>Internal Only</h2>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                gridOptions = {gridOptions}
                animateRows={true}
                rowSelection={'single'}
                onSelectionChanged={onSelectionChanged}
                checkboxSelection={false}
                ref={gridRef}
                getRowId={(params)=>{return params.data.id}}
            ></AgGridReact>
        </div>
    )}

function CenterSection({custodianData, data, custodianSelected, internalSelected, handleSubmit}){

    const readyToMatch = useMemo(()=>{
        if(custodianSelected==null || internalSelected==null){return false}
        return true;
    },[custodianSelected, internalSelected])

    const onSubmit = ()=>{handleSubmit(custodianSelected=custodianSelected, internalSelected=internalSelected)};



    return(
    <div>
        <h3>Custody Line Number:</h3><span>{custodianSelected?.lineNumber}</span>
        <h3>Internal Entry: </h3>
        <span>{internalSelected?.portfolioList?.alias} | {internalSelected?.securityMaster?.identifier?.identifier?.ticker}</span>
        <div style={{marginTop:'100px'}}><Button disabled={!readyToMatch} variant={"contained"} onClick={onSubmit}>Match</Button></div>

    </div>
)}

const UnmatchButton = ({data, handleUnmatch})=>{return(<Button onClick={()=>{handleUnmatch(data)}}>Unmatch</Button>)}

function Matched({data, custodianData, handleUnmatch}){
    const gridRef = useRef(null)
    const rowData = useMemo(()=>{
        return data?.allReconcile?.edges.map((edge)=>{return edge.node}).filter((node)=>{return (node.status==='Matched' || node.status==='PendingMatch')})
    },[data])
    const columnDef = useMemo(()=>{
        return(
            [
                {headerName: "Entry", field:"lineNumber", cellRenderer: (params)=> {return(<EntryToolTip params={params.value} custodianData={custodianData}/>)}},
                {headerName: "Portfolio", field:'portfolioList.alias'},
                {headerName: "Has Reconcile Entry", field: "reconcileEntry", cellRenderer: (params)=>{return params.value?'Yes':'No'}},
                {headerName: "Ticker", field: "securityMaster.identifier.identifier.ticker"},
                {headerName: "Status", field: "status"},
                {headerName: "Unmatch", cellRenderer: (params)=>{return(<UnmatchButton data={params.data} handleUnmatch={handleUnmatch}/>)}},
            ]
        )
    },[data])
    const gridOptions = {
        defaultColDef: {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        }
    }
    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h2>Matched</h2>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                gridOptions = {gridOptions}
                animateRows={true}
                rowSelection={'none'}
                checkboxSelection={false}
                ref={gridRef}
                getRowId={(params)=>{return params.data.id}}
            ></AgGridReact>
        </div>
    )}

function CreateNew({reconData, onSubmit}){
    const [portfolio, setPortfolio] = useState(null)
    const [security, setSecurity] = useState(null)
    const {loading,error,data, refetch} = useQuery(GET_SM, {variables:{active:true}})

    const securityOptions = useMemo(()=>{
        if(loading || error){return []}
        if(portfolio===null){return []}
        const sm = data?.allSecurityMasters?.edges?.map((edge)=>{return edge.node})
        let rd = reconData?.allReconcile?.edges?.map((edge)=>{return edge.node}).filter((entry)=>{return entry?.portfolioList?.id===portfolio.id}).map((entry)=>{return entry?.securityMaster?.id})
        const results = sm.filter((entry)=>{return !rd.includes(entry.id)})
        return results
    },[data,loading,portfolio, reconData])

    const handlePortfolioChange = (e)=>{setPortfolio(e)}
    const handleSecurityChange = (e)=>{setSecurity(e)}
    const handleSubmit = ()=>{if(portfolio!=null && security!=null)onSubmit({portfolio, security})}

    return(
        <div className="ag-theme-alpine" style={{paddingTop:'50px', height: 150, width: '100%'}}>
            <h2>Create New Entry</h2>
            <PortfolioSelectWrapper onChange={handlePortfolioChange}/>
            <SecuritySelect rowData={securityOptions} onChange={handleSecurityChange}/>
            <Button variant={"outlined"} onClick={handleSubmit} disabled={portfolio===null || security===null}>Create</Button>
        </div>
    )
}