import React, {useState, useMemo, useCallback, useRef, useEffect} from 'react';
import Button from '@mui/material/Button';
import dayjs from "dayjs";
import {DatePicker} from "../components/shared/DatePicker";
import {PortfolioSelectWrapper} from "../components/shared/PortfolioSelect";
import {gql, useMutation, useQuery} from "@apollo/client";
import {AgGridReact} from "ag-grid-react";
import {twoDecimalThousands, noDecimalThousands, percentTwoDecimal, average} from "../utils/Numbers";
import Overlay from "../components/shared/Overlay";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';







const RUN_PERFORMANCE = gql`
  query RunPerformance($portfolioId_In: [ID!], $datetime_Date_Gt: String, $datetime_Date_Lte: String){
  runPerformance(
    portfolioId_In: $portfolioId_In
    datetime_Date_Gt: $datetime_Date_Gt
    datetime_Date_Lte: $datetime_Date_Lte
  ) {
    edges {
      node {
        datetime_Date_Lte
        datetime_Date_Gt
        errors
        missingPrices {
          datetime
          fx
          px
          security {
            id
            priceMultiplier
            identifier{identifier}}
        }
        pnl {
            security{
                id
                identifier{identifier}
                accountType
                }
            portfolio{
                id
                alias
            }            
            posStart
            dateStart
            dateEnd
            posEnd
            posTrade
            posFlow
            posOther
            posError
            pxEnd
            fxEnd
            pxStart
            fxStart
            priceMultiplier
            mvTradeLcl
            mvTrade
            pxTradeLcl
            mvManualOverrideStart
            mvOtherPnl
            mvIncome
            mvManualOverrideEnd            
        }
        portfolios {
          id
          alias
        }
      }
    }
  }
}
`
const NEW_PRICE_MUTATION = gql`
    mutation NewPriceMutation($input:NewPriceMutationInput!) {
    newPrices(input: $input) {prices {id}}
}

`
const NEW_PERFORMANCE_MUTATION = gql`
    mutation NewPerformanceMutation($input:NewPerformanceMutationInput!) {
    newPerformances(input: $input) {performances{id}}
}
`


export function NewPerformance(){
    const [pageState, setPageState] = useState('initial')
    const [pageIter, setPageIter] = useState(0)
    const [params, setParams] = useState({datetime_Date_Gte:dayjs().format('YYYY-MM-DD'), datetime_Date_Lte:dayjs().format('YYYY-MM-DD'), portfolioId_In:[]})
    const [showOverlay, setShowOverlay] = useState(false)
    const [addPerformances, {data:addPerformancesData, loading:addPerformancesLoading, error:addPerformancesError}] = useMutation(NEW_PERFORMANCE_MUTATION);

    const handleReset = ()=>{
        setPageState('initial')
        setPageIter(pageIter+1)
    }

    const handleInitialSubmit = (queryParams)=>{
        setParams(queryParams);
        setPageState('generate');
    }

    const handleGenerateSubmit = (data)=>{
        const payload = data?.map((row)=>{return(
            {
                dateEnd:row.dateEnd,
                dateStart:row.dateStart,
                fxEnd:row.fxEnd,
                fxStart:row.fxStart,
                mvEnd:row.mvEnd,
                mvEndAdj:row.mvEndAdj,
                mvError:row.mvError,
                mvFlow:row.mvFlow,
                mvIncome:row.mvIncome,
                mvManualOverrideEnd:row.mvManualOverrideEnd,
                mvManualOverrideStart:row.mvManualOverrideStart,
                mvMidday:row.mvMidday,
                mvOther:row.mvOther,
                mvOtherPnl:row.mvOtherPnl,
                mvStart:row.mvStart,
                mvStartAdj:row.mvStartAdj,
                mvTrade:row.mvTrade,
                mvTradeLcl:row.mvTradeLcl,
                mvUgl:row.mvUgl,
                portfolioId:row.portfolio.id,
                posEnd:row.posEnd,
                posError:row.posError,
                posFlow:row.posFlow,
                posOther:row.posOther,
                posStart:row.posStart,
                posTrade:row.posTrade,
                priceMultiplier:row.priceMultiplier,
                pxEnd:row.pxEnd,
                pxStart:row.pxStart,
                pxTradeLcl:row.pxTradeLcl,
                securityId:row.security.id,
            }
        )})
        console.log(payload)
        addPerformances({
            variables:{"input":{"data":payload}},
            onCompleted: (addPerformancesData)=>{
                setShowOverlay(false)
                setPageState('results')
            },
            onError: (error)=>{
                console.log(error);
                setShowOverlay(false)
            }
        })
    }

    return (
        <div key={pageIter}>
            <Overlay open={showOverlay}/>
            <h1>New Performance</h1>
            <div style={{display: pageState==='initial'?'block':'none'}}><Initial setPageState={setPageState} onSubmit={handleInitialSubmit}/></div>
            <div style={{display: pageState==='generate'?'block':'none'}}><GeneratePerformance params={params} onReset={handleReset} onSubmit={handleGenerateSubmit}/></div>
            <div style={{display: pageState==='results'?'block':'none'}}><Results data={[]} onReset={handleReset}/></div>


        </div>
  );
}


const Initial = ({setPageState, onSubmit})=>{
    const initialParams = {datetime_Date_Gt:dayjs().format('YYYY-MM-DD'), datetime_Date_Lte:dayjs().format('YYYY-MM-DD'), portfolioId_In:[]}
    const [queryParams, setQueryParams] = useState(initialParams)
    const handleChange = (e)=>{
        const newParams = {...queryParams, [e.target.name]:e.target.value}
        setQueryParams(newParams)
    }

    const handleSubmit = ()=>{onSubmit(queryParams)}




    return(
        <div>
            <h2>Initial</h2>
            <DatePicker value={dayjs(queryParams.datetime_Date_Gt)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Gt', value:newValue}})}} label={'Start Date'}/>
            <DatePicker value={dayjs(queryParams.datetime_Date_Lte)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Lte', value:newValue}})}} label={'End Date'}/>
            <PortfolioSelectWrapper onChange={(e)=>{setQueryParams({...queryParams, portfolioId_In:e.map((e)=>{return e.id})})}} isMulti={true}/>
            <Button variant={'contained'} onClick={handleSubmit}>Generate Performance</Button>
        </div>
    )

}

const GeneratePerformance = ({params, onReset, onSubmit})=> {
    const {data, loading, error,refetch} = useQuery(RUN_PERFORMANCE, {variables:params}, {fetchPolicy: 'network-only', onError: (error)=>{console.log(error)}})

    const errors = useMemo(()=>{
        if(loading || error){return []}
        return data?.runPerformance?.edges[0]?.node?.errors
    },[data, loading, error])

    const missingPrices = useMemo(()=>{
        if(loading || error){return []}
        return data?.runPerformance?.edges[0]?.node?.missingPrices
    },[data,loading,error])

    const pnl = useMemo(()=>{
        if(loading || error){return []}
        return data?.runPerformance?.edges[0]?.node?.pnl
    },[data,loading,error])

    const portfolios = useMemo(()=>{
        if(loading||error){return[]}
        return data?.runPerformance?.edges[0]?.node?.portfolios
    },[data,loading,error])

    const showOverlay = useMemo(()=>{
        if(loading){return true}
        return false
    },[loading,error])

    const handleSubmit = (data)=>{onSubmit(data)}
    const handleReset = (data)=>{onReset()}


    return(
        <div>
            <Overlay open={showOverlay}/>
            <h2>Generate Performance</h2>
            {missingPrices?.length > 0 ? <MissingPrices missingPrices={missingPrices} refetch={refetch} onReset={handleReset}/>: <div/>}
            {(pnl?.length > 0 && missingPrices === null) ? <Pnl pnl={pnl} onSubmit={handleSubmit} onReset={handleReset}/>: <div/>}

        </div>
    )
}

const MissingPrices = ({missingPrices, refetch, onReset})=>{
    const [addPrices, {data:addPricesData, loading:addPricesLoading, error:addPricesErrors}] = useMutation(NEW_PRICE_MUTATION);
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: true,
        };
    }, []);
    const rowData = useMemo(()=>{
        if(missingPrices === null){return []}
        return missingPrices.map((x)=>{return(
            {
                datetime: x.datetime,
                security: x.security,
                px: x.px,
                fx: x.fx,
            }
        )})
    },[missingPrices])
    const columnDef = useMemo(()=>{
        return [
            {field:'datetime', headerName:'Date', filter: 'agDateColumnFilter'},
            {field:'security.identifier.identifier.bloomberg_symbol', headerName:'Ticker'},
            {field:'security.identifier.identifier.bloomberg_fx', headerName:'FX Ticker'},
            {field:'security.priceMultiplier', headerName:'Multiplier'},
            {field:'px', headerName:'Price', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
            {field:'fx', headerName:'Fx', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
        ]
    },[])
    const [showOverlay, setShowOverlay] = useState(false)

    const handleReset = ()=>{onReset()}
    const handleSubmit = ()=>{
        setShowOverlay(true)
        let newPrices = []
        gridRef?.current?.api.forEachNode((node)=>{
            newPrices.push({
                datetime: node.data.datetime,
                securityId: node.data.security.id,
                px: node.data.px,
                fx: node.data.fx,
                notes: 'Missing Prices from Performance Run, manually added by user'
            })
        })
        addPrices({
            variables:{"input":{"data":newPrices}},
            onCompleted: (addEntriesData)=>{
                setShowOverlay(false)
                refetch();
            },
            onError: (error)=>{
                console.log(error);
                setShowOverlay(false)
            }
        })
    }
    return(
        <div>
            <Overlay open={showOverlay}/>
            <h2>Missing Prices</h2>
            <h5>Please add these missing prices to the database before continuing.</h5>
            <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDef}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    enableRangeSelection={false}
                    rowSelection='none'
                    checkboxSelection={false}
                    ref={gridRef}
                />
            </div>
            <Button variant={'contained'} onClick={handleSubmit}>Submit</Button>
            <Button variant={'outlined'} onClick={handleReset}>Reset</Button>
        </div>
    )
}


const Pnl = ({pnl, onSubmit, onReset})=>{
    const [data,setData] = useState()
    const [selected,setSelected] = useState([])
    const handleSubmit = ()=>{onSubmit(data)}
    const handleReset = ()=>{onReset(data)}
    const handleDataChange = (newData)=>{setData(newData)}
    const handleToggle = (portfolioId, toggled)=>{
        if(toggled && !selected.includes(portfolioId)){setSelected([...selected, portfolioId])}
        if(!toggled && selected.includes(portfolioId)){setSelected(selected.filter((x)=>{return x!==portfolioId}))}
    }

    return(
        <div>
            <PnlSummary data={data} onToggle={handleToggle}/>
            <PnlTable pnl={pnl} onChange={handleDataChange} selected={selected}/>
            <Button onClick={handleSubmit} variant={'contained'}>Submit</Button>
            <Button onClick={handleReset} variant={'outlined'}>Reset</Button>
        </div>
    )
}
const PnlTable = ({pnl, onChange, selected})=>{
    const rowDataColumns = [
        'portfolio',
        'security',
        'dateStart',
        'dateEnd',
        'posStart',
        'posTrade',
        'posFlow',
        'posOther',
        'posError',
        'posEnd',
        'priceMultiplier',
        'mvTradeLcl',
        'mvTrade',
        'pxTradeLcl',
        'pxStart',
        'pxEnd',
        'fxStart',
        'fxEnd',
        'mvManualOverrideStart',
        'mvManualOverrideEnd',
        'mvOtherPnl',
        'mvIncome',
    ]
    const makeData = (pnl)=>{
        let output = []
        let i = 0
        pnl.forEach((x)=>{
            let row = {id:i}
            rowDataColumns.forEach((col)=>{row[col] = x[col]})
            output.push(row)
            i++
        })
        return output
    }
    const [data,setData] = useState(makeData(pnl))
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,

        };
    }, []);
    const getRowId = useCallback((params) => params.data.id, [])
    const onCellEditRequest = useCallback((event) => {
        const _data = event.data
        const field = event.colDef.field
        const newValue = event.newValue
        const oldValue = event.oldValue
        const oldItem = data.find((x)=>{return x.id === _data.id})
        console.log(_data, field, newValue, oldValue)
        if(!oldItem || !field){return;}
        const newItem = {...oldItem, [field]:newValue}
        const newData = data.map((x)=>{return x.id === _data.id ? newItem : x})
        setData(newData)
        },
        [data]
    );

    const callbacks = {
        'getMvStart': (data)=>{return data.posStart * data.priceMultiplier * data.pxStart / data.fxStart},
        'getMvOther': (data)=>{return data.posOther * data.priceMultiplier * data.pxStart / data.fxStart},
        'getMvFlow': (data)=>{return data.posFlow * data.priceMultiplier * data.pxStart / data.fxStart},
        'getMvError': (data)=>{return data.posError * data.priceMultiplier * data.pxStart / data.fxStart},
        'getMvStartAdj': (data)=>{return callbacks.getMvStart(data) + callbacks.getMvOther(data) + callbacks.getMvFlow(data) + callbacks.getMvError(data) + data.mvManualOverrideStart},
        'getMvMidday': (data)=>{return ((data.posStart * data.priceMultiplier * data.pxTradeLcl / data.fxStart) + callbacks.getMvFlow(data)+callbacks.getMvOther(data)+callbacks.getMvError(data) +data.mvManualOverrideStart)},
        'getMvEnd': (data)=>{return data.posEnd * data.priceMultiplier * data.pxEnd / data.fxEnd},
        'getMvEndAdj':(data)=>{return callbacks.getMvEnd(data)+data.mvManualOverrideEnd},
        'getMvUgl': (data)=>{return callbacks.getMvEndAdj(data) - (callbacks.getMvMidday(data)+data.mvTrade)+data.mvIncome+data.mvOtherPnl},
        'getWeight1': (data, totals)=>{return data.mvStartAdj / totals.mvStartAdjTotal[data.portfolio.id]},
        'getRoic1': (data, totals)=>{
            if(data.mvStartAdj===0){return 0}
            return data.mvMidday / data.mvStartAdj - 1
        },
        'getCtr1': (data,totals)=>{return data.roic1 * data.weight1},
        'getWeight2': (data,totals)=>{return data.mvMidday / totals.mvMiddayTotal[data.portfolio.id]},
        'getRoic2': (data, totals)=> {
            if(data.mvMidday===0){return 0}
            return (data.mvIncome + data.mvOtherPnl + data.mvUgl) / data.mvMidday
        },
        'getCtr2': (data,totals)=>{return data.roic2 * data.weight2},
        'getWeight': (data,totals)=>{return (data.weight1 + data.weight2)/2},
        'getCtr': (data,totals)=> {return ((data.ctr1 + 1) * (data.ctr2 + 1)) - 1},
        'getRoic': (data,totals)=>{return data.ctr / data.weight},
    }

    const calculatedColumns = [
        {name:'mvStart',callBack:callbacks.getMvStart, defer:false},
        {name:'mvEnd',callBack:callbacks.getMvEnd, defer:false},
        {name:'mvOther',callBack:callbacks.getMvOther, defer:false},
        {name:'mvFlow',callBack:callbacks.getMvFlow, defer:false},
        {name:'mvError',callBack:callbacks.getMvError, defer:false},
        {name:'mvStartAdj',callBack:callbacks.getMvStartAdj, defer:false},
        {name:'mvMidday',callBack:callbacks.getMvMidday, defer:false},
        {name:'mvUgl',callBack:callbacks.getMvUgl, defer:false},
        {name:'mvEndAdj', callBack:callbacks.getMvEndAdj, defer:false},

        {name:'weight1',callBack:callbacks.getWeight1, defer:true},
        {name:'roic1',callBack:callbacks.getRoic1, defer:true},
        {name:'ctr1',callBack:callbacks.getCtr1, defer:true},
        {name:'weight2',callBack:callbacks.getWeight2, defer:true},
        {name:'roic2',callBack:callbacks.getRoic2, defer:true},
        {name:'ctr2',callBack:callbacks.getCtr2, defer:true},
        {name:'weight',callBack:callbacks.getWeight, defer:true},
        {name:'ctr',callBack:callbacks.getCtr, defer:true},
        {name:'roic',callBack:callbacks.getRoic, defer:true},
    ]

    const rowData = useMemo(()=> {
        let output = []

        // calculate all the easily calculated columns
        data?.forEach((row) => {
            let newRow = {...row}
            calculatedColumns.filter(column=>column.defer===false).forEach((col) => {
                newRow[col.name] = col.callBack(row)
            })
            output.push(newRow)
        })

        // go through data and get all unique portfolios
        let portfolios = []
        output?.forEach((row)=>{
            if(!portfolios.includes(row.portfolio.id)){
                portfolios.push(row.portfolio.id)
            }
        })

        // for each portfolio get the mvStartAdjTotal
        let mvStartAdjTotal = {}
        let mvMiddayTotal = {}
        portfolios.forEach((portfolio)=>{
            mvStartAdjTotal[portfolio] = 0
            mvMiddayTotal[portfolio] = 0
            output?.forEach((row)=>{
                if(row.portfolio.id === portfolio){
                    mvStartAdjTotal[portfolio] += row.mvStartAdj
                    mvMiddayTotal[portfolio] += row.mvMidday
                }
            })
        })

        output?.forEach((row,index)=>{
            let newRow = {...row}
            calculatedColumns.filter(column=>column.defer===true).forEach((col) => {
                newRow[col.name] = col.callBack(newRow, {mvStartAdjTotal:mvStartAdjTotal, mvMiddayTotal:mvMiddayTotal})
            })
            output[index] = newRow
        })

        return output
    },[data])

    useEffect(()=>{onChange(rowData)},[rowData])

    const columnDef = useMemo(()=>{
        return [
            {field:'portfolio.alias', headerName:'Portfolio'},
            {field:'portfolio.id', headerName:'ID', hide:true},
            {field:'security.identifier.identifier.ticker', headerName:'Ticker'},
            {field:'security.accountType', headerName:'Account Type'},
            {field:'dateStart', headerName:'Date Start', filter: 'agDateColumnFilter'},
            {field:'dateEnd', headerName:'Date End', filter: 'agDateColumnFilter'},
            {headerName:'Position',children:
                    [
                        {field:'posStart', headerName:'Start', valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
                        {field:'posTrade', headerName:'Trade', valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
                        {field:'posFlow', headerName:'Flow', valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
                        {field:'posOther', headerName:'Other', valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
                        {field:'posError', headerName:'Error', valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
                        {field:'posEnd', headerName:'End', valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
                    ]
            },

            {headerName:'Price', children:
                    [
                        {field:'pxStart', headerName:'Start', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter',editable:true},
                        {field:'pxEnd', headerName:'End', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter',editable:true},
                        {field:'fxStart', headerName:'FX Start', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter',editable:true},
                        {field:'fxEnd', headerName:'FX End', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter',editable:true},
                        {field:'priceMultiplier', headerName:'Multiplier', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'}
                    ]
            },
            {headerName:'Trade', children:
                    [
                        {field:'mvTradeLcl', headerName:'Mkt Value Lcl Trade', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                        {field:'mvTrade', headerName:'Mkt Value Trade', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                        {field:'pxTradeLcl', headerName:'Px Lcl Trade', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'}
                    ]
            },
            {headerName:'Manual Override', children:
                    [
                        {field:'mvManualOverrideStart', headerName:'Start', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter',editable:true},
                        {field:'mvManualOverrideEnd', headerName:'End', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter',editable:true},
                        {field:'mvOtherPnl', headerName:'Other PnL', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter',editable:true},
                        {field:'mvIncome', headerName:'Income', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter',editable:true}
                ]
            },
            {headerName:'Market Values', children:[
                    {field:'mvStart', headerName:'Start', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                    {field:'mvStartAdj', headerName:'Start Adj', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                    {field:'mvMidday', headerName:'Midday', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                    {field:'mvOther', headerName:'Other', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                    {field:'mvFlow', headerName:'Flow', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                    {field:'mvError', headerName:'Error', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                    {field:'mvTrade', headerName:'Trade', valueFormatter:twoDecimalThousands, filter: 'agNumberColumnFilter'},
                    {field:'mvUgl', headerName: ' UGL', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                    {field:'mvEnd', headerName:'End', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                    {field:'mvEndAdj', headerName:'End Adj', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                ]},

            {headerName:'Calculations',children:
                    [
                        {field:'weight1',headerName:'Weight 1', valueFormatter: percentTwoDecimal, filter: 'agNumberColumnFilter'},
                        {field:'roic1',headerName:'ROIC 1', valueFormatter: percentTwoDecimal, filter: 'agNumberColumnFilter'},
                        {field:'ctr1',headerName:'CTR 1', valueFormatter: percentTwoDecimal, filter: 'agNumberColumnFilter'},
                        {field:'weight2',headerName:'Weight 2', valueFormatter: percentTwoDecimal, filter: 'agNumberColumnFilter'},
                        {field:'roic2',headerName:'ROIC 2', valueFormatter: percentTwoDecimal, filter: 'agNumberColumnFilter'},
                        {field:'ctr2',headerName:'CTR 2', valueFormatter: percentTwoDecimal, filter: 'agNumberColumnFilter'},
                        {field:'weight',headerName:'Weight', valueFormatter: percentTwoDecimal, filter: 'agNumberColumnFilter'},
                        {field:'roic',headerName:'ROIC', valueFormatter: percentTwoDecimal, filter: 'agNumberColumnFilter'},
                        {field:'ctr',headerName:'CTR', valueFormatter: percentTwoDecimal, filter: 'agNumberColumnFilter'},
                    ]
            }


        ]
    },[])
    useEffect(()=>{
        console.log('detected change in selected',selected)
        if(selected?.length>0){
            let model = {}
            model['portfolio.id'] = {
                filterType:'set',
                values:selected
            }
            gridRef?.current?.api?.setFilterModel(model)
        }
        if(selected === null || selected?.length===0){
            gridRef?.current?.api?.setFilterModel({})
        }
    },[data,selected])


    return(
        <div>
            <h2>PnL</h2>
            <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDef}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    enableRangeSelection={false}
                    rowSelection='none'
                    checkboxSelection={false}
                    ref={gridRef}
                    readOnlyEdit={true}
                    onCellEditRequest={onCellEditRequest}
                    getRowId = {getRowId}
                />
            </div>
        </div>
    )
}

const PnlSummary = ({data, onToggle})=>{
    const portfolios = useMemo(()=>{
        // get all the unique portfolio ids from data
        let portfolios = []
        data?.forEach((row)=>{if(!portfolios.includes(row.portfolio.id)){portfolios.push(row.portfolio.id)}})
        let output = []
        portfolios.forEach((portfolio)=> {
            const portfolioData = data.filter((row) => {
                return row.portfolio.id === portfolio
            })
            output.push({
                portfolio: data.find((row)=>{return row.portfolio.id === portfolio}).portfolio,
                aum: portfolioData.reduce((acc, row) => {
                    return acc + row.mvEndAdj
                }, 0),
                ctr: portfolioData.reduce((acc, row) => {
                    return acc + row.ctr
                }, 0),
                flows: portfolioData.reduce((acc, row) => {
                    return acc + row.posFlow
                }, 0),
                dateEnd: portfolioData[0].dateEnd
            })
        })

        return output

    },[data])
    const handleToggle = (portfolioId, toggled)=>{onToggle(portfolioId, toggled)}

    return(
        <div>
            <h2>Summary</h2>
            <Grid container spacing={2}>
                {portfolios.map((portfolio,index)=>{return (<SummaryCard key={index} data={portfolio} onChange={handleToggle}/>)})}
            </Grid>




        </div>
    )
}

const SummaryCard = ({data, onChange})=>{
    const [toggled, setToggled] = useState(false)
    const toggle = ()=>{
        onChange(data.portfolio.id, !toggled)
        setToggled(!toggled)
    }

    return(
        <Grid xs={3}>
            <Card style={{backgroundColor: toggled ? '#1976d2':'white', color:toggled ? 'white':'black'}}>
            <CardContent>
                <Typography variant="h5" component="div">{data?.portfolio?.alias}</Typography>
                <Typography>AUM: ${average({value:data?.aum})}</Typography>
                <Typography>CTR: {percentTwoDecimal({value:data?.ctr})}</Typography>
                <Typography>Flows: ${average({value:data?.flows})}</Typography>
                <Typography>Date: {data?.dateEnd.split(' ')[0]}</Typography>




            </CardContent>
            <CardActions>
                <Button style={{color: toggled ? 'white':'#1976d2'}} size="small" onClick={()=>{toggle()}}>{toggled ? 'Deselect' : 'Select'}</Button>
            </CardActions>
        </Card>
        </Grid>
        )

}


const Results = ({data,onReset})=>{
    const handleReset = ()=>{onReset()}
    return(
        <div>
            <h2>Results</h2>
            <h4>Success</h4>
            <Button onClick={handleReset} variant={'outlined'}>Reset</Button>
        </div>
    )
}