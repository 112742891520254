import { React, useState, useRef, useMemo, useCallback } from 'react'
import { gql, useMutation, useQuery} from '@apollo/client'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from '@mui/material/Button';
import { noDecimalThousands,twoDecimalThousands,percentOneDecimal, sixDecimalThousands } from "../utils/Numbers";
import Overlay from "../components/shared/Overlay";
import dayjs from "dayjs";
import { DatePicker } from "../components/shared/DatePicker";

const GET_ROUTE_ALLOCATIONS = gql`
query GetTodaysRas($route_Datetime_Date_Gte:String!, $route_Datetime_Date_Lte:String!) {
  allRouteAllocations(route_Datetime_Date_Gte: $route_Datetime_Date_Gte, route_Datetime_Date_Lte: $route_Datetime_Date_Lte) {
    edges {
      node {
        id
        com
        fee
        fillShares
        shares        
        filled
        fx
        interest
        px
        orderAllocation {
          portfolio {
            id
            alias
          }
        }
        routeFilled
        routeShares
        route{
            id
            datetime_Date
            order{id,security{identifier{identifier},currency},side}
            broker{name}            
        }
        orderAllocation{
            id
            portfolio{alias}
        }
      }
    }
  }
}

`

const BOOK_TRADE_MUTATION = gql`
    mutation BookTrades($input:BookTradeMutationInput!){
        bookTrades(input: $input) {
            routeAllocations {
                id
                com
                fee
                filled                            
            }
        }
    }
`

export function BookTrades(){
    const initialParams = {
        route_Datetime_Date_Gte:dayjs().format('YYYY-MM-DD'),
        route_Datetime_Date_Lte:dayjs().format('YYYY-MM-DD')
    }

    const [queryParams, setQueryParams] = useState(initialParams)
    const [pageState, setPageState] = useState('initial')
    const [pageIter, setPageIter] = useState(0)
    const [bookTradeData, setBookTradeData] = useState([])
    const [results, setResults] = useState([])
    const [showOverlay, setShowOverlay] = useState(false)

    const {data, loading, error} = useQuery(GET_ROUTE_ALLOCATIONS, {
        variables:{
            route_Datetime_Date_Gte:queryParams.route_Datetime_Date_Gte,
            route_Datetime_Date_Lte:queryParams.route_Datetime_Date_Lte
        },
        fetchPolicy:'network-only'
    }
    )
    const [bookTrades, {data:bookTradeData2, loading:bookTradeLoading, error:bookTradeError}] = useMutation(BOOK_TRADE_MUTATION, );

    const handleChange = (e)=>{setQueryParams({...queryParams, [e.target.name]:e.target.value})}
    const handleStage = (d)=>{
        setBookTradeData(d);
        setPageState('confirm');
    }

    const handleSubmit = ()=>{
        setShowOverlay(true)
        const output = bookTradeData.map(i=>{return {id:i.id, com:i.com, fee:i.fee, filled:i.filled}})
        bookTrades({
            variables:{"input":{"data":output}},
            onCompleted: (bookTradeData2)=>{
                setShowOverlay(false)
                setResults(bookTradeData2);
                setPageState('results');
            },
            onError: (error)=>{
                console.log(error);
                setShowOverlay(false)
            }

        })
    }

    const handleReset = ()=>{setShowOverlay(false);setPageIter(pageIter+1);setPageState('initial')}

    const rowData = useMemo(()=>{
        if(loading){return []};
        return data?.allRouteAllocations?.edges?.map((i) => {
            return {
            id: i.node.id,
            routeId: i.node.route.id,
            datetime_Date: i.node.route.datetime_Date,
            ticker: i.node.route.order.security.identifier.identifier.ticker,
            portfolio: i.node.orderAllocation.portfolio.alias,
            side: i.node.route.order.side,
            broker: i.node.route.broker.name,
            shares: i.node.shares,
            routeShares: i.node.routeShares,
            actualPercent: i.node.actualPercent,
            routeFilled: i.node.routeFilled,
            filled: i.node.filled,
            leaves: i.node.leaves,
            totalAllocated: i.node.total_allocated,
            status: i.node.status,
            com: i.node.com,
            fee: i.node.fee,
            currency: i.node.route.order.security.currency,
            px: i.node.px,
            valid: i.node.valid
            }})

    },[data,loading])

    return (
        <div>
            <Overlay open={showOverlay}/>
            <h1>Book Trades</h1>
            <div style={{display: pageState==='initial'?'block':'none'}} key={pageIter}>
                <DatePicker value={dayjs(queryParams.route_Datetime_Date_Gte)} setValue = {(newValue)=>{handleChange({target:{name:'route_Datetime_Date_Gte', value:newValue}})}} label={'Start Date'}/>
                <DatePicker value={dayjs(queryParams.route_Datetime_Date_Lte)} setValue = {(newValue)=>{handleChange({target:{name:'route_Datetime_Date_Lte', value:newValue}})}} label={'Start Date'}/>
                <BookTradeTable rowData={rowData} handleSubmit={handleStage}/>
            </div>

            <div style={{display: pageState==='confirm'?'block':'none'}}><BookTradeConfirm bookTradeData={bookTradeData} onSubmit={handleSubmit} onBack={()=>{setPageState('initial')}}/></div>
            <div style={{display: pageState==='results'?'block':'none'}}><BookTradeResults results={results} onReset={handleReset}/></div>

        </div>
    )
}

function BookTradeTable({rowData, handleSubmit}) {

    const [feeState, setFeeState] = useState(8)
    const targetPercentGetter = (params) => {
        if(params.data.routeShares === 0){return 0}
        else{return params.data.shares / params.data.routeShares}}
    const actualPercentGetter = (params)=>{
        if(params.data.routeFilled===0){return 0}
        else {return params.data.filled / params.data.routeFilled}
    }
    const totalAllocatedGetter = (params) => {
        let totalAllocated = 0;
        const currentRouteId = params.data.routeId;
        params.api.forEachNode((node) => {if (node.data.routeId === currentRouteId) {totalAllocated += node.data.filled;}});
        return totalAllocated;
    }
    const routeAllocationLeavesGetter = (params) => {
        return params.data.shares - params.data.filled
    }
    const routeLeavesGetter = (params) => {return params.data.routeShares - params.data.routeFilled}
    const statusGetter = (params) => {
        let totalAllocated = params.getValue('totalAllocated')
        if (totalAllocated === 0) {return 'Unallocated';}
        else if (totalAllocated === params.data.routeFilled) {return 'Fully Allocated';}
        else if (totalAllocated < params.data.routeFilled) {return 'Partially Allocated';}
        else if (totalAllocated > params.data.routeFilled) {return 'Over Allocated';}
        return 'Unknown';
    }
    const setCom = (comScheme) => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        selectedNodes.forEach(node => {
            const px = node.data.px;
            let com = 0
            if (comScheme === 'bps') {com = px* 15 / 10000;}
            if (comScheme === 'std'){
                if(px<1){com = 0.01;}
                if(px>=1 && px<5){com = 0.02;}
                if(px>=5 && px<10){com = 0.03;}
                if(px>=10){com = 0.04;}
            }
            node.setDataValue('com', com);
        });
        gridRef.current.api.refreshCells(); // Refresh the grid to reflect the changes


    }
    const setFee = () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        selectedNodes?.forEach(node => {
            const px = node.data.px;
            node.setDataValue('fee', px*feeState/1000000);
        });
        gridRef.current.api.refreshCells(); // Refresh the grid to reflect the changes
    }
    const allocateSelected = () => {
        const selectedNodes = gridRef?.current?.api?.getSelectedNodes();
        const routeIdMap = {};

        // First pass: Set filled value and build routeIdMap
        selectedNodes?.forEach(node => {
            const targetPercent = node.data.shares / node.data.routeShares
            const routeFilled = node.data.routeFilled;
            const filled = Math.round(targetPercent * routeFilled);
            node.setDataValue('filled', filled);
            const routeId = node.data.routeId;
            if (!routeIdMap[routeId]) {
                routeIdMap[routeId] = {
                    totalAllocated: 0,
                    maxFilledNode: null
                };
            }
            routeIdMap[routeId].totalAllocated += filled;
            if (!routeIdMap[routeId].maxFilledNode || filled > routeIdMap[routeId].maxFilledNode.data.filled) {
                routeIdMap[routeId].maxFilledNode = node;
            }
        });

        // Second pass: Adjust filled value for rows where totalAllocated > routeFilled
        Object.values(routeIdMap).forEach(({ totalAllocated, maxFilledNode }) => {
            if (totalAllocated > maxFilledNode.data.routeFilled) {
                const adjustment = totalAllocated - maxFilledNode.data.routeFilled;
                const newFilled = maxFilledNode.data.filled - adjustment;
                maxFilledNode.setDataValue('filled', newFilled);
            }
        });

        gridRef.current.api.refreshCells(); // Refresh the grid to reflect the changes
    }
    const validateRow = (row) => {return true}
    const onSubmit = () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        let output = []
        selectedNodes.forEach(node => {
            if(validateRow(node.data)){output.push({
                id: node.data.id,
                com: node.data.com,
                fee: node.data.fee,
                filled: node.data.filled,
                portfolio: node.data.portfolio,
                ticker: node.data.ticker,
                side: node.data.side,
                currency: node.data.currency,
            })}
        })
        handleSubmit(output);
    }
    const changeFee = (e) => {setFeeState(e.target.value)}

    const [columnDef, setColumnDef] = useState([
        {field: 'id',headerCheckboxSelection: true,checkboxSelection: true,headerCheckboxSelectionFilteredOnly: true},
        {field: 'routeId', headerName: 'Route ID'},
        {field: 'datetime_Date', filter: 'agDateColumnFilter', headerName: 'Date'},
        {field: 'ticker', headerName: 'Ticker'},
        {field: 'portfolio', headerName: 'Portfolio'},
        {field: 'side', headerName: 'Side'},
        {field: 'broker', headerName: 'Broker'},
        {field: 'px',valueFormatter:twoDecimalThousands, filter: 'agNumberColumnFilter'},
        {headerName: 'RA Shares', field: 'shares', valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
        {headerName:'Route Shares', field: 'routeShares',valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
        {headerName: 'Route Filled',field: 'routeFilled',valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter' },
        {field: 'targetPercent',valueGetter: params => targetPercentGetter(params),valueFormatter: percentOneDecimal,filter: 'agNumberColumnFilter'},
        {field: 'actualPercent',valueGetter: params=> actualPercentGetter(params), valueFormatter: percentOneDecimal,filter: 'agNumberColumnFilter'},
        {headerName: 'RA Filled', field: 'filled', editable: true, valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter', onCellValueChanged: params=>{if(params.newValue!==params.oldValue){params.api.refreshCells()}}},
        {
            headerName: 'RA Leaves',
            field: 'leaves',
            valueGetter: params => routeAllocationLeavesGetter(params),
            valueFormatter: noDecimalThousands,
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: 'Route Leaves',
            valueGetter: params => routeLeavesGetter(params),
            valueFormatter: noDecimalThousands,
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: 'Total Route Allocated',
            field: 'totalAllocated',
            valueGetter: params => totalAllocatedGetter(params),
            valueFormatter: noDecimalThousands,
            filter: 'agNumberColumnFilter'
        },
        {field: 'status', valueGetter: params => statusGetter(params)},
        {
            field: 'com',
            editable: true,
            valueParser: params => Number(params.newValue),
            valueFormatter: twoDecimalThousands,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'fee',
            editable: true,
            valueParser: params => Number(params.newValue),
            valueFormatter: sixDecimalThousands,
            filter: 'agNumberColumnFilter'
        },
        {field: 'currency'},
        {field: 'valid'}


    ])
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        };
    }, []);
    return (
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <Button variant={"outlined"} onClick={() => {allocateSelected()}}>Allocate</Button>
            <Button variant={"outlined"} onClick={() => {setCom('std')}}>Set Std Com</Button>
            <Button variant={"outlined"} onClick={() => {setCom('bps')}}>Set Bps Com</Button>
            <Button variant={"outlined"} onClick={() => {setFee()}}>Set Fee</Button>
            <input name={'fee'} onChange={changeFee} value={feeState}/>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='multiple'
                checkboxSelection={true}
                ref={gridRef}
            />
            <Button variant={"contained"} onClick={() => {onSubmit()}} primary>Book Trades</Button>
        </div>
    )
}

function BookTradeConfirm({bookTradeData, onBack, onSubmit}) {
    const [columnDef, setColumnDef] = useState([
        {field: 'id', headerName: 'ID'},
        {field:'portfolio', headerName: 'Portfolio'},
        {field:'ticker', headerName: 'Ticker'},
        {field:'side', headerName: 'Side'},
        {field:'currency', headerName: 'Currency'},
        {field: 'filled', headerName: 'Filled', valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter'},
        {field: 'com', headerName: 'Com', valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
        {field: 'fee', headerName: 'Fee', valueFormatter: sixDecimalThousands, filter: 'agNumberColumnFilter'},
    ])
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
            editable:false,
        };
    }, []);
    const handleSubmit = useCallback(()=>{onSubmit(bookTradeData)},[bookTradeData])
    const handleBack = ()=>{onBack()}
    return (
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <AgGridReact
                rowData={bookTradeData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                ref={gridRef}
            />
            <Button variant={"contained"} onClick={handleSubmit}>Submit</Button>
            <Button variant={"outline"} onClick={handleBack}>Back</Button>

        </div>
    )
}

function BookTradeResults({results,onReset}) {
    const onGenerateTradeFiles = ()=>{}
    return(
        <div>
            <h1>Results</h1>
            <h4>Success</h4>
            <Button variant={"outlined"} onClick={()=>{onReset()}}>Reset</Button>
        </div>
    )
}