import React, {useMemo, useRef, useState, useCallback} from "react";
import Button from "@mui/material/Button";
import {AgGridReact} from "ag-grid-react";
import {gql, useQuery} from "@apollo/client";
import {DatePicker} from "../components/shared/DatePicker";
import dayjs from "dayjs";
import {twoDecimalThousands, noDecimalThousands, percentOneDecimal} from "../utils/Numbers";
import Overlay from "../components/shared/Overlay";

// Queries
const GET_PORTFOLIOS = gql`
query GET_PORTS(
    $portfolio_Active:Boolean!
    $datetime_Date_Lte:String!
) {
  allPositions(
    portfolio_Active: $portfolio_Active
    datetime_Date_Lte: $datetime_Date_Lte
  ) {
    edges {
      node {
        id
        datetime_Date
        
        portfolio {
          id
          alias
        }
        
        pos
        
        price {
          datetime
          fx
          px
          id
          security{id}
        }
        
        security {
          identifier {
            identifier
          }
          id
          priceMultiplier
          sector
          accountType
          currency
        }
      }
    }
  }
}
`

export function Portfolio(){
    const initialParams = {datetime_Date_Lte:dayjs().format('YYYY-MM-DD'), portfolio_Active:true}
    const [queryParams, setQueryParams] = useState(initialParams)
    const [prices, setPrices] = useState([])

    const {loading, error, data, refetch} = useQuery(
        GET_PORTFOLIOS, {
            variables:queryParams,
            notifyOnNetworkStatusChange:true,
            onCompleted:(data)=>{setPrices(priceSet)}
        })

    const gridRef = useRef(null)

    const handleChange = (e)=>{
        const newParams = {...queryParams, [e.target.name]:e.target.value}
        setQueryParams(newParams)
    }

    const portfolioSet = useMemo(() => {
        if(loading || error){return []}
        let output =  new Set(data?.allPositions.edges.map((edge) => edge.node.portfolio))
        // sort output based upon a specific order
        const order = [
            'UG9ydGZvbGlvTGlzdE5vZGU6NA==',
            'UG9ydGZvbGlvTGlzdE5vZGU6NTc=',
            'UG9ydGZvbGlvTGlzdE5vZGU6MjI=',
            'UG9ydGZvbGlvTGlzdE5vZGU6MTc=',
            'UG9ydGZvbGlvTGlzdE5vZGU6MTg=',
            'UG9ydGZvbGlvTGlzdE5vZGU6MTk=',
            'UG9ydGZvbGlvTGlzdE5vZGU6MjA=',
            'UG9ydGZvbGlvTGlzdE5vZGU6MTY=',
            'UG9ydGZvbGlvTGlzdE5vZGU6MjE=',
            'UG9ydGZvbGlvTGlzdE5vZGU6MTQ=',
            'UG9ydGZvbGlvTGlzdE5vZGU6MTU=',
            'UG9ydGZvbGlvTGlzdE5vZGU6MjQ=',
        ]
        output = [...output].sort((a,b) => {return order.indexOf(a.id) - order.indexOf(b.id)})
        return [...output]
    },[data, loading, error])

    const securitySet = useMemo(() => {
        if(loading || error){return []}
        const output = new Set(data?.allPositions.edges.map((edge) => edge.node.security))
        return [...output]
    },[data, loading, error])

    const priceSet = useMemo(() => {
        if(loading || error){return []}
        var output = new Set(data?.allPositions.edges.map((edge) => edge.node.price))
        output = [...output].filter((price) => {return price !== null})
        return output

    },[data, loading, error])

    const getPosition = (portId, secId) => {
        const results = data?.allPositions?.edges?.filter((edge) => {return (edge.node.portfolio.id === portId && edge.node.security.id === secId)})
        if(results.length > 0){return results[0].node.pos}else{return 0}
    }

    const getPrice = useCallback((secId)=>{
        if(loading || error){return null}
        const results = prices?.filter((price) => {return (price.security.id === secId)})
        if(results.length > 0){return results[0]}else{return null}},[prices])



    const accountTypeSort = (valueA,valueB, nodeA, nodeB, isInverted) => {
        const accountTypeOrder = ['POSITION','CASH EQUIVALENT','ACCRUAL','TBD']
        const indexA = accountTypeOrder.indexOf(valueA)
        const indexB = accountTypeOrder.indexOf(valueB)
        if(indexA < indexB){return -1}
        if(indexA > indexB){return 1}
        return 0
    }

    const sectorSort = (valueA,valueB, nodeA, nodeB, isInverted) => {
        const sectorOrder = [
            'EP',
            'OFFSHORE',
            'OILSERVICES',
            'URANIUM',
            'COPPER',
            'GOLD',
            'COAL',
            'AGRICULTURE',
            'DIAMONDS',
            'CASH EQUIVALENT',
            'ACCOUNTING',
        ]
        const indexA = sectorOrder.indexOf(valueA)
        const indexB = sectorOrder.indexOf(valueB)
        if(indexA < indexB){return -1}
        if(indexA > indexB){return 1}
        return 0
    }

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
            editable:false,
        };
    }, []);

    const mvGetter = (params)=>{
        const portId = portfolioSet.find((port)=>{return port.alias === params.colDef.headerName})?.id
        const pos = params.data.positions[portId]
        const px = params.data.px
        const fx = params.data.fx
        const priceMultiplier = params.data.priceMultiplier
        const mv = pos * px * priceMultiplier / fx
        return mv
    }

    const weightGetter = (params)=>{
        const portId = portfolioSet.find((port)=>{return port.alias === params.colDef.headerName})?.id
        let mvTotal = 0
        gridRef?.current?.api.forEachLeafNode((node)=>{mvTotal+=node.data.positions[portId] * node.data.px * node.data.priceMultiplier / node.data.fx})
        const mv = params.data.positions[portId] * params.data.px * params.data.priceMultiplier / params.data.fx
        return mv/mvTotal
    }


    const columnDef = useMemo(() => {
        const shouldSort =(port)=>{if(port.id==='UG9ydGZvbGlvTGlzdE5vZGU6NA=='){return 'desc'}; return 'none'};

        var output = [
            {
                headerName: 'Security',
                children: [
                    {
                        headerName: 'Account Type',
                        field:'accountType',
                        rowGroup:true,
                        hide:true
                    },
                    {
                        headerName: 'Sector',
                        field:'sector',
                        rowGroup:true,
                        hide:true,
                        minWidth:50
                    },
                    {headerName: 'Ticker', field: 'identifier.ticker'},
                ]
            },
            {
                headerName: 'Pricing',
                children:[
                    {headerName:'Price', field: 'px', editable:true, valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                    {headerName:'Date', field: 'date', openByDefault: false,hide:true, filter: 'agDateColumnFilter'},
                    {headerName:'FX', field: 'fx', editable:true, valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                    {headerName: 'Multiplier', field: 'priceMultiplier', editable:false, openByDefault: false,hide:true, valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter'},
                ]
            },
            {
                headerName: 'Position',
                children: portfolioSet?.map((port) => {return {headerName: port.alias, valueGetter: (params)=>{return params?.data?.positions[port.id]}, editable:false, openByDefault: false,hide:true, valueFormatter: noDecimalThousands, filter: 'agNumberColumnFilter', aggFunc:'sum'}})
            },
            {
                headerName: 'Market Value',
                children: portfolioSet?.map((port) => {return {headerName: port.alias, editable:false, valueGetter: mvGetter, openByDefault: false,hide:true, valueFormatter: twoDecimalThousands, filter: 'agNumberColumnFilter', aggFunc:'sum', sort:shouldSort(port), sortIndex:1}})
            },
            {
                headerName: 'Weight',
                children: portfolioSet?.map((port) => {return {
                    headerName: port.alias,
                    editable:false,
                    valueGetter: weightGetter,
                    valueFormatter: percentOneDecimal,
                    filter: 'agNumberColumnFilter',
                    aggFunc:'sum'
                }})
            }
        ]

        return output
    },[data, portfolioSet])

    const rowData = useMemo(()=>{
        if(loading){
            gridRef?.current?.api.showLoadingOverlay();
            return [{positions:{tmp:0}}]}
        gridRef?.current?.api.hideOverlay();
        let output = []
        if(data) {
            securitySet.forEach((sec) => {
                let row = {}
                row['id'] = sec.id
                row['accountType'] = sec.accountType
                row['sector'] = sec.sector
                row['identifier'] = sec.identifier.identifier
                row['currency'] = sec.currency
                row['priceMultiplier'] = sec.priceMultiplier
                row['secId'] = sec.id
                let positions = {}
                portfolioSet.forEach((port)=>{positions[port.id] = getPosition(port.id, sec.id)})
                row['positions'] = positions
                let price = getPrice(sec.id)
                if (price) {
                    row['px'] = price.px
                    row['fx'] = price.fx
                    row['date'] = price.datetime
                } else {
                    row['px'] = 0;
                    row['fx'] = 1;
                    row['date'] = null;
                }

                output.push(row)
            })

        }

        output = output.sort((a,b)=>{
            const accountType = accountTypeSort(a.accountType,b.accountType)
            if(accountType !== 0){return accountType}
            if(accountType === 0){
                const sector = sectorSort(a.sector,b.sector)
                if(sector !== 0){return sector}
            }
            //const weightA = a.weights['UG9ydGZvbGlvTGlzdE5vZGU6NA==']
            //const weightB = b.weights['UG9ydGZvbGlvTGlzdE5vZGU6NA==']
            //if(weightA > weightB){return -1}
            //if(weightA < weightB){return 1}
            //return 0
        })

        return output

    },[data, securitySet, portfolioSet, loading, prices])

    const autoGroupColumnDef = useMemo(()=>{
        return{
            headerName:'Group',
            minWidth:200,
        }
    },[])

    const handleCellValueChanged = (params)=> {
        gridRef.current.api.refreshClientSideRowModel('sort')
        gridRef.current.api.refreshClientSideRowModel('group')
    }

    if(loading){return <Overlay open={loading}/>}
    return (
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>Portfolio</h1>
            <DatePicker value={dayjs(queryParams.datetime_Date_Lte)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Lte', value:newValue}})}} label={'As of'}/>
            <AgGridReact
                rowData={rowData}
                ref={gridRef}
                defaultColDef={defaultColDef}
                columnDefs={columnDef}
                animateRows={true}
                enableRangeSelection={true}
                multiSortKey={'ctrl'}
                groupDisplayType={'singleColumn'}
                groupMaintainOrder={true}
                autoGroupColumnDef={autoGroupColumnDef}
                onCellValueChanged={handleCellValueChanged}
                isGroupOpenByDefault={(params)=>{
                    if(params.field==='sector'){return true}
                    if(params.field==='accountType' && params.key==='POSITION'){return true}
                    return false
                }}
            />
            <span>Total Entries: {rowData?.length || 0}</span>
        </div>
    )
}

