import {React} from "react";
import Button from '@mui/material/Button';

export function  Initial({onSubmit, onReset, onBack}){
    return(
        <div>
            <h2>Start New Reconciliation</h2>
            <Button variant={"contained"} onClick={onSubmit}>Start</Button>
        </div>
    )
}