import {React, useEffect, useState, useCallback} from 'react'
import { FileUploader } from '../components/FileUploader'
import {gql, useMutation } from '@apollo/client'
import { CssVarsProvider } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import {Link} from "react-router-dom";

const ADD_FILE =  gql`
mutation MyMutation($input:AddFileMutationInput!) {
addFile(input: $input) {
file {
        id
      name
      description
      downloadUrl
    }
  }
}
`

export function NewFile(){
    const [form, setForm] = useState({description: null,file: null,})
    const [pageState, setPageState] = useState('initial')
    const [addFile, {data, loading, error}] = useMutation(ADD_FILE)
    const [pageIter, setPageIter] = useState(0)

    const handleReset = ()=>{setPageState('initial');setForm({description: null,file: null,});setPageIter(pageIter+1)}
    const removeFile = ()=>setForm({...form, file:null})
    const handleError = ()=>{console.log('error')}
    const handleSuccess = (file)=>{setForm({...form, file:file})}
    const handleChange = (e) => {setForm({...form, [e.target.name]: e.target.value});}
    const handleSubmit = (e) => {
        e.preventDefault();
        addFile({
            variables: {
                input: {
                    description: form.description,
                    file: form.file.uploadDetails.fields.key,
                    name: form.file.file.path
                }},
            onCompleted: (data) => {setPageState('success')},
            onError: (error) => {setPageState('error')}
        })
    }

    return (
        <div>
            <div key={'initial_'+pageIter} style={{display: pageState==='initial'?'block':'none'}}>
                <h1>New File</h1>
                    <Sheet
                        sx={{
                            width: '50%',
                            mx: 'auto', // margin left & right
                            my: 4, // margin top & botom
                            py: 3, // padding top & bottom
                            px: 2, // padding left & right
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            borderRadius: 'sm',
                            boxShadow: 'md',
                        }}
                        variant="outlined"
                    >
                        <Input
                            name="description"
                            type="text"
                            placeholder="File Description"
                            label="Description"
                            value = {form.description}
                            onChange = {handleChange}
                        />

                        {form.file ?<div>{form.file.file.name}<button onClick={removeFile}>Remove</button></div> : <div/>}

                        <FileUploader onError={handleError} onSuccess={handleSuccess}/>
                        <Button variant={"contained"} onClick={handleSubmit}>Submit</Button>

                    </Sheet>

            </div>
            <div style={{display: pageState==='success'?'block':'none'}}>{data ? <UploadSuccess data={data} handleReset={handleReset}/> : <div/>}</div>
            <div style={{display: pageState==='error'?'block':'none'}}><UploadError handleReset={handleReset}/></div>
        </div>
    )
}

const UploadSuccess = ({data, handleReset})=>{
    return(
        <div onClick={()=>{console.log(data)}}>
            <h1>Success</h1>
            <p>{data.addFile.name}</p>
            <p>{data.addFile.description}</p>
            <a href={data.addFile.file.downloadUrl}>Download</a>
            <button onClick={handleReset}>Upload Another File</button>
        </div>
    )
}



const UploadError = ({handleReset})=>{
    return(
        <div>
            <h1>Error</h1>
            <button onClick={handleReset}>Try Again</button>
        </div>
    )
}