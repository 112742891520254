import Button from "@mui/material/Button";
import {FileUploader} from "./FileUploader";
import {React, useState} from "react";

import{gql, useMutation, useQuery } from '@apollo/client'

const ADD_TRADE_FILE = gql`
    mutation AddTradeFile($input:AddFileMutationInput!) {
        addFile(input: $input) {
        file {
        id
        name
        description
        downloadUrl
    }
  }
}`

const PROCESS_FILE = gql`mutation ProcessFileMutation($input:ProcessFileMutationInput!) {processFile(input: $input) {routeAllocations {id}}}`

const GET_TEMPLATE = gql`
query GetNewTradeTemplate($fileName: String!) {
  allFileObjs(name_Icontains: $fileName) {
    edges {
      node {
        downloadUrl
        name
      }
    }
  }
}
`
export function UploadTradeFile({onBack, onSuccess}){
    const [pageState, setPageState] = useState('initial')
    const [addTradeFile, {data, loading, error}] = useMutation(ADD_TRADE_FILE)
    const [processTradeFile, {data:processData, loading:processLoading, error:processError}] = useMutation(PROCESS_FILE)
    const {data:templateData, loading:templateLoading, error:templateError} = useQuery(GET_TEMPLATE, {variables:{fileName:'new_trade_template.csv'},fetchPolicy:'network-only'})


    const handleS3Error = ()=>{setPageState('s3 error')}
    const handleS3Success = (x)=>{
        setPageState('s3 success')
        saveFile(x)
    }
    const saveFile = (x)=>{
        addTradeFile({
            variables:{
                input:{
                    description: 'groms uploaded trade file',
                    file: x.uploadDetails.fields.key,
                    name: x.file.path
                }
            },
            onCompleted: (data)=>{
                setPageState('successfully saved to groms');
                processFile(data.addFile.file.id)
            },
            onError: (e)=>{
                setPageState('error saving to groms')
            }
        });

    };
    const processFile = (fileId)=>{
        processTradeFile({
            variables:{
                input:{
                    fileId: fileId,
                    fileType: 'newRas'
                }
            },
            onCompleted: (processData)=>{
                setPageState('successfully processed file');
                const savedRas = processData.processFile.routeAllocations.map((x)=>x.id)
                onSuccess(savedRas)
            },
            onError: (e)=>{
                setPageState('error processing file')
            }
        })

    }
    const downloadTemplate = (templateData)=>{window.open(templateData.allFileObjs.edges[0].node.downloadUrl)}

    return(
        <div>
            <h1>Upload Trade File</h1>
            <Button variant={"outlined"} onClick={()=>{onBack()}}>Back</Button>
            {templateData ? <Button variant={"outlined"} onClick={()=>{downloadTemplate(templateData)}}>Download Template</Button>:<div/>}
            <FileUploader onError={handleS3Error} onSuccess={handleS3Success}/>
            <p>{pageState}</p>
        </div>

    )
}