import {gql, useQuery} from "@apollo/client";
import React, {useState, useMemo, useRef} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import dayjs from "dayjs";
import {DatePicker} from "../components/shared/DatePicker";


const GET_COMPLIANCES = gql`query ALL_CR($datetime_Date_Gte: String!, $datetime_Date_Lte: String!) {
  allComplianceReports(
    datetime_Date_Gte: $datetime_Date_Gte
    datetime_Date_Lte: $datetime_Date_Lte
  ) {
    edges {
      node {
        id
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`

export  function Compliances(){
    const initialParams = {datetime_Date_Gte:dayjs().format('YYYY-MM-DD'), datetime_Date_Lte:dayjs().format('YYYY-MM-DD')}
    const [queryParams, setQueryParams] = useState(initialParams)
    const {loading, error, data, refetch} = useQuery(GET_COMPLIANCES, {variables:queryParams});
    const handleChange = (e)=>{
        const newParams = {...queryParams, [e.target.name]:e.target.value}
        setQueryParams(newParams)
    }


    const [columnDef, setColumnDef] = useState([
        {field:'node.id'},
    ])

    const gridRef = useRef(null)

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);
    const rowData = useMemo(()=>{
        if(loading){gridRef?.current?.api.showLoadingOverlay();return null}
        gridRef?.current?.api.hideOverlay();
        return data.allComplianceReports?.edges
    },[data, loading])

    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>Compliance Reports</h1>
            <DatePicker value={dayjs(queryParams.datetime_Date_Gte)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Gte', value:newValue}})}} label={'Start Date'}/>
            <DatePicker value={dayjs(queryParams.datetime_Date_Lte)} setValue = {(newValue)=>{handleChange({target:{name:'datetime_Date_Lte', value:newValue}})}} label={'End Date'}/>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='none'
                checkboxSelection={false}
                ref={gridRef}
            ></AgGridReact>
            <span>Total Entries: {rowData?.length}</span>
        </div>
    )
}