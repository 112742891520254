import {React} from 'react'
import {Link, Route} from 'react-router-dom'

export function MainPage(){
    const routes = [
        {name:'New Trade', path:'/groms/trades/new'},
        {name:'Compliances', path:'/groms/compliances'},
        {name:'Generate', path:'/groms/generate'},
        {name:'Trades', path:'/groms/trades/book'},
        {name:'Files', path:'/groms/files'},
        {name:'GLs', path:'/groms/gls'},
        {name:'Routes', path:'/groms/routes'},
        {name:'New Compliance', path:'/groms/compliances/new'},
        {name:'New File', path:'/groms/files/new'},
        {name:'New Flow', path:'/groms/flows/new'},
        {name:'New GLS', path:'/groms/gls/new'},
        {name:'New Performance', path:'/groms/performances/new'},
        {name:'New Price', path:'/groms/prices/new'},
        {name:'New Reconciliation', path:'/groms/reconciliations/new'},
        {name:'New Trade', path:'/groms/trades/new'},
        {name:'New Fill', path:'/groms/fills/new'},
        {name:'Fills', path:'/groms/fills'},
        {name:'Order Allocations', path:'/groms/orderallocations'},
        {name:'Orders', path:'/groms/orders'},
        {name:'Performances', path:'/groms/performances'},
        {name:'Portfolio', path:'/groms/portfolio'},
        {name:'Portfolio Lists', path:'/groms/portfoliolists'},
        {name:'Prices', path:'/groms/prices'},
        {name:'Reconciliations', path:'/groms/reconciliations'},
        {name:'Route Allocations', path:'/groms/routeallocations'},
        {name:'Security Identifiers', path:'/groms/securityidentifiers'},
        {name:'Security Masters', path:'/groms/securitymasters'},
    ]
    return (
        <div>
            <h1>Welcome to Groms</h1>
            <h3>Please use the menu on the left or select from the following:</h3>
            {routes.map((item,index)=>{return(
                <div key={index}><h4><Link to={item.path}>{item.name}</Link></h4></div>)})}

            <h4><Link to={'/groms/trades/book'}>Trades</Link></h4>
            <Link to={'/groms/portfolio'}>Portfolio</Link>

        </div>
    )
}




