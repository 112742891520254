import React, {useMemo, useContext} from "react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import logo from './logo.svg';
import './App.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import GromsWrapper from './components/GromsWrapper.js'
import {GromsProvider, RequireAuth} from './context/Context.js'

import {BookTrades} from './pages/BookTrades.js'
import {Compliances} from './pages/Compliances.js'
import {Generate} from './pages/Generate.js'
import {Files} from './pages/Files.js'
import {Fills} from './pages/Fills.js'
import {NewFills} from './pages/NewFills.js'
import {Gls} from './pages/Gls.js'
import {GromsRoutes} from "./pages/GromsRoutes";
import {NewCompliance} from "./pages/NewCompliance";
import {NewFile} from "./pages/NewFile";
import {NewFlow} from "./pages/NewFlow";
import {NewGls} from "./pages/NewGls";
import {NewPerformance} from "./pages/NewPerformance";
import {NewPrice} from "./pages/NewPrice";
import {NewReconciliation} from "./pages/NewReconciliation";
import {NewTrades} from "./pages/NewTrades";
import {OrderAllocations} from "./pages/OrderAllocations";
import {Orders} from "./pages/Orders";
import {Performances} from "./pages/Performances";
import {Portfolio} from "./pages/Portfolio";
import {PortfolioLists} from "./pages/PortfolioLists";
import {Prices} from "./pages/Prices";
import {Reconciliations} from "./pages/Reconciliations";
import {RouteAllocations} from "./pages/RouteAllocations";
import {SecurityIdentifier} from "./pages/SecurityIdentifier";
import {SecurityMasters} from "./pages/SecurityMasters";
import {Login} from "./pages/Login";
import {LoginResetRequest} from "./pages/LoginResetRequest";
import {LoginReset} from './pages/LoginReset.js'
import {MainPage} from './pages/MainPage.js'

function App() {
  return (
    <div className="App">
        <Router>
            <AppRouter/>
        </Router>
    </div>
  );
}

export default App;

function AppRouter(){
  return(
      <Routes>
          <Route path='/' element={<Login/>}/>
          <Route path='login' element={<Login/>}/>
          <Route path='login/reset' element={<LoginResetRequest/>}/>
          <Route path='login/reset/:key' element={<LoginReset/>}/>

          <Route path='groms' element={<RequireAuth><GromsWrapper/></RequireAuth>}>
              <Route path='main' element={<MainPage/>}/>
              <Route path={'trades/new'} element={<NewTrades/>}/>
              <Route path={'compliances'} element={<Compliances/>}/>
              <Route path={'generate'} element={<Generate/>}/>
              <Route path={'trades/book'} element={<BookTrades/>}/>
              <Route path={'files'} element={<Files/>}/>
              <Route path={'gls'} element={<Gls/>}/>
              <Route path={'routes'} element={<GromsRoutes/>}/>
              <Route path={'compliances/new'} element={<NewCompliance/>}/>
                <Route path={'files/new'} element={<NewFile/>}/>
                <Route path={'flows/new'} element={<NewFlow/>}/>
                <Route path={'gls/new'} element={<NewGls/>}/>
                <Route path={'performances/new'} element={<NewPerformance/>}/>
                <Route path={'prices/new'} element={<NewPrice/>}/>
                <Route path={'reconciliations/new'} element={<NewReconciliation/>}/>
                <Route path={'trades/new'} element={<NewTrades/>}/>
                <Route path={'fills/new'} element={<NewFills/>}/>
                <Route path={'fills'} element={<Fills/>}/>
                <Route path={'orderallocations'} element={<OrderAllocations/>}/>
                <Route path={'orders'} element={<Orders/>}/>
                <Route path={'performances'} element={<Performances/>}/>
                <Route path={'portfolio'} element={<Portfolio/>}/>
                <Route path={'portfoliolists'} element={<PortfolioLists/>}/>
                <Route path={'prices'} element={<Prices/>}/>
                <Route path={'reconciliations'} element={<Reconciliations/>}/>
                <Route path={'routeallocations'} element={<RouteAllocations/>}/>
                <Route path={'securityidentifiers'} element={<SecurityIdentifier/>}/>
                <Route path={'securitymasters'} element={<SecurityMasters/>}/>
          </Route>
      </Routes>
  )
}