import {React, useMemo, useRef, useState, useCallback} from 'react';
import { gql, useLazyQuery, useQuery, useMutation} from "@apollo/client";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {DatePicker} from "../components/shared/DatePicker";
import dayjs from "dayjs";
import {noDecimalThousands, percentOneDecimal, twoDecimalThousands} from "../utils/Numbers";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Overlay from "../components/shared/Overlay";
import {PortfolioSelectWrapper} from "../components/shared/PortfolioSelect";
import Select from 'react-select';


const initialComplianceReport = {
    datetime: new Date(),
    notes: "From GROMS 5.2",
    passed: false,
    reportType: "PTC",
    json:{
        'grid':[],
        'results':[],
    },
    portfolioId: '',
}
const GET_PTC_RAS = gql`
query PtcRas(
  $shares_Gt:Decimal!,
  $route_Datetime_Date:String!,
  $orderAllocation_PortfolioId:ID!
) {
  allRouteAllocations(
    shares_Gt: $shares_Gt,
    route_Datetime_Date: $route_Datetime_Date,
    orderAllocation_PortfolioId: $orderAllocation_PortfolioId
  ) {
    edges {
      node {
        id
        route {
          datetime_Date
          order {
            side
            security {
              currency
              identifier {
                identifier
              }
            }
          }
        }
        shares
        px
      }
    }
  }
}
`
const GET_GRID_DATA = gql`
query GetGridData(
    $portfolioId:ID!,
    $datetime_Date_Lt:String,
    $datetime_Date_Lte:String,
    $routeAllocationId_In:[ID!],
    $reportType:String,
) {
  allComplianceGrid(
    portfolioId: $portfolioId,
    datetime_Date_Lt: $datetime_Date_Lt,
    datetime_Date_Lte: $datetime_Date_Lte,
    routeAllocationId_In: $routeAllocationId_In,
    reportType: $reportType,
  ) {
    edges {
      node {
        id
        portfolio {
            id
            name
        }        
        security {
          id
          identifier {identifier}
          priceMultiplier
        }
        px
        fx
        pos
        tradePx
        tradeFx
        tradeShares        
      }
    }
  }
}
`
const ADD_COMPLIANCE_REPORT = gql`
mutation AddComplianceReport($input:AddComplianceInput!) {
  addCompliance(input: $input) {
    clientMutationId
    complianceReport {
      id
      datetime
      json
      notes
      passed
      portfolio {
        alias
      }
      reportType
      routeAllocations{
        edges {
          node {
            id
          }
        }
      }
    }
  }
}

`
const RUN_COMPLIANCE = gql`
mutation MyMutation($input: RunComplianceInput!) {
runCompliance(input: $input) {
clientMutationId
results
}
}
`


export function NewCompliance(){
    const [pageState, setPageState] = useState("initial");
    const [pageIter, setPageIter] = useState(0);
    const [complianceReport, setComplianceReport] = useState(initialComplianceReport);
    const [initialParams, setInitialParams] = useState({portfolioId:null, date:null, reportType:null, ras:[]})
    const [gridParams, setGridParams] = useState([])
    const [testResults,setTestResults] = useState([])
    const [complianceReportResults, setComplianceReportResults] = useState(null)
    const [addCompliance,{data:mutateData,loading:mutateLoading,error:mutateError,reset:mutateReset}] = useMutation(ADD_COMPLIANCE_REPORT)
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false)
    const handleChange = (event) => {
        const {name, value} = event.target;
        setComplianceReport({...complianceReport, [name]: value});
    }

    const handleInitialSubmit = (d)=>{setInitialParams(d);setPageState('grid')}
    const handleGridSubmit = (d)=>{
        var output = d.map((node)=>{
            return(
                {
                    portfolioId:node.portfolioId,
                    securityId:node.securityId,
                    px:node.px,
                    fx:node.fx,
                    pos:node.pos,
                    tradeShares:node.tradeShares,
                    override:node.override,
                    notes:node.notes,
                }
            )

        })
        setGridParams(output);
        setPageState('runTests');
    }

    const handleRunTestSubmit = (d)=>{
        setTestResults(d);
        setPageState('results');
    }

    const handleChangePageState = (d)=>{setPageState(d);}
    const handleReset = ()=>{setPageState("initial"); setPageIter(pageIter+1); setComplianceReport(initialComplianceReport)}

    const handleResultsSubmit= ()=>{
        const isPassed = (testResults)=>{
            var output = true
            testResults.forEach((node)=>{if(node.isPassed==false && node.isOverridden==false){output=false}})
            return output
        }
        setShowLoadingOverlay(true)

        const mutation_input = {
            datetime: complianceReport.datetime,
            notes: complianceReport.notes,
            passed: isPassed(testResults),
            reportType: initialParams.reportType,
            json:{grid:gridParams,results:testResults},
            routeAllocations: initialParams.ras,
            portfolio: initialParams.portfolioId
        }

        addCompliance({
            variables:{input:mutation_input},
            onCompleted:(data)=>{
                setComplianceReportResults(data);
                setShowLoadingOverlay(false);
                setPageState('summary');
            },
            onError:(error)=>{
                console.log('error', error);
                setShowLoadingOverlay(false);
            }
        });

    }



    return (
        <div key={'page_'+pageIter}>
            <Overlay open={showLoadingOverlay}/>
            <h1>New Compliance</h1>
            <div style={{display: pageState==='initial'?'block':'none'}}><Initial handleSubmit={handleInitialSubmit} handleReset={handleReset} handleChangePageState={handleChangePageState}/></div>
            <div style={{display: pageState==='grid'?'block':'none'}}><ComplianceGrid handleChangePageState={handleChangePageState} handleReset={handleReset} handleSubmit={handleGridSubmit} initialParams={initialParams}/></div>
            <div style={{display: pageState==='runTests'?'block':'none'}}><RunTests handleChangePageState={handleChangePageState} handleReset={handleReset} gridParams={gridParams} onSubmit={handleRunTestSubmit}/></div>
            <div style={{display: pageState==='results'?'block':'none'}}><Results handleChangePageState={handleChangePageState} handleReset={handleReset} testResults={testResults} handleSubmit={handleResultsSubmit}/></div>
            <div style={{display: pageState==='summary'?'block':'none'}}><Summary handleReset={handleReset} results={complianceReportResults}/></div>
        </div>
    )
}



const Initial = ({handleSubmit, handleReset, handleChangePageState})=>{
    const initialParams = {portfolioId:null, date:dayjs().format('YYYY-MM-DD'), reportType:null, ras:[]}
    const [queryParams, setQueryParams] = useState(initialParams)
    const [getRas, {loading:rasLoading, error:rasError, data:rasData}] = useLazyQuery(GET_PTC_RAS)
    const [showRasTable, setShowRasTable] = useState(false)
    const shouldGetRas = (tempParams)=>{
        if(tempParams==null){return false}
        if(tempParams.reportType!="PTC"){return false}
        if(tempParams.portfolioId==null || tempParams.date==null){return false}
        if(tempParams.portfolioId=='' || tempParams.date==''){return false}
        return true
    }
    const handleChange = (e)=>{
        const newParams = {...queryParams, [e.target.name]:e.target.value}
        setQueryParams(newParams)
        if(shouldGetRas(newParams)){
            getRas({
                variables:{shares_Gt:0, route_Datetime_Date:newParams.date, orderAllocation_PortfolioId:newParams.portfolioId},
                onCompleted:(data)=>{setShowRasTable(true)}
            }
            )
        }
        if(!shouldGetRas(newParams)){setShowRasTable(false)}
    }

    const rowData = useMemo(()=>{if(rasLoading){return null}return rasData?.allRouteAllocations?.edges},[rasData,rasLoading])


    const handleSelectionChange = (d)=>{setQueryParams({...queryParams, ras:d});}
    const onSubmit = useCallback(()=>{handleSubmit(queryParams);},[queryParams])

    return(
        <div>
            <Container maxWidth={'sm'}>
                <Box my={3} component={'section'}><DatePicker value={dayjs(queryParams.date)} setValue = {(newValue)=>{handleChange({target:{name:'date', value:newValue}})}} label={'As of'}/></Box>
                <Box my={3} component={'section'}><PortfolioSelectWrapper onChange={(e)=>{handleChange({target:{name:'portfolioId', value:e.id}})}} title={'Portfolio...'}/></Box>
                <Box my={3} component={'section'} sx={{minWidth:200}}>
                    <Select
                        options={[{id:1, name:'PTC'},{id:2, name:'EOD'}]}
                        getOptionValue={(option)=>{return option.id}}
                        onChange={(e)=>{handleChange({target:{name:'reportType', value:e.name}})}}
                        isMulti={false}
                        isSearchable={false}
                        formatOptionLabel={(option)=>{return <div>{option.name}</div>}}
                        placeholder={'Report Type...'}
                    />
                </Box>

            </Container>
            <div style={{display:showRasTable?'block':'none', paddingBottom:'60px'}}><RasTable data={rowData} onChangeSelection={handleSelectionChange}/></div>
            <Button variant={"outlined"} onClick={()=>{handleChangePageState('initial')}}>Back</Button>
            <Button variant={"contained"} onClick={()=> {onSubmit()}}>Next</Button>
            <Button variant={"outlined"} onClick={()=>{handleReset()}}>Reset</Button>
        </div>
    )
}

const RasTable = ({data,onChangeSelection})=>{
    const [columnDef, setColumnDef] = useState([
        {field: 'node.id',headerCheckboxSelection: true,checkboxSelection: true,headerCheckboxSelectionFilteredOnly: true, hide:true},
        {field: 'node.route.datetime_Date', filter:'agDateColumnFilter', headerName: 'Date', headerCheckboxSelection: true,checkboxSelection: true,headerCheckboxSelectionFilteredOnly: true},
        {field: 'node.route.order.security.identifier.identifier.ticker', headerName:'Ticker'},
        {field: 'node.shares', headerName:'Shares', valueFormatter:noDecimalThousands},
        {field: 'node.px', headerName:'Px', valueFormatter: twoDecimalThousands},
        {field:'node.route.order.side', headerName:'Side'},

    ])
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        };
    }, []);

    // function that triggers onchangeselection when row selections change
    const onSelectionChanged = ()=>{
        let selectedNodes = gridRef.current.api.getSelectedNodes()
        let selectedData = selectedNodes.map(node => node.data.node.id)
        onChangeSelection(selectedData)
    }

    return (
        <div className="ag-theme-alpine" style={{height: 500, width: '100%'}}>
            <h3>Choose Route Allocations to Test</h3>
            <AgGridReact
                rowData={data}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='multiple'
                checkboxSelection={true}
                ref={gridRef}
                onSelectionChanged={onSelectionChanged}
            />
        </div>
    )
}


const ComplianceGrid = ({handleChangePageState, handleReset, initialParams, handleSubmit})=>{
    const gridRef = useRef(null)
    const {loading:gridLoading, error:gridError, data:gridData} = useQuery(GET_GRID_DATA,{
        variables:{
            portfolioId:initialParams.portfolioId,
            datetime_Date_Lt:initialParams.date,
            routeAllocationId_In:initialParams.ras,
            reportType:initialParams.reportType,
        },
        fetchPolicy:'no-cache'
    }
    )

    const rowData = useMemo(()=>{
        if(gridLoading){gridRef?.current?.api.showLoadingOverlay();return null}
        gridRef?.current?.api.hideOverlay();
        return gridData?.allComplianceGrid?.edges.map((node)=>{
            {
                return {
                    'id':node.node.id,
                    'portfolio':node.node.portfolio.name,
                    'portfolioId':node.node.portfolio.id,
                    'security':node.node.security.identifier.identifier.ticker,
                    'securityId':node.node.security.id,
                    'multiplier':node.node.security.priceMultiplier,
                    'px':node.node.px,
                    'fx':node.node.fx,
                    'pos':node.node.pos,
                    'tradePx':node.node.tradePx,
                    'tradeFx':node.node.tradeFx,
                    'tradeShares':node.node.tradeShares,
                    'override':null,
                    'notes':null

                }
            }
        })
    },[gridData, gridLoading])

    const mvStartGetter = (params)=>{return params.data.pos * params.data.multiplier * params.data.px / params.data.fx}
    const weightStartGetter = (params)=>{
        var mvTotal = 0
        gridRef.current.api.forEachNode((node) => {
            const mvPos = node.data.pos * node.data.multiplier * node.data.px / node.data.fx
            mvTotal += mvPos
        })
        if(params.data.fx==0 || mvTotal==0){return 0}
        return (params.data.pos * params.data.multiplier * params.data.px / params.data.fx) / mvTotal
    }
    const posFinalGetter = (params)=>{return params.data.pos + params.data.tradeShares}
    const mvFinalGetter = (params)=>{
        if(params.data.fx==0){return 0}
        return (params.data.pos+params.data.tradeShares) * params.data.multiplier * params.data.px / params.data.fx
    }

    const onOverride = (params)=>{params.node.setDataValue('override', 'Manual Override');gridRef.current.api.refreshCells();}

    const [columnDef, setColumnDef] = useState([
        {field:'id', hide:true},
        {field:'portfolio'},
        {field:'security'},
        {field:'px',editable:true, onCellValueChanged: (p) => onOverride(p), valueFormatter: twoDecimalThousands, filter:'agNumberColumnFilter'},
        {field:'fx',editable:true, onCellValueChanged: (p) => onOverride(p), valueFormatter: twoDecimalThousands, filter:'agNumberColumnFilter'},
        {field:'multiplier', valueFormatter:twoDecimalThousands, filter:'agNumberColumnFilter'},
        {field:'pos',editable:true, onCellValueChanged: (p) => onOverride(p), valueFormatter:noDecimalThousands, filter:'agNumberColumnFilter'},
        {field:'mvStart', valueGetter: mvStartGetter, valueFormatter: noDecimalThousands, filter:'agNumberColumnFilter'},
        {field:'weightStart', valueGetter: weightStartGetter, valueFormatter: percentOneDecimal, filter:'agNumberColumnFilter'},
        {field:'tradePx',editable:true, onCellValueChanged: (p) => onOverride(p), valueFormatter: twoDecimalThousands, filter:'agNumberColumnFilter'},
        {field:'tradeFx',editable:true, onCellValueChanged: (p) => onOverride(p), valueFormatter: twoDecimalThousands, filter:'agNumberColumnFilter'},
        {field:'tradeShares',editable:true, onCellValueChanged: (p) => onOverride(p), valueFormatter:noDecimalThousands, filter:'agNumberColumnFilter'},
        {field:'posFinal', valueGetter: posFinalGetter, valueFormatter:noDecimalThousands, filter:'agNumberColumnFilter'},
        {field:'mvFinal', valueGetter:mvFinalGetter, valueFormatter:noDecimalThousands, filter:'agNumberColumnFilter'},
        {field:'override',editable: true},
        {field:'notes',editable: true},
    ])

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        };
    }, []);

    const onSubmit = ()=>{
        var output = []
        gridRef.current.api.forEachNode((node)=>{output.push(node.data)})
        handleSubmit(output)
    }

    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='multiple'
                checkboxSelection={true}
                ref={gridRef}
                onCellValueChange = {()=>{gridRef.current.api.refreshCells()}}
            />
            <Button variant={"outlined"} onClick={()=>{handleChangePageState('initial')}}>Back</Button>
            <Button variant={"contained"} onClick={onSubmit}>Next</Button>
            <Button variant={"outlined"} onClick={()=>{handleReset()}}>Reset</Button>
    </div>
    )
}











const RunTests = ({handleChangePageState, handleReset, gridParams, onSubmit})=>{
    const [results, setResults] = useState([])
    const [runCompliance,{data:mutateData,loading:mutateLoading,error:mutateError,reset:mutateReset}] = useMutation(RUN_COMPLIANCE)
    const handleRun = ()=>{
        runCompliance({
            variables:{input:{data:gridParams}},
            onCompleted: (data)=>{
                const results = data.runCompliance.results.map((node)=>{return({...node, isOverridden:false})})
                setResults(results)
                console.log('results', results)
            }
        })
    }
    const handleChange = (index,data)=>{
        var output = data
        if(data.hasOwnProperty('overrideNotes')==false){output.overrideNotes='Manual Override'}
        setResults(results.map((node,i)=>{return(i==index?output:node)}))
    }

    const handleSubmit = ()=>{
        onSubmit(results);
    }

    return(
        <div>
            {results.length>0 ?
                results.map((node,index)=>{return(<TestResult key={index} data={node} onChange={handleChange} index={index}/>)}) :
                <div/>
            }
            {results?.length===0 ? <Button variant={"contained"} onClick={handleRun}>RUN</Button> : <div/>}
            {results?.length>0 ? <div>
                <Button variant={"outlined"} onClick={()=>{handleChangePageState('grid')}}>Back</Button>
                <Button variant={"contained"} onClick={handleSubmit}>Submit</Button>
                <Button variant={"outlined"} onClick={()=>{handleReset()}}>Reset</Button></div> : <div/>}

        </div>
    )
}

const TestResult = ({index,data, onChange})=>{

    const getColor = (data)=>{
        if(data!=null && data.isOverridden){return 'orange'}
        if(data!=null && data.isPassed){return 'green'}
        if(data!=null && !data.isPassed){return 'red'}
        return 'black'
    }

    const handleOverride = ()=>{onChange(index,{...data, isOverridden:true})}

    return(
        <div style={{color:getColor(data)}}>
            <hr/>
            <p>{data.name}</p>
            <p>{data.description}</p>
            <p>{data.isRun ? "Run" : "Not Run"}</p>
            <p>{data.isPassed ? "Passed" : "Not Passed"}</p>
            <p>{data.results}</p>
            {data.isPassed ? <div/>:<Button variant={"outlined"} onClick={handleOverride}>Override</Button>}
            {data.isOverridden ? <input value={data.overrideNotes} onChange={(e)=>{console.log(e.target.value);onChange(index,{...data, overrideNotes:e.target.value})}}/> : <div/>}
        </div>
    )
}


const Results = ({handleChangePageState, handleReset, testResults, handleSubmit})=>{
    const [columnDef, setColumnDef] = useState([
        {field:'name'},
        {field: 'isRun'},
        {field: 'isPassed'},
        {field: 'isOverridden'},
        {field: 'ultimately Passed', valueGetter: (params)=>{return params.data.isPassed || params.data.isOverridden}},
    ])
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        };
    }, []);
    const getStatus = (testResults)=>{
        var output = 'Not Run'
        const passed = testResults.filter((node)=>{return(node.isPassed==true)})
        const failed = testResults.filter((node)=>{return(node.isPassed!=true && node.isOverridden!=true)})
        const overridden = testResults.filter((node)=>{return(node.isOverridden==true)})
        const numberTests = testResults?.length
        if(passed.length===numberTests){return 'Passed'}
        if(failed.length>0){return 'Failed'}
        if(overridden.length>0){return `Passed With ${overridden.length} Overrides`}
    }
    const isFailed = useMemo(()=>{
        const failed = testResults?.filter((node)=>{return(node.isPassed!=true && node.isOverridden!=true)})
        return failed?.length > 0 ? true : false
    },[testResults])

    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h2>Results</h2>
            <p>Status: {getStatus(testResults)}</p>
            <AgGridReact
                    rowData={testResults}
                    columnDefs={columnDef}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    enableRangeSelection={false}
                    rowSelection='none'
                    checkboxSelection={false}
                    ref={gridRef}
                />
                <Button variant={"outlined"} onClick={()=>{handleChangePageState('runTests')}}>Back</Button>
                <Button variant={"outlined"} onClick={()=>{handleReset()}}>Reset</Button>
                <Button variant={"contained"} disabled={isFailed} onClick={()=>{handleSubmit()}}>Next</Button>
            </div>

    )
}

const Summary = ({handleReset, results})=>{
    if(results==null){return(<div/>)}
    return(
        <div>
            <h2>Summary</h2>
            <p>{results.addCompliance.complianceReport.id}</p>
            <p>{results.addCompliance.complianceReport.datetime}</p>
            <p>{results.addCompliance.complianceReport.notes}</p>
            <p>{results.addCompliance.complianceReport.passed ? 'Passed' : 'Failed'}</p>
            <Button variant={'outlined'} onClick={()=>{handleReset()}}>Reset</Button>
        </div>
    )
}