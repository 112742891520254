import {React, useMemo} from 'react';
import {gql, useQuery} from "@apollo/client";
import Select from 'react-select';

const GET_SCHEMAS = gql`query GetSchemas {
  allReconcileSchemas {
    edges {
      node {
        id
        effectiveDate
        name
      }
    }
  }
}
`


export function ReconcileSelectWrapper({value, onChange}){
    const {loading,error,data, refetch} = useQuery(GET_SCHEMAS)
    const handleChange = (e)=>{onChange(e.id)}
    const rowData = useMemo(()=>{
        if(loading || error){return []}
        return data?.allReconcileSchemas?.edges?.map((edge)=>{return edge?.node})
    },[data,loading])

    return(
        <div>
            <ReconcileSelect value={value} rowData={rowData} onChange={handleChange}/>
        </div>

    )
}

export function ReconcileSelect({value, rowData, onChange}){
    const formatOptionLabel = (option)=>{return <div>{option.name} | {option.effectiveDate.split('T')[0]}</div>}
    const handleChange = (e)=>{onChange(e)}
    const selectedValue = useMemo(()=>{
        if(value==null || value===''){return null}
        return rowData?.find((x)=>{return x.id===value})
    },[value,rowData])

    return (
        <div>
            <Select
                options={rowData}
                getOptionValue={(option)=>{return option.id}}
                value={selectedValue}
                onChange={(e)=>{handleChange(e)}}
                isMulti={false}
                isSearchable={false}
                formatOptionLabel={formatOptionLabel}
                placeholder={"Select Reconciliation Schema..."}
            />
        </div>
    );
}