import {gql, useQuery} from "@apollo/client";
import React, {useState, useMemo, useRef} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {DatePicker} from "../components/shared/DatePicker";
import dayjs from "dayjs";
import {twoDecimalThousands, noDecimalThousands} from "../utils/Numbers";




const GET_RAS = gql`query ALL_RAS($route_Datetime_Date_Gte:String, $route_Datetime_Date_Lte:String){
  allRouteAllocations(route_Datetime_Date_Gte: $route_Datetime_Date_Gte, route_Datetime_Date_Lte: $route_Datetime_Date_Lte) {
    edges {
      cursor
      node {
        id
        px
        notes
        shares
        filled
        fx
        orderAllocation{portfolio{alias}}
        route{
            datetime_Date
            broker{name}
            
            order{                                
                side
                security{
                    identifier{identifier}
                    currency
                    accountType
                    sector
                }                
            }                           
        }                
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`

export  function RouteAllocations(){
    const initialParams = {route_Datetime_Date_Gte:dayjs().format('YYYY-MM-DD'), route_Datetime_Date_Lte:dayjs().format('YYYY-MM-DD')}
    const [queryParams, setQueryParams] = useState(initialParams)
    const {loading, error, data, refetch} = useQuery(GET_RAS, {variables:queryParams});
    const handleChange = (e)=>{
        const newParams = {...queryParams, [e.target.name]:e.target.value}
        setQueryParams(newParams)
    }


    const [columnDef, setColumnDef] = useState([
        {field:'node.id', hide:true},
        {field:'node.route.order.security.identifier.identifier.ticker', headerName: 'Ticker'},
        {field:'node.route.datetime_Date', headerName: 'Date', filter: 'agDateColumnFilter'},
        {field:'node.orderAllocation.portfolio.alias', headerName: 'Portfolio'},
        {field:'node.route.order.side', headerName: 'Side'},
        {field:'node.route.broker.name', headerName: 'Broker'},
        {field:'node.shares', headerName: 'Shares', filter: 'agNumberColumnFilter', valueFormatter: noDecimalThousands},
        {field:'node.filled', headerName: 'Filled', filter: 'agNumberColumnFilter', valueFormatter: noDecimalThousands},
        {field:'node.px', headerName: 'Price', filter: 'agNumberColumnFilter', valueFormatter: twoDecimalThousands},
        {field:'node.fx', headerName: 'FX', filter: 'agNumberColumnFilter', valueFormatter: twoDecimalThousands},
        {field:'node.route.order.security.currency', headerName: 'Currency'},
        {field:'node.route.order.security.accountType', headerName: 'Account Type'},
        {field:'node.route.order.security.sector', headerName: 'Sector'},
        {field:'node.notes',headerName: 'Notes',hide:true}
    ])

    const gridRef = useRef(null)

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);

    const rowData = useMemo(()=>{
        if(loading){gridRef?.current?.api.showLoadingOverlay();return null}
        gridRef?.current?.api.hideOverlay();
        return data?.allRouteAllocations?.edges
    },[data, loading])

    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>Route Allocations</h1>
            <DatePicker value={dayjs(queryParams.route_Datetime_Date_Gte)} setValue = {(newValue)=>{handleChange({target:{name:'route_Datetime_Date_Gte', value:newValue}})}} label={'Start Date'}/>
            <DatePicker value={dayjs(queryParams.route_Datetime_Date_Lte)} setValue = {(newValue)=>{handleChange({target:{name:'route_Datetime_Date_Lte', value:newValue}})}} label={'End Date'}/>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='none'
                checkboxSelection={false}
                ref={gridRef}
            ></AgGridReact>
            <span>Total Entries: {rowData?.length || 0}</span>
        </div>
    )
}