import {useLazyQuery} from "@apollo/client";
import {React, useMemo, useRef, useState} from "react";
import {useQuery, gql} from '@apollo/client';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";



// DEPRECATED
const GET_CUSTODIAN = gql`
query CustodianReconcileGrid(
  $fileObjId: ID!
    $reconcileSchemaId: ID!
    $portfolioId_In: [ID!]
) {
  allCustodianReconcileGrid(
    fileObjId: $fileObjId
    reconcileSchemaId: $reconcileSchemaId
    portfolioId_In:$portfolioId_In
  ) {
    edges {
      node {
        data
        columns
        matchCols
        valCol
        fileObj {id}
        reconcileSchema {
          id
          json
        }
      }
    }
  }
}
`
export function Custodian({onSubmit, onBack, onReset, params}){
    const {loading, error, data} = useQuery(GET_CUSTODIAN, {
        variables:{
            fileObjId: params.fileObj,
            reconcileSchemaId: params.reconcileSchema,
            portfolioId_In: params.portfolios
        },
        onCompleted: (data)=>{console.log(data)},
        fetchPolicy: 'no-cache'
    })
    return(
        <div>
            <h2>Custodian</h2>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}
            {data ? <CustodianGrid data={data} onBack={onBack} onSubmit={onSubmit} onReset={onReset}/> : null}
        </div>
    )
}
const CustodianGrid = ({data,onBack,onSubmit,onReset})=>{
    const [rowData, setRowData] = useState(data.allCustodianReconcileGrid.edges[0].node.data.map((row)=>{return row}))
    const [columnDefs, setColumnDefs] = useState(()=>{
        var cols = data.allCustodianReconcileGrid.edges[0].node.columns.map((col)=>{return {field:col}})
        cols = cols.filter((col)=>{return col.field !== 'ignore' && col.field !== 'custodian_duplicate'})
        cols.push({field:'custodian_duplicate'})
        cols.push({field:'ignore',editable:true})
        return cols
    }
    )

    const handleSubmit = ()=>{
        var output = []
        gridRef.current.api.forEachNode((node)=>{output.push(node.data)})
        onSubmit(output)
    }

    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        };
    }, []);

    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <p>Lines: {rowData.length}</p>
            <p>Custodian Duplicates: {rowData.filter((row)=>row.custodian_duplicate===true).length}</p>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='multiple'
                checkboxSelection={true}
                ref={gridRef}
            />
            <div style={{marginTop:'50px', marginBottom:'50px'}}>
            <button onClick={onBack}>Back</button>
            <button onClick={()=>{handleSubmit()}}>Next</button>
            <button onClick={onReset}>Reset</button>
            <button onClick={()=>{console.log(rowData)}}>Log</button>
            </div>
        </div>
    )
}




// NEW

const GET_CUSTODIAN_RAW = gql`
query GetCustodianRaw(
  $fileObjId:ID,
  $portfolioId_In:[ID],
  $reconcileSchemaId:ID
  
) {
  allCustodianRaw(
    fileObjId: $fileObjId
    portfolioId_In: $portfolioId_In
    reconcileSchemaId: $reconcileSchemaId
  ) {
    edges {
      node {
        columns
        data
        fileObj {
          id
        }
        portfolios {
          id
          alias
        }
        reconcileSchema {
          id
          json
        }
      }
    }
  }
}
`
export function CustodianDuplicates({onSubmit, onBack, onReset, params}){
    const {loading, error, data, refetch} = useQuery(GET_CUSTODIAN_RAW, {
        variables:{
            fileObjId: params.fileObj,
            reconcileSchemaId: params.reconcileSchema,
            portfolioId_In: params.portfolios
        },
        fetchPolicy: 'no-cache'
    })
    const gridRef = useRef(null)
    const [gridIter, setGridIter] = useState(0)

    const handleDuplicateChange = (params)=>{
        if(params.newValue==='None'){return}

        if(params.newValue==='Ignore'){
            const uid = params.data.uid
            let itemsToUpdate = []
            let data = params.data;
            data.is_duplicated = false;
            data.include = false;
            itemsToUpdate.push(data);

            // get all rows in the grid regardless of filter
            let remainingRows = []
            gridRef.current.api.forEachNode((node)=>{remainingRows.push(node.data)})
            if(remainingRows.filter((row)=>{return (row.uid === uid && row.include==true)}).length===1) {
                gridRef.current.api.forEachNode((node)=>{
                    if(node.data.uid === uid){
                        let data = {...node.data};
                        data.is_duplicated = false;
                        itemsToUpdate.push(data);
                    }
                })
            }
            gridRef.current.api.applyTransaction({update:itemsToUpdate})
            setGridIter(gridIter+1)
        }

        if(params.newValue==='Merge'){
            const uid = params.data.uid
            const rowId = params.data.id
            let maxRowId = null;
            let totalShares = 0
            gridRef?.current?.api.forEachNode((node)=>{if(node.data.id>maxRowId){maxRowId=node.data.id}})
            let itemsToUpdate = []
            let itemsToAdd= []
            gridRef?.current?.api.forEachNode((node)=>{
                if(node.data.uid===uid){
                    totalShares+=node.data.shares;
                    let data = {...node.data}
                    data.is_duplicated = false;
                    data.include=false;
                    data.handle_duplicate = 'Merge';
                    itemsToUpdate.push(data);
                }
            })
            let newRowData = {...params.data};
            newRowData.id = maxRowId+1;
            newRowData.shares = totalShares;
            newRowData.is_duplicated = false;
            newRowData.include = true;
            newRowData.handle_duplicate = null;
            newRowData.new = true;
            itemsToAdd.push(newRowData)
            gridRef?.current?.api.applyTransaction({update:itemsToUpdate, add:itemsToAdd})
            setGridIter(gridIter+1)
        }
    }

    const rowData = useMemo(()=>{
        if(loading){
            gridRef?.current?.api.showLoadingOverlay();
            return null
        }
        gridRef?.current?.api.hideOverlay();
        return data?.allCustodianRaw?.edges[0]?.node?.data
    },[data, loading])

    const hasRemainingDuplicates = useMemo(()=> {
        if(rowData===null){return true}
        var _rowData =[]
        gridRef?.current?.api?.forEachNode((node)=>{_rowData.push(node.data)})
        if(_rowData.filter((row)=>{return row.is_duplicated}).length===0){return false}
        return true
    },[gridIter,rowData])

    const valCol = useMemo(()=>{
        return data?.allCustodianRaw?.edges[0]?.node?.reconcileSchema?.json.filter((col)=>{if(col.is_value){return col}})[0].col_name
    },[data,loading])

    const columnDef = useMemo(()=>{
        let output = data?.allCustodianRaw?.edges[0]?.node.columns.map((col)=>{return {field:col}})
        if(output == null){return []}
        // replace the entry with field == 'handle_duplicate' so that its display name is 'Handle Duplicate'
        output = output.map((col)=>{
            if(col.field === 'handle_duplicate'){
                return {
                    field:col.field,
                    headerName:'Handle Duplicate',
                    editable:true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {values: ['None','Ignore', 'Merge']},
                    onCellValueChanged: handleDuplicateChange

                }}
            return col
        });
        return output
    },[data, loading])

    const gridOptions = {
        defaultColDef: {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
        },
        onFirstDataRendered: params=>{
            const filter = {"is_duplicated": {"values": ["true"],"filterType": "set"}}
            params.api.setFilterModel(filter);
            params.api.onFilterChanged();
            params.api.applyColumnState({state:[{colId:'uid',sort:'asc'}],defaultState:{sort:null}});
            setGridIter(gridIter+1)
        }

    }

    const handleSubmit = ()=>{
        var output = []
        gridRef.current.api.forEachNode((node)=>{output.push(node.data)})
        onSubmit(output)
    }

    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h1>Custodian Duplicates</h1>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                gridOptions = {gridOptions}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='none'
                checkboxSelection={false}
                ref={gridRef}
                getRowId={(params)=>{return params.data.id}}
            ></AgGridReact>
            <Button variant={"outlined"} onClick={onBack}>Back</Button>
            {hasRemainingDuplicates ? null : <Button variant={"contained"} onClick={handleSubmit}>Next</Button>}
            <Button variant={"outlined"} onClick={()=>{refetch()}}>Reset Grid</Button>
            <Button variant={"outlined"} onClick={onReset}>Reset Recon</Button>
        </div>
    )
}
