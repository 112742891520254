import React, {useCallback, useState, useMemo} from 'react'
import {useDropzone} from 'react-dropzone'
import {gql, useMutation } from '@apollo/client'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';



const GET_UPLOAD_CREDENTIALS = gql`
    mutation GetUploadCredentials($input:PresignedPostMutationInput!) {
    generatePresignedPost(input: $input) {
    url
    fields
    uid
    clientMutationId
  }
}
`

export function FileUploader({onSuccess, onError}) {
    const [file, setFile] = useState({status:'inital', file:null, uid:null, uploadDetails:null})
    const [getUploadCredentials, {data, loading, error}] = useMutation(GET_UPLOAD_CREDENTIALS)
    const [pageState, setPageState] = useState('init')


    const onDrop = useCallback(acceptedFiles => {
        setPageState('dropped')
        var _file = {file: acceptedFiles[0],uid: Math.random().toString(36).substring(2),status: 'dropped'}
        setFile(_file)
        getUploadCredentials({
            variables:{input:{objectName: _file.file.name, uid: _file.uid}},
            onCompleted: (data)=>{
                _file['status'] = 'RxCredentials'
                _file['uploadDetails'] = data.generatePresignedPost
                setFile(_file)
                setPageState('credentials success')
                uploadFile(_file)
            },
            onError: (error)=> {
                _file['status'] = 'RxError'
                setFile(_file)
                console.log('credentials error', error)
                onError(error)
                setPageState('credentials error')
            }
        })
    }, [])

    const uploadFile = (_file) => {
        setPageState('uploading')
        var output = {..._file, status:'uploading'}
        setFile(output);

        // Create a new FormData instance
        var formData = new FormData();

        // Append all fields from the presigned post
        for (let key in output.uploadDetails.fields) {formData.append(key, output.uploadDetails.fields[key]);}
        // Append the file
        formData.append('file', output.file);
        // Use fetch to upload the file
        fetch(output.uploadDetails.url, {
            method: "POST",
            body: formData
        })
            .then(function(res){
                output['status'] = 'uploaded'
                setFile(output)
                setPageState('upload success')
                onSuccess(output)
            })
            .catch(function(res){
                output['status'] = 'uploadError'
                setFile(output)
                setPageState('upload error')
                onError(res)
            })
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    const showLoadingOverlay = useMemo(()=>{
        if(pageState==='uploading'||pageState==='dropped'){return true}
        return false
    },[pageState])

    return (
        <div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showLoadingOverlay}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box
                component={'section'}
                sx={{ p: 2, border: '1px dashed grey', width:'50%', margin: 'auto', textAlign: 'center'}}
            >

                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragActive ?<p>Drop the files here ...</p> :<p>Drag 'n' drop some files here, or click to select files</p>}
                    </div>


            </Box>

        </div>
    )
}