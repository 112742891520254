import {React, useState} from 'react';
import {gql, useQuery} from "@apollo/client";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const porstfolios = [
    {id:1,alias:'Test 1'},
    {id:2,alias:'Test 2'},
];

const GET_PL = gql`query ALL_PL($active:Boolean) {
  allPortfolioLists(active: $active) {
    edges {
      cursor
      node {
        id
        alias
        active
        name
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`


export function PortfolioSelect({multiple, onChange}) {
    const [selectedPortfolios, setPortfolios] = useState([]);
    const {loading,error,data, refetch} = useQuery(GET_PL, {variables:{active:true}})
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPortfolios(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        onChange(value)
    };

    const renderSelected = (selected) => {
        const output = selected.map((id) => {
            const portfolios = data.allPortfolioLists.edges.map((p) => p.node)
            const portfolio = portfolios.find((p) => p.id === id)
            return portfolio.alias
        })
        return output.join(', ')
    }

    if(loading){return(<div>Loading</div>)}
    if(error){return(<div>Error</div>)}
    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="portfolio-select">Portfolio</InputLabel>
                <Select
                    labelId="portfolio-select"
                    id="demo-multiple-checkbox"
                    multiple={multiple}
                    value={selectedPortfolios}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => renderSelected(selected)}
                    MenuProps={MenuProps}
                >
                    {data.allPortfolioLists.edges.map((portfolio) => (
                        <MenuItem key={portfolio.node.id} value={portfolio.node.id}>
                            <Checkbox checked={selectedPortfolios.indexOf(portfolio.node.id) > -1} />
                            <ListItemText primary={portfolio.node.alias} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}