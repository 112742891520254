import {React, useMemo, useRef, useState, useCallback} from 'react'
import {gql, useMutation, useQuery} from "@apollo/client";
import { AgGridReact } from 'ag-grid-react';
import dayjs, { Dayjs } from 'dayjs';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from "@mui/material/Button";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {FileUploader} from "../components/FileUploader";
import Overlay from "../components/shared/Overlay";
import {DatePicker} from "../components/shared/DatePicker";
import {SecuritySelectWrapper} from "../components/shared/SecurityMasterSelect";

const GET_SM = gql`query ALL_SM{
  allSecurityMasters{
    edges {
      cursor
      node {
        id
        identifier {
          identifier
        }
        longName
        currency
        sector
        securityType
        financialStatement
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`
const ADD_PRICE_FILE = gql`
    mutation AddPriceFile($input:AddFileMutationInput!) {
        addFile(input: $input) {
        file {
        id
        name
        description
        downloadUrl
    }
  }
}`

const GET_PX_TEMPLATE = gql`
    mutation GetPxTemplateMutation($reportType:String!, $datetime_Date_Lte:Date!) {
        generatePriceTemplate(
            input: {reportType: $reportType, datetime_Date_Lte: $datetime_Date_Lte}
  ) {
    files {
      downloadUrl
    }
  }
}
`

const PROCESS_FILE = gql`mutation ProcessFileMutation($input:ProcessFileMutationInput!) {processFile(input: $input) {prices {id}}}`

const NEW_PRICE_MUTATION = gql`
    mutation NewPriceMutation($input:NewPriceMutationInput!) {
    newPrices(input: $input) {prices {id}}
}
`

export function NewPrice(){
    const [pageState, setPageState] = useState('init')
    const [pageIter, setPageIter] = useState(0)
    const [savedPrices, setSavedPrices] = useState([])
    const [stagedPrices, setStagedPrices] = useState([])
    const [showOverlay, setShowOverlay] = useState(false)
    const {loading:smLoading, error:smError, data:smData, refetch:smRefetch} = useQuery(GET_SM);
    const [addPrices, {data:addPricesData, loading:addPricesLoading, error:addPricesError}] = useMutation(NEW_PRICE_MUTATION);
    const onSetPageState = (x)=>{setPageState(x)}
    const onReset = ()=>{
        setSavedPrices([]);
        setStagedPrices([]);
        setPageState('init');
        setPageIter(pageIter+1);};

    const handleStage = (data)=>{
        setStagedPrices(data);
        setPageState('confirm');
    };

    const handleSubmit = (data)=>{
        const payload = data.map((item)=>{return(
            {
                datetime:item.datetime,
                securityId:item.security,
                px:item.px,
                fx:item.fx,
                notes:item.notes
            }
        )})
        setShowOverlay(true)
        addPrices({
            variables:{"input":{"data":payload}},
            onCompleted: (addPricesData)=>{
                setShowOverlay(false)
                setSavedPrices(addPricesData.newPrices.prices.map((x)=>{return x.id}));
                setPageState('results')
            },
            onError: (error)=>{
                console.log(error);
                setShowOverlay(false)
            }
        })
    }

    const handleUploadSuccess = (x)=>{
        setPageState('results');
        setSavedPrices(x);
    }


    return (
        <div key={pageIter}>
            <Overlay open={showOverlay}/>
            <h1>New Prices</h1>
            <div  style={{display: pageState==='init'?'block':'none'}}><Initial onSetPageState={onSetPageState} onReset={onReset}/></div>
            <div  style={{display: pageState==='form'?'block':'none'}}><NewPriceForm onSetPageState={onSetPageState} onReset={onReset} onSubmit={handleStage} stagedPrices={stagedPrices} smData={smData}/></div>
            <div  style={{display: pageState==='upload'?'block':'none'}}><NewPriceUpload onSetPageState={onSetPageState} onReset={onReset} onSuccess={handleUploadSuccess}/></div>
            <div  style={{display: pageState==='confirm'?'block':'none'}}><Confirm onSetPageState={onSetPageState} onSubmit={handleSubmit} onReset={onReset} stagedPrices={stagedPrices} smData={smData}/></div>
            <div  style={{display: pageState==='results'?'block':'none'}}><Results onSetPageState={onSetPageState} onReset={onReset} results={savedPrices}/></div>
        </div>
    )


}

const Initial = ({onSetPageState})=>{
    const loading = false
    const error = false
    return(
        <div>
            <Button variant={"outlined"} onClick={()=>{onSetPageState('form')}}>Manual</Button>
            <Button variant={"outlined"} onClick={()=>{onSetPageState('upload')}}>Upload</Button>
        </div>
    )
}

const NewPriceForm = ({onSetPageState, onReset, onSubmit, stagedPrices, smData})=>{
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: true,
        };
    }, []);
    const validateRow = ()=>{return true}
    const rowData = useMemo(()=>{return stagedPrices},[stagedPrices])
    const handleSubmit = ()=>{
        var output = []
        gridRef.current.api.forEachNode(n=>{if(validateRow(n.data)){output.push(n.data)}})
        onSubmit(output)
    }
    const addRow = ()=>{
        let maxId = 0
        gridRef.current.api.forEachNode(n=>{if(n.data.id>maxId){maxId=n.data.id}})
        gridRef.current.api.applyTransaction({add:[{id:maxId+1}]})
    }
    const removeRenderer = props => {
        const onClick = ()=>{gridRef.current.api.applyTransaction({remove:[{id:props.data.id}]})}
        return <Button onClick={onClick}>Remove</Button>
    }
    const SecuritySelectCellEditor = (props)=>{
        const [value,setValue] = useState(props?.data?.security || null)
        const handleChange = (e)=>{
            const newValue = e.id
            const oldValue = value
            if(newValue===oldValue){return}
            else{setValue(newValue);props.node.setDataValue('security', newValue);}
        }
        return(
            <div style={{width:'100%', height:'100px'}}><SecuritySelectWrapper onChange={handleChange} /></div>
        )
    }
    const securityRenderer = useCallback((params)=> {
        return smData?.allSecurityMasters?.edges?.find((x)=>{return(x.node.id===params.value)})?.node?.identifier?.identifier?.ticker || params.value
    },[smData])
    const columnDef = useMemo(()=>{
        return([
            {field:'id'},
            {field:'datetime', headerName:'Date'},
            {
                headerName: 'Security',
                field: 'security',
                cellEditor:SecuritySelectCellEditor,
                cellEditorPopup:true,
                cellRenderer:securityRenderer
            },
            {field:'px', valueParser: params=>Number(params.newValue)},
            {field:'fx', valueParser: params=>Number(params.newValue)},
            {field:'notes'},
            {field: 'delete', cellRenderer: removeRenderer, editable:false}
        ])
    },[smData])


    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <Button variant={'outlined'} onClick={addRow}>Add Row</Button>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                reactiveCustomComponents={true}
                animateRows={true}
                enableRangeSelection={true}
                rowSelection='multiple'
                checkboxSelection={true}
                ref={gridRef}
                getRowId={params=>params.data.id}
            ></AgGridReact>
            <Button variant={'outlined'} onClick={()=>{onSetPageState('init')}}>Back</Button>
            <Button variant={'contained'} onClick={handleSubmit}>Submit</Button>
            <Button variant={'outlined'} onClick={onReset}>Reset</Button>

        </div>
    )
}

const NewPriceUpload = ({onSetPageState, onReset, onSuccess})=>{
    const [fileType, setFileType] = useState('bloombergPrices')
    const [datetime_Date_Lte, setDatetime_Date_Lte] = useState(dayjs().format('YYYY-MM-DD'))
    const [pageState, setPageState] = useState('initial')
    const [addPriceFile, {data, loading, error}] = useMutation(ADD_PRICE_FILE)
    const [processPriceFile, {data:processData, loading:processLoading, error:processError}] = useMutation(PROCESS_FILE)
    const [getPxTemplate, {data:templateData, loading:templateLoading, error:templateError}] = useMutation(GET_PX_TEMPLATE)
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false)





    const handleS3Error = (e)=>{console.log(e);setPageState('s3 error')}
    const handleS3Success = (x)=>{setPageState('s3 success');saveFile(x)}

    const saveFile = (x)=>{
        setShowLoadingOverlay(true)
        addPriceFile({
            variables:{
                input:{
                    description: 'new price file',
                    file: x.uploadDetails.fields.key,
                    name: x.file.path
                }
            },
            onCompleted: (data)=>{
                setPageState('successfully saved to groms');
                processFile(data.addFile.file.id)
                setShowLoadingOverlay(false)
            },
            onError: (e)=>{
                console.log('error saving to groms',e)
                setPageState('error saving to groms')
                setShowLoadingOverlay(false)
            }
        });

    };
    const processFile = (fileId)=>{
        setShowLoadingOverlay(true)
        processPriceFile({
            variables:{input:{fileId: fileId,fileType: fileType}},
            onCompleted: (processData)=>{
                setPageState('successfully processed file');
                const savedPrices = processData?.processFile?.prices?.map((x)=>x.id)
                console.log('saved prices', savedPrices)
                onSuccess(savedPrices)
                setShowLoadingOverlay(false)
            },
            onError: (e)=>{
                console.log('error processing file',e)
                setPageState('error processing file')
                setShowLoadingOverlay(false)
            }
        })

    }
    const handleRadioChange = (e)=>{setFileType(e.target.value)}
    const handleDateChange = (e)=>{setDatetime_Date_Lte(e.target.value)}
    const downloadTemplate = (templateData)=>{
        setShowLoadingOverlay(true)
        getPxTemplate({
            variables:{reportType:fileType, datetime_Date_Lte:datetime_Date_Lte},
            onCompleted: (templateData)=>{
                window.open(templateData.generatePriceTemplate.files[0].downloadUrl);
                setShowLoadingOverlay(false);
            },
            onError: (e)=>{
                console.log('templateError',e);
                setShowLoadingOverlay(false);
            }
        })


    }
    return(
        <div>
            <Overlay open={showLoadingOverlay} />
            <Box>
                <Container maxWidth="sm">
                    <DatePicker value={dayjs(datetime_Date_Lte)} setValue = {(newValue)=>{handleDateChange({target:{name:'datetime_Date_Lte', value:newValue}})}} label={'Price Date'}/>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="bloombergPrices"
                        name="radio-buttons-group"
                        onChange={handleRadioChange}
                        value={fileType}
                    >
                        <FormControlLabel value="bloombergPrices" control={<Radio />} label="Bloomberg" />
                        <FormControlLabel value="refinitivPrices" control={<Radio />} label="refinitivPrices" />
                    </RadioGroup>
                    <Button variant={"outlined"} onClick={downloadTemplate}>Download Template</Button>
                </Container>
            </Box>
            <FileUploader onError={handleS3Error} onSuccess={handleS3Success}/>
            <button onClick={()=>{onSetPageState('init')}}>Back</button>
            <button onClick={()=>{onReset()}}>Reset</button>
            {pageState}
        </div>
    )
}

const Confirm = ({onSetPageState, onReset, onSubmit, stagedPrices, smData})=>{
    const gridRef = useRef(null)
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter:true,
            editable: false,
        };
    }, []);
    const rowData = useMemo(()=>{return stagedPrices},[stagedPrices])
    const handleSubmit = ()=>{onSubmit(stagedPrices)}
    const securityRenderer = useCallback((params)=> {
        console.log(smData)
        return smData?.allSecurityMasters?.edges?.find((x)=>{return(x.node.id===params.value)})?.node?.identifier?.identifier?.ticker || params.value
    },[smData])
    const columnDef = useMemo(()=>{
        return([
            {field:'id'},
            {field:'datetime', headerName:'Date'},
            {headerName: 'Security',field: 'security',cellRenderer:securityRenderer},
            {field:'px', valueParser: params=>Number(params.newValue)},
            {field:'fx', valueParser: params=>Number(params.newValue)},
            {field:'notes'},
        ])
    },[smData, stagedPrices])


    return(
        <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
            <h2>Confirm</h2>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                reactiveCustomComponents={true}
                animateRows={true}
                enableRangeSelection={false}
                rowSelection='none'
                checkboxSelection={false}
                ref={gridRef}
                getRowId={params=>params.data.id}
            ></AgGridReact>
            <Button variant={'outlined'} onClick={()=>{onSetPageState('form')}}>Back</Button>
            <Button variant={'contained'} onClick={handleSubmit}>Submit</Button>
            <Button variant={'outlined'} onClick={onReset}>Reset</Button>

        </div>
    )
}

const Results = ({onSetPageState, onReset,results})=>{
    return(
        <div>
            <h2>Results</h2>
            {results?.map((x)=><p>{x}</p>)}
            <button onClick={()=>{onSetPageState('init')}}>Back</button>
            <button onClick={()=>{onReset()}}>Reset</button>
        </div>
    )
}

